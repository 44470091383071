import React from "react";
import HeroContent from "./HeroContent";
import CurebayKavach from "./curebayKavach";
import Quantum from "./Quantum";
import Services from "./Services";
import Family from "./Family";
import ConciergeServices from "./ConciergeServices";
import Coupons from "./Coupons";
import HealthcareDisparities from "./HealthcareDisparities";
import CurebayResponses from "./CurebayResponses";
import WhyCurebay from "./WhyCurebay";
import MembershipSection from "./MembershipSection";
import TotalCareSection from "./TotalCareSection";
import Partners from "./Partners";
import CurebayNumbers from "./CurebayNumbers";
import EclinicMap from "./EclinicMap";
import Faq from "./Faq";
import GetInTouch from "./GetInTouch";
import Testimonials from "./Testimonials";
import Appointments from "./Appointments";

const componentMap = {
    HeroContent: HeroContent,
    CurebayKavach:CurebayKavach,
    Quantum: Quantum,
    Services: Services,
    Family: Family,
    ConciergeServices: ConciergeServices,
    Coupons: Coupons,
    HealthcareDisparities: HealthcareDisparities,
    CurebayResponses: CurebayResponses,
    WhyCurebay: WhyCurebay,
    MembershipSection: MembershipSection,
    TotalCareSection: TotalCareSection,
    Partners: Partners,
    CurebayNumbers: CurebayNumbers,
    EclinicMap: EclinicMap,
    Faq: Faq,
    GetInTouch: GetInTouch,
    Testimonials: Testimonials,
    Appointments: Appointments
};


const DynamicSections = ({ sections }) => {
    return (
        <div>
            {sections
                .sort((a, b) => a.order - b.order) // Sort sections by their order
                .map((section, index) => {
                    const SectionComponent = componentMap[section.type];
                    if (!SectionComponent) {
                        console.warn(`Unknown section type: ${section.type}`);
                        return null;
                    }
                    return <SectionComponent key={index} data={section} />;
                })}
        </div>
    );
};

export default DynamicSections;
