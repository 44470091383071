import { useDispatch, useSelector } from "react-redux";
import CurebayButton from "./Button/PrimaryButton"
import { useHistory } from "react-router-dom";
import { addLabtestTocart } from "../utils/cart-utils";
import { useEffect, useState } from "react";
import { AddtoCart } from "../../Redux/Action/cartPlaceOrderAction";
import { trackAddToCartEvent } from "../utils/analytics/events";
import { APP_ROUTES } from "../PageRouting/AppRoutes";
import { getCartDetails } from "../../Redux/Action/cartPlaceOrderAction";
import TestTube from "../../Assets/Images/testTube.svg";
import { Card, message } from "antd";
const LabTestCard = ({index = -1, labs, quantity = 1}) =>{
    const { cartList } = useSelector((state) => state.cartReducer);
    const [isLoading, setIsLoading] = useState(false)
    const userData = useSelector((state) => state.authReducer.patientData);
    const history = useHistory()
    const dispatch = useDispatch();

      const handleNavigation = (e, data,labTestCode) => {
        console.log(labTestCode,"hyuhyubhuhu")
        e.preventDefault();
       
        history.push({ pathname: APP_ROUTES.LABVIEW, state: { labdata:data,labTestCode:labTestCode }, login: false });
        if (/Mobi|Android/i.test(navigator.userAgent)) {
          window.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 0);
        }
      };
console.log(labs,"gfbhj")
      // const handleClick = () => {
       
      //   if (!userData.code) {
      //     history.push({
      //       pathname: APP_ROUTES.HOME,
      //       // state: { background: location, login: true },
      //     });
      //   }
      //   if (cartList?.patientLabTestsOrder) {
      //       let availableItem = cartList?.patientLabTestsOrder.find(
      //         (item) => item.hospitalId === labs.labId
      //       );
        
      //       if (availableItem === undefined) {
      //         message.warning(
      //           "Select this test from the same lab or replace the tests in your cart"
      //         );
      //         return;
      //       }
      //     }
      //     setIsLoading(true)
      //     const dataObj = addLabtestTocart(userData, cartList, labs, quantity)
      //   dispatch(AddtoCart(dataObj)).then((res) => {
      //     trackAddToCartEvent(labs?.amount, labs?.labTestName)
      //     setIsLoading(false)
      //     dispatch(getCartDetails(userData.code))
      //     // setIsAdding(-1);
      //   }).catch(err =>{
      //       setIsLoading(false)
      //   })
      // }

      const handleClick = () => {
        if (!userData.code) {
          history.push({
            pathname: APP_ROUTES.HOME,
            state: {isLoginModalOpen : true}
          });
          return;
        }
      
        if (cartList?.patientLabTestsOrder) {
          let availableItem = cartList?.patientLabTestsOrder.find(
            (item) => item.hospitalId === labs.labId
          );
      
          if (availableItem === undefined) {
            message.warning(
              "Select this test from the same lab or replace the tests in your cart"
            );
            return;
          }
        }
      
        setIsLoading(true);
      
      
        const payload = {
          patientId: userData.code,
          labTestCode: labs.labTestCode,
          amount: labs.amount,
          hospitalId: labs.labId,
          locationId: labs.locationId,
          labTestName: labs.labTestName,
          labTestType: labs.testType,
          sourceChannel: "B2C",
        };
      
        // Call the new API using fetch
       
        fetch(`${process.env.REACT_APP_BASEURL}cart/addToCart/labtest`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((response) => response.json())
          .then((res) => {
            console.log(res, "Response from addToCart API");
            trackAddToCartEvent(labs.amount, labs.labTestName); 
            dispatch(getCartDetails(userData.code)); 
            setIsLoading(false);
          })
          .catch((err) => {
            console.error("Error adding to cart:", err);
            setIsLoading(false); 
          });
      };

      const drugIDsInCart = getProductsIDs();

      function getProductsIDs() {
        let ids = [];
        if (cartList) {
          if (cartList.patientLabTestsOrder) {
            for (const product of cartList.patientLabTestsOrder) {
              // if (product && product.patientLabTestsOrderDetailsList) {
              for (const drug of product.patientLabTestsOrderDetailsList) {
                ids.push(drug.labTestCode);
              }
              console.log("Collected product IDs:", ids);
              // }
            }
          }
        }
        return ids; 
      }

    return(
       
        <div className="mb-5 w-full" key={index}>
                              <Card>
                                <div className="hidden md:flex items-center gap-5">
                                  <div className="bg-lightBlue w-[5rem] h-[5rem] rounded-full p-5">
                                    <img
                                      src={TestTube}
                                      alt="test"
                                      className="h-full w-full"
                                    />
                                  </div>
                                  <div className="flex justify-between w-full gap-5">
                                    <div className="ml-5 w-[75%]">
                                      <h6 className="text-lg font-semibold text-primaryColor mb-1">
                                        {labs.labTestName}
                                      </h6>
                                      <p className="text-textGray text-base font-light">
                                        <span className="font-medium">
                                          Test Display Name:
                                        </span>{" "}
                                        {labs.testDisplayName}
                                      </p>
                                    </div>
                                    <div className="w-[25%] flex justify-between items-center gap-3">
                                      <p className="font-bold text-xl text-secondaryColor ">
                                        ₹ {labs.amount}
                                      </p>
  

                                      <CurebayButton
                                      loading = {isLoading}
                                      type="primary"
                                      size="large"
                                        onClick={handleClick}
                                        disabled={drugIDsInCart.indexOf(labs.labTestCode) !== -1}
                                      >  
                                      {drugIDsInCart.indexOf(labs.labTestCode) === -1 ? "Add to Cart": "ADDED"}
                                      </CurebayButton>
                                    </div>
                                  </div>
                                </div>
                                { labs?.testParameters?.length ? <div
                                  onClick={(e) => handleNavigation(e, labs, " ")}
                                  className="text-secondaryColor underline text-sm cursor-pointer text-right"
                                >
                                  View More Details
                                </div> : null}
                                <div className="block md:hidden">
                                  <div className="md:flex items-center mb-3 gap-2">
                                    <div className="bg-lightBlue w-[3rem] md:w-[2rem] h-[3rem] md:h-[2rem] rounded-full p-2 mb-3 md:mb-0">
                                      <img
                                        src={TestTube}
                                        alt="test"
                                        className="h-full w-full"
                                      />
                                    </div>
                                    <div className="md:ml-2">
                                      <h6 className="text-sm font-semibold text-primaryColor mb-1">
                                        {labs.labTestName}
                                      </h6>
                                    </div>
                                  </div>
                                  <p className="text-textGray text-xs font-light">
                                    <span className="font-medium">
                                      Test Display Name:
                                    </span>{" "}
                                    {labs.testDisplayName}
                                  </p>
                                </div>
                                <hr className="border-lightBlue border-[1px] my-3" />
                                <div className="md:flex justify-between gap-5">
                                  <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-13">
                                    <div className="font-medium flex gap-1">
                                      Test Type <div>:</div>
                                    </div>{" "}
                                    {labs.testType}
                                  </p>
                                  <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                                    <div className="font-medium flex">
                                      Lab Name :
                                    </div>{" "}
                                    {labs.labName}
                                  </p>
                                  <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3">
                                    <div className="font-medium flex gap-1">
                                      City <div>:</div>
                                    </div>{" "}
                                    {labs.city}
                                  </p>
                                  <p className="w-full md:w-auto text-textGray text-xs mb-1 md:mb-0 md:text-sm font-light flex gap-3 w-[40%]">
                                    <div className="font-medium flex gap-1">
                                      Address <div>:</div>
                                    </div>{" "}
                                    {labs.address1}
                                  </p>
                                </div>
                                <div className="flex justify-between items-center mt-5 md:hidden">
                                  <p className="font-bold text-lg text-secondaryColor ">
                                    ₹ {labs.amount}
                                  </p>

                                  <CurebayButton
                                      loading = {isLoading}
                                      size="medium"
                                        onClick={handleClick}
                                        type="primary"
                                      >  
                                      {drugIDsInCart.indexOf(labs.labTestCode) === -1 ? "Add to Cart": "ADDED"}
                                      </CurebayButton>
                                </div>
                              </Card>
                            </div>
    )
}

export default LabTestCard