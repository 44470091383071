import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import { Button, List, Avatar, Spin, Tooltip } from "antd";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { ReactMic } from "react-mic";
import caresathiagent from "../../../Assets/Images/caresathi_ai_agent.png";
import rx_image from "../../../Assets/Images/rx.png";
import wave from "../../../Assets/Images/audio_wave.png"
import "./aiagent.css";
import Header from "../../Shared/Header";



const AiConcierge = ({ }) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [recording, setRecording] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [flagrespnse, setFlagresponse] = useState(false);
  const [loadingAgentResponse, setLoadingAgentResponse] = useState(false);
  const audioRefs = useRef({});
  const waveformRefs = useRef({});

  const chatRef = useRef(null);
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);

  useEffect(() => {
    if (chatRef.current && isUserAtBottom) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [conversation]);

  const handleScroll = () => {
    if (chatRef.current) {
      // Check if user is near the bottom
      const { scrollTop, scrollHeight, clientHeight } = chatRef.current;
      setIsUserAtBottom(scrollHeight - scrollTop - clientHeight < 50);
    }
  };

  const agents = [
    {
      id: 1,
      name: "Devi",
      role: "Prescription",
      description: "Simplifies e-prescription, ensuring accuracy & direct connectivity with pharmacies.",
      image: caresathiagent,
      icon: rx_image,
    },
  ];

  useEffect(() => {
    conversation.forEach((msg, index) => {
      if (!waveformRefs.current[index] && msg.audioUrl) {
        waveformRefs.current[index] = WaveSurfer.create({
          container: `#waveform-${index}`,
          waveColor: "#b2f5ea",
          progressColor: "#38a169",
          // waveColor: msg.type === "user" ? "#FFFFFF" : "#FFFFFF",
          //progressColor: msg.type === "user" ? "#34B7F1" : "#25D366",
          cursorColor: "transparent",
          barWidth: 3,
          responsive: true,
          height: 40,
        });

        waveformRefs.current[index].load(msg.audioUrl);

        waveformRefs.current[index].on("finish", () => {
          setPlayingIndex(null);
          waveformRefs.current[index].stop();
          waveformRefs.current[index].load(msg.audioUrl); // Reload to keep waveform visible
        });
      }
    });
  }, [conversation]);

  const handlePress = () => {

    setRecording(true);
  };

  const handleRelease = () => {
    setRecording(false);
  };

  const onStop = (recordedBlob) => {

    setRecording(false);
    setLoadingAgentResponse(true);
    setFlagresponse(true);
    const newIndex = conversation.length;
    const newAudio = { type: "user", audioUrl: recordedBlob.blobURL };

    setConversation((prev) => [...prev, newAudio]);
    setConversation((prev) => [
      ...prev,
      { type: "agent", audioUrl: null, loading: true }
    ]);
    uploadAudio(recordedBlob.blob, newIndex);
  };

  const uploadAudio = async (audioBlob, userIndex) => {
    const formData = new FormData();
    formData.append("audio", audioBlob);

    try {
      const response = await fetch(
        "https://digitalconciergeservice.curebay.in/agent/doctor_consultationv2",
        {
          method: "POST",
          headers: { "Session-ID": "uswsfcgv" },
          body: formData,
        }
      );

      const data = await response.blob();
      const agentAudioUrl = URL.createObjectURL(data);
      const newAgentAudio = { type: "agent", audioUrl: agentAudioUrl, loading: false };

      setConversation((prev) => {
        const updatedConversation = [...prev];


        const lastAgentIndex = updatedConversation.findIndex(msg => msg.loading);
        if (lastAgentIndex !== -1) {
          updatedConversation[lastAgentIndex] = newAgentAudio;
        }

        setFlagresponse(false);

        return updatedConversation;
      });


      setTimeout(() => {
        setConversation((prev) => {
          const updatedConversation = [...prev];
          const agentIndex = updatedConversation.findIndex(msg => msg.audioUrl === agentAudioUrl); // Find correct index

          if (agentIndex !== -1 && waveformRefs.current[agentIndex]) {
            waveformRefs.current[agentIndex].play();
            setPlayingIndex(agentIndex);
          }

          return updatedConversation;
        });
      }, 500);

    } catch (error) {
      console.error("Error uploading audio:", error);
      setLoadingAgentResponse(false);
      setFlagresponse(false);
    }
  };


  const togglePlay = (index) => {
    if (playingIndex === index) {
      waveformRefs.current[index].pause();
      setPlayingIndex(null);
    } else {
      Object.values(waveformRefs.current).forEach((wave) => wave.pause());
      waveformRefs.current[index].play();
      setPlayingIndex(index);
    }
  };

  return (
    <>
      <Header />
      <div className="flex flex-col justify-center items-center text-center mt-28" >

        <h2 className="text-xl sm:text-2xl font-bold mb-6">Talk to Digital Concierge AI Agents</h2>
        <div className="mt-6 items-center">
          <p>{recording ? "Please speak..." : ""}</p>

          {/* <img src={wave} alt="Agent" className="" /> */}
        </div>

        <div
          ref={chatRef}
          onScroll={handleScroll}
          style={{
            maxHeight: "760px",
            overflowY: "auto",
            padding: "20px",
            width: "40%",
            display: "flex",
            flexDirection: "column",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <style>
            {`
          /* Hide scrollbar for Chrome, Safari, and Opera */
          div::-webkit-scrollbar {
            display: none;
          }
        `}
          </style>
          <List
            dataSource={conversation}
            renderItem={(msg, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: msg.type === "user" ? "flex-end" : "flex-start",
                  marginBottom: "15px",
                }}
              >
                {msg.type !== "user" && (
                  <Avatar
                    className="w-10 h-10"
                    src={caresathiagent}
                    style={{ marginRight: "10px" }}
                  />
                )}
                <div
                  style={{
                    background: msg.type === "user" ? "#05386B" : "#25D366",
                    color: "#fff",
                    padding: "10px 15px",
                    borderRadius: "20px",
                    maxWidth: "60%",
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {msg.loading ? (
                    <>
                      <Spin size="small" style={{ marginRight: "10px" }} />
                      <span>Listening...</span>
                    </>
                  ) : (
                    <>
                      <Button
                        type="text"
                        icon={playingIndex === index ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
                        onClick={() => togglePlay(index)}
                        style={{ color: "#FFF", fontSize: "30px", marginRight: "10px" }}
                      />
                      <div style={{ width: "200px", height: "40px" }} id={`waveform-${index}`} />
                      <audio ref={(el) => (audioRefs.current[index] = el)} controls style={{ display: "none" }}>
                        <source src={msg.audioUrl} type="audio/wav" />
                      </audio>
                    </>
                  )}
                </div>
                {msg.type === "user" && (
                  <Avatar
                    className="w-10 h-10"
                    src={"https://cdn-icons-png.flaticon.com/512/3135/3135715.png"}
                    style={{ marginLeft: "10px" }}
                  />
                )}
              </div>
            )}
          />

        </div>
        <div className="fixed-buttons">
          <Button
            type="default"
            shape="circle"
            size="large"
            onClick={() => setRecording(false)}
            className="cancel-btn"
          >
            ❌
          </Button>
          <div className="flex gap-4 items-center relative mic-wrapper">
            <Button
              type="primary"
              shape="circle"
              size="large"
              className={recording ? "mic-btn active" : "mic-btn"}
              onMouseDown={handlePress}
              onMouseUp={handleRelease}
              onTouchStart={handlePress}
              onTouchEnd={handleRelease}
            >
              <Tooltip title={recording ? "Pause" : "Speak"} placement="top">
                {recording ? (
                  <PauseCircleOutlined style={{ fontSize: "24px" }} />
                ) : (
                  <PlayCircleOutlined style={{ fontSize: "24px" }} />
                )}
              </Tooltip>

            </Button>



            <div className="mic-container absolute left-4" style={{ display: recording ? "block" : "none" }}>
              <ReactMic
                record={recording}
                onStop={onStop}
                mimeType="audio/wav"
                className="mic-wave"
              // strokeColor="#ff4500"  
              // backgroundColor="rgba(0, 0, 0, 0.8)" 
              // visualSetting="sinewave" 
              // style={{ width: "250px", height: "100px" }} 
              />

            </div>
          </div>


        </div>
      </div>
    </>
  );
};

export default AiConcierge;
