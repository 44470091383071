import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {useSelector } from "react-redux";
import Layouts from "../../../Layouts";
import {Select} from "antd";
import FamilyDropdown from "../HealthRecords/FamilyDropDown";
import B2cOrder from "./b2cOrder";
import CheOrder from "./cheorder";

function MedicineOrders() {
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);
  const [orderType, setOrderType] = useState("b2c");
  const [selectedPatient, setSelectedpatient] = useState(userData?.code);

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      history.push("/", {isLoginModalOpen : true});
    }
  }, []);

  const handleChange = (code) => {
    setSelectedpatient(code);
  };

  return (
    <Layouts>
      <section className="home-banner bg-lightGray min-h-[80vh] py-8 relative  overflow-hidden">
        <div className="md:w-[80%] w-[90%] mx-auto ">
          <div className="w-full bg-primaryColor rounded-md  md:px-8 px-6 md:py-5 py-3">
            <div className="md:flex justify-between items-center ">
              <div className="font-medium md:text-2xl text-White">
                Medicine Order
              </div>
              <div className="hidden md:flex gap-2">
                <FamilyDropdown
                  onSelect={handleChange}
                  title={"Get medicine orders for"}
                />
                <Select
                  defaultValue={orderType}
                  placeholder={orderType}
                  onChange={(code) => setOrderType(code)}
                  options={[{ value: "b2c" }, { value: "eclinic" }]}
                  className="h-12 w-full md:w-[200px] text-base"
                ></Select>
              </div>
            </div>
          </div>
          <div className="md:hidden flex gap-2 mt-5">
            <FamilyDropdown
              onSelect={handleChange}
              title={"Get medicine orders for"}
            />
            <Select
              defaultValue={orderType}
              placeholder={orderType}
              onChange={(code) => setOrderType(code)}
              options={[{ value: "b2c" }, { value: "eclinic" }]}
              className="h-12 w-full md:w-[200px] text-base"
            ></Select>
          </div>
          {orderType == "b2c" ? (
            <B2cOrder selectedPatient={selectedPatient} />
          ) : (
            <CheOrder selectedPatient={selectedPatient} />
          )}
        </div>
      </section>
    </Layouts>
  );
}

export default MedicineOrders;
