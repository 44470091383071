import React from 'react';
import { Button as AntdButton, Spin } from 'antd';
import PropTypes from 'prop-types';
import { colors } from '@mui/material';

const CurebayButton = ({ children, onClick, type , loading = false, disabled = false, size = 'middle', className }) => {
  const customStyle =  type === 'primary' ? { backgroundColor: '#004171', borderColor: '#004171', color: "white" } : { color: "white" };
  const defaultStyle = type === 'gray' ?  { backgroundColor: '#80808096', color: 'white' }:
    type === 'primary'
      ? { backgroundColor: '#004171', borderColor: '#004171', color: 'white' }
      : { color: 'white' };
  return (
    <AntdButton
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      loading={loading}
      size={size}
      style={{ ...defaultStyle, ...customStyle }}
      
      className={className}
      iconPosition="end"
    >

      {children}
    </AntdButton>
  );
};

CurebayButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['primary', 'default', 'dashed', 'link', 'text', 'ghost', "outlined",]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  className: PropTypes.string
};

export default CurebayButton;
