import React from 'react';

function InfoDetails({ title }) {
  
  const data = {
    'Heart Rate': {
      heading: 'Heart Rate',
      description: 'It is the number of times your heart beats per minute.',
      ranges: [
       
        { label: 'Lower', color: 'text-yellow-500', value: '<60 bpm' },
        { label: 'Healthy', color: 'text-green-500', value: '60 – 100 bpm' },
        { label: 'Elevated', color: 'text-red-500', value: '>100 bpm' },
      ],
      additionalInfo: [
      
        { label: 'Bradycardia (Slow)', description: ' Heart Rate < 60' },
        { label: 'Normal', description: '60 < Heart Rate < 100' },
        { label: 'Tachycardia (Fast)', description: 'Heart Rate > 100' },
      ],
   
     
    },
   'Oxygen Level': {
      heading: 'Oxygen Level',
      description: 'It refers to the amount of oxygen in your blood, usually measured as SpO2, with normal levels being 95-100%.',
      ranges: [
        { label: 'Lower', color: 'text-red-500', value: '<90%' },
        { label: 'Healthy', color: 'text-green-500', value: '95 – 100%' },
        { label: 'Concerning', color: 'text-yellow-500', value: '90 – 95%' },
      ],

      additionalInfo: [
       
      ],
     
    },
    'Breathing Rate': {
      heading: 'Breathing Rate',
      description: 'It is the number of breaths you take per minute.',
   
      ranges: [
        { label: 'Lower', color: 'text-yellow-500', value: '<12 bpm' },
        { label: 'Healthy', color: 'text-green-500', value: '12 – 20 bpm' },
        { label: 'Elevated', color: 'text-red-500', value: '>20 bpm' },
      ],
      additionalInfo: [
     
        { label: 'Bradypnea (Slow)', description: 'Fewer than 12 breaths per minute.' },
        { label: 'Tachypnea (Fast)', description: 'More than 20 breaths per minute.' },
      ],
   
    },
    'Systolic Blood Pressure': {
      heading: 'Blood Pressure',
      ranges: [
        { label: 'Systolic :  It is the top number in a blood pressure reading, showing the pressure in your arteries when your heart beats.',
           subRanges: [
          { label: 'Lower', color: 'text-yellow-500', value: '<90 mm Hg' },
          { label: 'Healthy', color: 'text-green-500', value: '90–120 mm Hg' },
          { label: 'Elevated', color: 'text-red-500', value: '>120 mm Hg' },
        ]},
        
      ],
      additionalInfo: [
       
        { label: 'Hypotension (Low)', description: 'Less than 90/60 mmHg.' },
        { label: 'Normal', description: '90–120/60–80 mmHg.' },
        { label: 'Elevated', description: '120–129/<80 mmHg.' },
        { label: 'Hypertension Stage 1', description: '130–139/80–89 mmHg.' },
        { label: 'Hypertension Stage 2', description: '140+/90+ mmHg.' },
       
      
      ],
     
    },
    'Diastolic Blood Pressure': {
        heading: 'Blood Pressure',
        ranges: [
         
          { label: 'Diastolic : It is the bottom number in a blood pressure reading, showing the pressure in your arteries when your heart is resting between beats.', subRanges: [
            { label: 'Lower', color: 'text-yellow-500', value: '<60 mm Hg' },
            { label: 'Healthy', color: 'text-green-500', value: '60–80 mm Hg' },
            { label: 'Elevated', color: 'text-red-500', value: '>80 mm Hg' },
          ]},
        ],
        additionalInfo: [
          { label: 'Hypotension (Low)', description: 'Less than 90/60 mmHg.' },
          { label: 'Normal', description: '90–120/60–80 mmHg.' },
          { label: 'Elevated', description: '120–129/<80 mmHg.' },
          { label: 'Hypertension Stage 1', description: '130–139/80–89 mmHg.' },
          { label: 'Hypertension Stage 2', description: '140+/90+ mmHg.' },
        ],
       
      },

      'Stress Index': {
        heading: 'Stress Index',
        description: 'It is a measure of how much stress your body is under, often based on heart rate variability (HRV) and autonomic nervous system activity.',
  
        ranges: [
          { label: 'Healthy', color: 'text-green-500', value: '0–25% (No Stress/Low Stress)' },
          { label: 'Stressed', color: 'text-yellow-500', value: '50% (Stressed)' },
          { label: 'Elevated', color: 'text-red-500', value: '75–100% (High/Extreme Stress)' },
        ],
        additionalInfo: [
          // { label: 'Stress Index', description: 'It is a measure of how much stress your body is under, often based on heart rate variability (HRV) and autonomic nervous system activity.' },
        ],
        
      },
      'Heart Rate Variability': {
        heading: 'Heart Rate Variability',
        description:'Heart rate variability (HRV) is the variation in time between consecutive heartbeats, indicating heart health and stress levels.',
        ranges: [
          { label: 'Lower', color: 'text-yellow-500', value: '<50 ms' },
          { label: 'Healthy', color: 'text-green-500', value: '50 – 100 ms' },
          { label: 'Elevated', color: 'text-red-500', value: '>100 ms' },
        ],
        additionalInfo: [
        
          { label: 'Stressed', description: 'The body is under strain, with reduced adaptability and recovery capacity.' },
          { label: '50–100 (Moderate HRV): Stable', description: 'Balanced stress and recovery, indicating normal adaptability.' },
          { label: '>100 (High HRV): Resilient', description: 'Excellent adaptability and recovery, though excessively high HRV might require context evaluation.' },
        ],
        
      },
      cardiacworkload: {
        heading: 'Cardiac Workload',
        description:'The effort your heart exerts to pump blood; increases with physical activity, stress, or health conditions.',
        ranges: [
          { label: 'Healthy', color: 'text-green-500', value: 'Low' },
          { label: 'Okay', color: 'text-yellow-500', value: 'Moderate' },
          { label: 'Unhealthy', color: 'text-red-500', value: 'High/Very High' },
        ],
        additionalInfo: [
        
        ],
      },
      BMI: {
        heading: 'BMI',
        description:  'A measure of body fat based on height and weight; indicates if you\'re underweight, normal, overweight, or obese.' ,
        ranges: [
          { label: 'Underweight', color: 'text-red-500', value: '<18.5 kg/m²' },
          { label: 'Healthy', color: 'text-green-500', value: '18.5 – 24.9 kg/m²' },
          { label: 'Overweight', color: 'text-yellow-500', value: '25 – 30 kg/m²' },
          { label: 'Obese', color: 'text-red-500', value: '30 – 40 kg/m²' },
          { label: 'Severe Obesity', color: 'text-red-500', value: '>40 kg/m²' },
        ],
        additionalInfo: [
        
        ],
      },

      'Burnout Risk': {
        heading: 'Burnout Risk',
        description:'Burnout risk is the likelihood of extreme mental, emotional, and physical exhaustion due to prolonged stress and overwork',
        ranges: [
          { label: 'Low', color: 'text-green-500', value: '0 – 30%' },
          { label: 'Moderate', color: 'text-yellow-500', value: '30 – 60%' },
          { label: 'High', color: 'text-orange-500', value: '60 – 80%' },
          { label: 'Severe', color: 'text-red-500', value: '80 – 100%' },
        ],
        additionalInfo: [
       
          { label: 'Low Burnout Risk', description: 'Healthy recovery and low stress (0 - 30%).' },
          { label: 'Moderate Burnout Risk', description: 'Stress may be affecting well-being (30% - 60%).' },
          { label: 'High Burnout Risk', description: 'Elevated stress and fatigue symptoms (60% - 80%).' },
          { label: 'Severe Burnout Risk', description: 'Critical signs of burnout and chronic stress (80% - 100%).' },
        ],
      },
      'Recovery Score': {
        heading: 'Recovery Score',
        description:"It is the body's ability to rest, repair, and regain energy after stress, exercise, or illness.",
        ranges: [
          { label: 'Severely Impaired', color: 'text-red-500', value: '0 – 20%' },
          { label: 'Poor', color: 'text-orange-500', value: '20 – 40%' },
          { label: 'Moderate', color: 'text-yellow-500', value: '40 – 70%' },
          { label: 'Excellent', color: 'text-green-500', value: '70 – 100%' },
        ],
        additionalInfo: [
           { label: 'Excellent Recovery', description: 'Optimal energy and physiological recovery (70% - 100%).' },
          { label: 'Moderate Recovery', description: 'Some fatigue but manageable (40% - 70%).' },
          { label: 'Poor Recovery', description: 'Significant fatigue and low motivation (20% - 40%).' },
          { label: 'Severely Impaired Recovery', description: 'Critical recovery issues requiring intervention (0 - 20%).' },
        ],
      },
      'Anxiety Risk': {
        heading: 'Anxiety Risk',
        description:"It is the likelihood of experiencing anxiety symptoms, often assessed through heart rate variability (HRV), stress levels, and psychological factors",
        ranges: [
          { label: 'Low', color: 'text-green-500', value: '0 – 30%' },
          { label: 'Moderate', color: 'text-yellow-500', value: '30 – 60%' },
          { label: 'High', color: 'text-orange-500', value: '60 – 80%' },
          { label: 'Severe', color: 'text-red-500', value: '80 – 100%' },
        ],
        additionalInfo: [
        
          { label: 'Low Anxiety Risk', description: 'Normal physiological and behavioral indicators (0 - 30%).' },
          { label: 'Moderate Anxiety Risk', description: 'Some anxiety symptoms present (30% - 60%).' },
          { label: 'High Anxiety Risk', description: 'Strong signs of anxiety (60% - 80%).' },
          { label: 'Severe Anxiety Risk', description: 'Critical anxiety requiring treatment (80% - 100%).' },
        ],
      },
      'Fatigue Risk': {
        heading: 'Fatigue Risk',
        description:"It is the likelihood of reduced physical or mental performance due to tiredness, often measured using heart rate, sleep, and workload data.",
        ranges: [
          { label: 'Low', color: 'text-green-500', value: '0 – 30%' },
          { label: 'Moderate', color: 'text-yellow-500', value: '30 – 60%' },
          { label: 'High', color: 'text-orange-500', value: '60 – 80%' },
          { label: 'Severe', color: 'text-red-500', value: '80 – 100%' },
        ],
        additionalInfo: [
         
          { label: 'Low Fatigue Risk', description: 'Good recovery and low fatigue symptoms (0 - 30%).' },
          { label: 'Moderate Fatigue Risk', description: 'Some fatigue signs but manageable (30% - 60%).' },
          { label: 'High Fatigue Risk', description: 'Strong fatigue symptoms (60% - 80%).' },
          { label: 'Severe Fatigue Risk', description: 'Critical fatigue requiring intervention (80% - 100%).' },
        ],
      },
  };

  // Renders ranges dynamically
  const renderRanges = (ranges) => (
    <ul>
      {ranges.map((range, index) => (
        <li key={index}>
          <span className={`${range.color} font-medium`}>{range.label}</span>: {range.value}
        </li>
      ))}
    </ul>
  );

  // Renders additional information if available
  const renderAdditionalInfo = (info) =>
    info?.map((item, index) => (
      <p key={index}><strong>{item.label}:</strong> {item.description}</p>
    ));



  const content = data[title];


  return (
    <div className="p-6 bg-White  rounded-lg ">
      {content ? (
        <>
          <h4 className="font-bold text-2xl mb-4 text-[#313131] border-b pb-2 border-gray">
            {content.heading}
          </h4>
          <p className='font-semibold text-lg mb-4 text-[#313131]'>{content?.description}</p>
         
          {content.ranges && !content.ranges[0].subRanges && (
            <ul className="mb-4">
              {renderRanges(content.ranges)}
            </ul>
          )}
  
         
          {content.ranges?.map((range, index) => (
            range.subRanges && (
              <div key={index} className="mt-4">
                <h5 className="font-semibold text-lg mb-2 text-[#313131]">
                  {range.label}
                </h5>
                <ul className="ml-4 border-l-2 pl-4 border-gray">
                  {renderRanges(range.subRanges)}
                </ul>
              </div>
            )
          ))}
  
        
          {content.additionalInfo && (
            <div className="mt-4 bg-lightGray p-4 rounded-lg">
              {renderAdditionalInfo(content.additionalInfo)}
            </div>
          )}
        </>
      ) : (
        <p className="text-gray-600 text-center">No information available for the given title.</p>
      )}
    </div>
  );
  
}

export default InfoDetails;
