/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatientfamilymembers } from "../../../../Redux/Action/UserprofileActions";
import {
  setPatientCode,
  setDropDownPatientCode,
} from "../../../../Redux/Action/userActions";
import { USERPROFILE_ROUTES } from "../../../PageRouting/UserProfileRoutes";

function FamilyDropdownCart(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);
  const patientCode = useSelector((state) => state.authReducer.patientCode);
  const familymemberinfo = useSelector((state) => state.familymembers);
  const memberCode = useSelector(
    (state) => state.particularpatientdetails.memberCode
  );
  const { FamilymembersData } = familymemberinfo;
  const [memberList, setMemberList] = useState([]);

  const addSelf = () => {
    let data = {};
    data.name = userData.firstName;
    data.code = userData.code;
    let members = FamilymembersData;
    console.log(userData, "sdisdhfiosdhf", FamilymembersData);
    members.unshift(data);
    let uniqueObjArray = [
      ...new Map(members.map((item) => [item["firstName"], item])).values(),
    ];
    setMemberList(uniqueObjArray);
  };

  useEffect(() => {
    if (FamilymembersData) {
      addSelf();
    }
  }, [FamilymembersData]);

  const onChange = (code) => {
    props.onSelect(code);
    //dispatch(setDropDownPatientCode(code));
  };

  useEffect(() => {
    dispatch(getPatientfamilymembers(userData.code));
  }, []);

  const gotoHistory = (e) => {
    e.preventDefault();
    history.push(USERPROFILE_ROUTES.MYDETAILS);
  };

  console.log(props?.patientId(), "sdfisdhiodsh");


  const checkSelected = (res) =>{
      if(props?.patientId() == res.code){
        return true
    }else if(memberCode == res.code){
      return true
    }
    return false
  }

  return (
    <div className="md:flex pr-2 pl-4 items-center">
      <p className=" font-bold text-xl " style={{ color: "#66B889" }}>
        {props.title}
      </p>
      <div className="h-10 md:w-40 border border-gray p-2 rounded-md flex">
        <select
          className="w-full bg-transparent text-sm font-semibold text-textGray outline-none"
          onChange={(e) => onChange(e.target.value)}
        >
          {memberList.map((res, i) => (
            <option
              key={i}
              className="py-1 text-sm text-green-600"
              selected={checkSelected(res)}
              value={res.code}
            >
              {res.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
export default FamilyDropdownCart;
