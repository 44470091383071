import { useEffect, useState } from "react";
import { getAddressDetails } from "./cartApi/cartapis";
import { useDispatch, useSelector } from "react-redux";
import SavedAddressModel from "./cartModels/SavedAddressModel";
import AddAddressModel from "./cartModels/AddAddressModel";
import { saveUserAddress } from "./cartApi/addressapis";
import useAddressInput from "./hooks/useAddressInput";
import useCurrentLocation from "./hooks/useCurrentLocation";
import http from "../../../Redux/Services/http-common";
import moment from "moment";
import Myprofile from "../PostLogin/MyProfile";
import { Modal } from "antd";
const Address = ({cartId}) => {
  const userData = useSelector((state) => state.authReducer.patientData);
  const [addressList, setAddressList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [openNewAddressDialog, setOpenNewAddresDialog] = useState(false);
  const [editClick, setEditClick] = useState(false);
  const [
    addressString,
    onAddressInputChange,
    onAddressTypeSelect,
    savePreFetchedValues,
    editSelectedAddress,
  ] = useAddressInput();
  const [
    currentLocationError,
    currentLocationloading,
    coords,
    eLocToLatLong,
    getLocation,
    getcoordsFromAddress,
  ] = useCurrentLocation();
  useEffect(() => {
    getAddress();
  }, []);
  console.log(userData, "sdvisdhvoisdovudsuovsd");

  const getAddress = () => {
    if (userData.code) {
      getAddressDetails(userData.code).then((res) => {
        if (res.data?.length) {
          setAddressList(res.data);
          console.log(res.data, "Result Data");
          let defaultAddress = res.data.find((item) => item.isDefault == 1);
          if (defaultAddress) {
            setSelectedAddress(defaultAddress);
            localStorage.setItem(
              "selectedAddress",
              JSON.stringify(defaultAddress)
            );
          } else {
            setSelectedAddress(res.data[0]);
            localStorage.setItem(
              "selectedAddress",
              JSON.stringify(res.data[0])
            );
          }
        }
      });
    }
  };

  useEffect(() => {
    // return http.put(`AddressBook/${id}`, data);
    if (
      selectedAddress?.pinCode &&
      !selectedAddress?.lattitude &&
      !selectedAddress?.longitude
    ) {
      let formatted_address =
        selectedAddress?.address1 +
        selectedAddress?.address2 +
        " " +
        selectedAddress?.city +
        " " +
        selectedAddress?.state +
        " " +
        selectedAddress?.pinCode;
      // console.log(formatted_address, "sdvsdivhsdoihvodsihvodsivsd")
      getcoordsFromAddress(formatted_address);
      // .then(res =>{
      //   console.log(res, "Sdvisdhvoishdivhsdivhsiovds")
      // })
    }
  }, [selectedAddress]);

  useEffect(() => {
    console.log(coords, selectedAddress, "Sdvisdhvoishdivhsdivhsiovds");
    if (coords?.lat) {
      let updatedAddress = {
        ...selectedAddress,
        lattitude: coords.lat,
        longitude: coords.long,
      };
      http
        .put(`AddressBook/${selectedAddress.id}`, updatedAddress)
        .then((res) => {
          getAddress();
        });
      // lat = 20.3644150000001
      //long = 85.8369890000001
    }

    // return http.put(`AddressBook/${id}`, data);
  }, [coords]);

  useEffect(() =>{
    if(selectedAddress?.lattitude && selectedAddress?.longitude && cartId){
      saveAddress()
    }
  },[selectedAddress, cartId])

  //   const getLatAndFromAddress = async () =>{
  //     // return http.put(`AddressBook/${id}`, data);
  //     if(!selectedAddress?.lattitude && !selectedAddress?.longitude){
  //       let formatted_address = selectedAddress?.address1 + selectedAddress?.address2 + " "+ selectedAddress?.city + " "+ selectedAddress?.state + " " + selectedAddress?.pinCode
  //         // console.log(formatted_address, "sdvsdivhsdoihvodsihvodsivsd")
  //       await getcoordsFromAddress(formatted_address)
  //     }
  // }

  const saveAddress = async () =>{
    let payload = {
      deliveryAddress1: selectedAddress?.address1,
      deliveryAddress2: selectedAddress?.address2,
      deliveryAddress3: selectedAddress?.address3,
      deliveryCity: selectedAddress?.city,
      deliveryState: selectedAddress?.state,
      deliveryCountry: selectedAddress?.country,
      deliveryZipcode: selectedAddress?.pinCode,
      cartId: cartId,
      latitude:selectedAddress?.lattitude,
      longitude:selectedAddress?.longitude
    }
    try {
      let res = await http.post(`${process.env.REACT_APP_BASEURL}webhook/updateAddress`, payload)
    } catch (error) {
      
    }
   
  }

  const handleAddressChangeClick = () => {
    setOpenAddressDialog(true);
  };

  const handleHideClick = () => {
    setOpenAddressDialog(false);
    setOpenNewAddresDialog(false);
  };

  const handleAddNewAddressClick = () => {
    setOpenAddressDialog(false);
    setOpenNewAddresDialog(true);
  };

  const handleSelectAddressForSavedAddress = (data) => {
    setSelectedAddress(data);
    localStorage.setItem("selectedAddress", JSON.stringify(data));
    setOpenAddressDialog(false);
  };

  const handleSaveAddressClick = (data) => {

    console.log(data,"CHECK")
    let payload = {
      patientId: data.patientId,
      createdBy: data.patientId,
      modifiedBy: data.patientId,
      address1: data.houseNo,
      address2: data?.landmark
        ? data.address + ", " + data?.landmark
        : data.address,
      city: data.city,
      isDefault: data.isDefault,
      pinCode: data.pincode,
      state: data.state,
      status: data.status,
      type: data.type,
      lattitude: data.lattitude,
      longitude: data.longitude,
    };
    setLoading(true);
    saveUserAddress(payload)
      .then((res) => {
        handleHideClick();
        setLoading(false);
        getAddress();
      })
      .catch((err) => {
        setLoading(false);
      });
    //payload

    // address1: "DC711 shiv colony railway road";
    // address2: "behind overseas bank, palwal";
    // city: "Delhi";
    // createdBy: "AcujG2819p";
    // isDefault: 0;
    // modifiedBy: "AcujG2819p";
    // patientId: "AcujG2819p";
    // pinCode: "121102";
    // state: "Delhi";
    // status: 1;
    // type: "Office";

    //data

    // address: "Chhoti Gali Bhai Parmanand Colony Dr Mukherjee Nagar";
    // address1: "Chhoti Gali";
    // address2: "Bhai Parmanand Colony";
    // city: "New Delhi";
    // formatted_address: "Chhoti Gali, Bhai Parmanand Colony, Dr Mukherjee Nagar, New Delhi, Delhi. Pin-110009 (India)";
    // houseNo: "100";
    // isDefault: 1;
    // landmark: "behind overseas bank";
    // lattitude: "28.7106224";
    // longitude: "77.2072686";
    // pincode: "110009";
    // state: "Delhi";
    // type: "Office";
    console.log(data, "AIGUOSCGougvousovbobiob");
  };

  console.log(openAddressDialog, "osvodhv");
  const handleEditClick = () => {
    setEditClick(true);
    setOpenNewAddresDialog(true);
  };

  const handleDeleteClick = (id) => {
    let payloads = {
      id: id,
    };
    http.delete(`AddressBook/delete`, { data: payloads }).then((res) => {
      getAddress();
    });
  };

  console.log(selectedAddress, "sdviosdhviodshosdhvoisdgvsd");
  return (
    <>
      <div className="bg-lightGreen p-3">
        <div className="flex justify-between items-center p-3 mb-1">
          <div className="w-full">
            <p className="text-primaryColor text-sm">
              Bill to :{" "}
              <span className="font-semibold ">
                {`${userData?.firstName}`}, {selectedAddress?.pinCode}{" "}
              </span>
            </p>
            {selectedAddress?.pinCode ? (
              <span className="text-sm text-primaryColor font-meduim">
                {selectedAddress?.address1} {selectedAddress?.address2}{" "}
                {selectedAddress?.city} {selectedAddress?.state}{" "}
                {selectedAddress?.pinCode}{" "}
              </span>
            ) : (
              <div
                className="font-bold text-sm underline text-center cursor-pointer"
                style={{ color: "#09AB6E" }}
                onClick={() => setOpenNewAddresDialog(true)}
              >
                Add new address
              </div>
            )}
          </div>
          {addressList.length > 0 && (
            <button
              className="font-medium hover:bg-secondaryColor hover:text-White text-sm text-secondaryColor rounded h-[40px] border border-secondaryColor w-[180px]"
              onClick={() => handleAddressChangeClick()}
            >
              Change Address
            </button>
          )}
        </div>
        {selectedAddress?.lattitude ? (
          <div className="">
            {moment().hour() >= 20 || moment().hour() <= 7 ? (
              <p className="font-semibold text-md">
                {`Your order will be delivered on ${
                  moment().hour() >= 0 && moment().hour() <= 8
                    ? moment().format("DD-MM-YYYY")
                    : moment().add(1, "days").format("DD-MM-YYYY")
                }, after 8:00am`}
              </p>
            ) : null}
          </div>
        ) : null}
        {selectedAddress?.pinCode && !selectedAddress?.lattitude ? (
          <div className="text-sm px-4 text-red-800 font-semibold border-t-2 pt-2">
            Your address is not accurate. Please pin point it on map.
          </div>
        ) : null}
      </div>
      {selectedAddress?.city && (
          <SavedAddressModel
            isOpen={openAddressDialog}
            data={addressList}
            handleHideClick={handleHideClick}
            loading={loading}
            handleAddNewAddressClick={handleAddNewAddressClick}
            selectedAddress={selectedAddress}
            handleSelectAddressForSavedAddress={
              handleSelectAddressForSavedAddress
            }
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
      )}

      <AddAddressModel
        isOpen={openNewAddressDialog}
        handleHideClick={handleHideClick}
        loading={loading}
        editAddress={selectedAddress}
        handleSaveAddressClick={handleSaveAddressClick}
      />
    </>
  );
};

export default Address;
