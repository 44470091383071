import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import healthcaredisparity from "../../../Assets/Images/healthcare_disparity_image.svg"
import Slider from "react-slick";
const HealthcareDisparities = ({data}) =>{
    const healths = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        prevArrow: <img src={PrevArrow} alt="icons" />,
        nextArrow: <img src={NextArrow} alt="icons" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

    return(
        <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            Overcoming India’s <br />{" "}
            <span className="text-secondaryColor">
              Healthcare Disparities
            </span>
          </h2>
          <div className="hidden md:flex justify-around gap-5">
            {/* <img style={{width: "30%"}} src= {data?.image_url[0]?.imageweb} /> */}
            <img style={{width:"30%"}} src={healthcaredisparity}/>
            <img style={{width: "30%"}} src= {data?.image_url[1]?.imageweb} />
            <img style={{width: "30%"}} src= {data?.image_url[2]?.imageweb} />
          </div>
          <div className="block md:hidden">
            <Slider {...healths}>
              <div>
                <div >
                  {/* <h4 className="text-2xl px-6 text-primaryColor font-bold mt-10 mb-5 md:w-[75%] relative z-10">
                    Urban bias in{" "}
                    <span className="text-secondaryColor">
                      Medical Healthcare
                    </span>
                  </h4> */}
                  {/* <img src= {data?.image_url[0]?.imageweb} /> */}
                  <img src= {healthcaredisparity} />
                </div>
              </div>
              <div>
              <img src= {data?.image_url[1]?.imageweb} />
              </div>
              <div>
              <img src= {data?.image_url[2]?.imageweb} />
              </div>
            </Slider>
          </div>
        </div>
      </section>
    )
}

export default HealthcareDisparities