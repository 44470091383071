import { useState } from 'react';
import http from '../../../../Redux/Services/http-common'

const DrugTable = ({items,orderId}) =>{
  
  // const [invoiceUrl, setInvoiceUrl] = useState(null);

  // const fetchInvoices = async () => {
  //   try {
  //     const url = `${process.env.REACT_APP_NEWBASEURL}EvitalInvoice/list?cOrderId=${orderId}`;
  //     const response = await http.get(url);

  //     if (response.status !== 200) {
  //       console.error('Failed to fetch invoices:', response.statusText);
  //       return;
  //     }

  //     const data = response.data;

  //     console.log(data, 'Fetched Invoices Data');

  //     const validItems = items.filter(item => item.consignmentId); // Filter out invalid items
  //     if (validItems.length === 0) {
  //       console.warn('No valid items found with consignmentId');
  //       return;
  //     }

  //     // Match consignmentId and get the invoice URL
  //     const matchedItem = data.data.find(invoice =>
  //       validItems.some(item => {
  //         console.log('Checking consignmentId:', item.consignmentId);
  //         return item.consignmentId === invoice.consignmentId;
  //       })
  //     );
      
     

  //     if (matchedItem) {
  //       setInvoiceUrl(matchedItem.invoiceUrl); 
  //     } else {
  //       console.log('No matching consignmentId found');
  //       setInvoiceUrl(null);
  //     }

      
  //   } catch (error) {
  //     console.error('Error fetching invoices:', error);
  //   }
  // };

  // console.log(invoiceUrl,"ftrtfgh")

  // const handleViewInvoiceClick = () => {
  //   fetchInvoices();
  // };
  // const fetchInvoices = async () => {
   
  
  //   try {
      
  //     const url = `${process.env.REACT_APP_NEWBASEURL}EvitalInvoice/list?cOrderId=${orderId}`;
      
     
  //     const response = await http.get(url);
      
      
  //     if (!response.ok) {
  //       console.error('Failed to fetch invoices:', response.statusText);
  //       return;
  //     }
  
      
  //     const data = await response.json();
  
  //     console.log(data, 'Fetched Invoices Data');
  
  //     // Example: Uncomment and update this if you need to set data in state
  //     // setInvoiceData(data.data || []);
  //   } catch (error) {
  //     console.error('Error fetching invoices:', error);
  //   } finally {
  //     // Uncomment this if you have a loading state
  //     // setLoading(false);
  //   }
  // };
  
    return(
      <>
    
 {/* <div  className="text-lightBlue2 underline cursor-pointer text-right mt-4 mb-4"> 
         <button
  className="text-primaryColor underline cursor-pointer"
  onClick={() => {
    window.open(invoiceUrl, '_blank');
    handleViewInvoiceClick();
  }}
>
  View Invoice
</button>
         </div>  */}
    
        <table className=" border-collapse border border-gray w-full h-full mx-auto ">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-400 p-2">ITEM NAME</th>
            <th className="border border-gray-400 p-2">QTY</th>
            <th className="border border-gray-400 p-2">UNIT PRICE</th>
            <th className="border border-gray-400 p-2">
              UNIT DISCOUNTED PRICE
            </th>
            <th className="border border-gray-400 p-2">
              TOTAL AMOUNT
            </th>
          </tr>
        </thead>
        <tbody>
          {/* {currSelectedOrder?.orderDetails[0]?.refundableAmountForItems?.map(
            (drug, idx) => (
              <tr key={idx}>
                <td className="border border-gray-400 p-2">
                  {drug?.drugName}
                </td>
                <td className="border border-gray-400 p-2">
                  {drug?.quantity}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.unitPrice}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.discountAmount}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.totalAmount}
                </td>
                <td className="border border-gray-400 p-2 bg-lightGreen text-secondaryColor">
                {drug?.consignmentstatusname ? drug?.consignmentstatusname : "Yet to Create"}
                </td>
              </tr>
            )
          )} */}
          {items.map(
            (drug, idx) => (
              <tr key={idx}>
                <td className="border border-gray-400 p-2">
                  {drug?.drugName}
                </td>
                <td className="border border-gray-400 p-2">
                  {drug?.quantity}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.unitPrice}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.discountAmount}
                </td>
                <td className="border border-gray-400 p-2">
                  ₹ {drug?.totalAmount}
                </td>
              
              </tr>
            )
          )}
        </tbody>
      </table>
      </>
    )
}

export default DrugTable