import React, { useState } from "react";
import Layouts from "../Layouts";
import News2 from "../../Assets/Images/Media/news2.png";
import News3 from "../../Assets/Images/Media/news-3.png";
import TOI from '../../Assets/Images/Media/Corporate/TOI.png';
import Wion from '../../Assets/Images/Media/Corporate/Wion.png';
import mediaBrief from '../../Assets/Images/Media/Corporate/mediabrief.png';
import businessStandard from '../../Assets/Images/Media/Corporate/BusinessStandard.png';
import iic from '../../Assets/Images/Media/Corporate/iic.png';
import youtube from '../../Assets/Images/Media/Corporate/youtube.png';
import timesnow from '../../Assets/Images/Media/Corporate/timesnow.png';
import newsnine from '../../Assets/Images/Media/Corporate/newsnine.png';
import editorJi from '../../Assets/Images/Media/Corporate/editorJi.png';
import cxotoday from '../../Assets/Images/Media/Corporate/cxotoday.png';
import expressHealthCare from '../../Assets/Images/Media/Corporate/expressHealthCare.png';
import healthcareExecutive from '../../Assets/Images/Media/Corporate/healthcare-executive.png';
import newSeptember from '../../Assets/Images/Media/Corporate/newSeptember.png';
import healthWorld from '../../Assets/Images/Media/Corporate/healthWorld.png';
import economictimes from '../../Assets/Images/Media/Funding/economictimes.png';
import pti from '../../Assets/Images/Media/Funding/pti.png';
import HealthWorld from '../../Assets/Images/Media/Funding/HealthWorld.jpg';
import CNBC from '../../Assets/Images/Media/Funding/CNBC.jpg';
import moneycontrol from '../../Assets/Images/Media/Funding/moneycontrol.png';
import YourStory from '../../Assets/Images/Media/Funding/YourStory.jpg';
import OutlookStartup from '../../Assets/Images/Media/Funding/OutlookStartup.jpg';
import HTDS from '../../Assets/Images/Media/Funding/HTDS.jpg';
import NDTV from '../../Assets/Images/Media/Funding/NDTV.jpg';
import NewIndiaExpress from "../../Assets/Images/Media/Events/NewIndiaExpress.png";
import kalingaChronicles from "../../Assets/Images/Media/Events/Kalinga Chronicle.jpeg";
import Biswababi from "../../Assets/Images/Media/Events/Biswababi.jpeg";
import Calendarimage from "../../Assets/Images/Icons/calendar.svg";
import { Segmented, Image } from "antd";

function MediaNews() {
  const [selectedOption, setSelectedOption] = useState("Corporate News");

  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h1 className="text-center text-2xl md:text-4xl font-bold text-primaryColor leading-normal mb-[30px] md:mb-[50px]">
            <span className="text-secondaryColor">Media</span> & News
          </h1>
          <div className="mt-10 mx-auto md:w-[50%]">
            <Segmented
              className="commonSegment"
              options={["Corporate News", "Funding News", "Events"]}
              block
              onChange={(option) => setSelectedOption(option)}
            />
          </div>
          <div className="mt-10 ">
            {selectedOption === "Corporate News" && (
              <div className="flex justify-center">
                <div className="md:flex justify-center flex-wrap md:grid-cols-3 2xl:grid-cols-4 gap-5">

                <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3" >
                      <a href="https://www.youtube.com/live/xRZ8R-gWDlY?si=fkI5-81dOK7pc4Iz " target="_blank">
                      <iframe className="w-full h-[280px]" src="https://www.youtube.com/embed/xRZ8R-gWDlY" title="LIVE | Latest Developments From The Startup Space | Startup Street | Business News | CNBC TV18" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://timesofindia.indiatimes.com/blogs/voices/investing-in-the-future-of-rural-healthcare-why-health-tech-startups-are-attracting-investors-in-india/" target="_blank">
                    In an insightful conversation with CNBC TV18, Mr. Priyadarshi Mohapatra, Founder & CEO of CureBay, highlights the remarkable 8X growth achieved in H1FY25 and shares the vision to scale even further. 
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                    At CureBay, the key lies in leveraging technology to bring high-quality healthcare services to the last mile by engaging the supply sector effectively and connecting with local communities. This approach transforms healthcare in remote areas from being purely clinical to a community-driven service.
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        16th December, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://health.economictimes.indiatimes.com/news/hospitals/curebay-records-8x-growth-in-gmv-during-h1-fy25-compared-to-h1-fy24/115727680" target="_blank">
                      <img src={healthWorld} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://health.economictimes.indiatimes.com/news/hospitals/curebay-records-8x-growth-in-gmv-during-h1-fy25-compared-to-h1-fy24/115727680" target="_blank">
                    CureBay records 8x growth in GMV during H1 FY25 compared to H1 FY24
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                    The company continues to grow steadily, serving approximately 55,000 patients each quarter and
                     enrolling 18,000 members in its subscription-based healthcare plans. Current reaching over 18
                      million people across Odisha and Chhattisgarh, the company aims to extend its services to 100 million
                      <span><a rel="noreferrer" href="https://health.economictimes.indiatimes.com/news/hospitals/curebay-records-8x-growth-in-gmv-during-h1-fy25-compared-to-h1-fy24/115727680" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      27th November, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.editorji.com/business-news/startups/startup-to-scaleup-spotlight-on-curebay-transforming-healthcare-access-in-rural-india-1727959635862" target="_blank">
                      <img src={editorJi} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.editorji.com/business-news/startups/startup-to-scaleup-spotlight-on-curebay-transforming-healthcare-access-in-rural-india-1727959635862" target="_blank">
                    Startup To Scaleup | Spotlight On CureBay: Transforming Healthcare Access in Rural India

                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                    India's healthcare system has struggled for years to reach the millions living in remote and rural areas.
                     But CureBay, a health-tech startup aims to changing this. Founded in 2021 by Priyadarshi Mohapatra, 
                     Shobhan Mahapatra, and Sanjay Swain, CureBay has created an innovative model combining technology,
                      <span><a rel="noreferrer" href="https://www.editorji.com/business-news/startups/startup-to-scaleup-spotlight-on-curebay-transforming-healthcare-access-in-rural-india-1727959635862" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      3rd October, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.news18.com/lifestyle/revolutionizing-rural-healthcare-9052731.html" target="_blank">
                      <img src={newSeptember} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.news18.com/lifestyle/revolutionizing-rural-healthcare-9052731.html" target="_blank">
                    Revolutionizing Rural Healthcare: How Comprehensive Care Can Improve Well-being for Rural Families
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                    Healthcare in rural areas has long been a challenge in India, where access to medical facilities is limited and 
                    preventive care is almost nonexistent. However, things are changing and for good, new initiatives have started 
                    focusing on not just treating illnesses but on creating a sustainable, comprehensive healthcare ecosystem that 
                      <span><a rel="noreferrer" href="https://www.news18.com/lifestyle/revolutionizing-rural-healthcare-9052731.html" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      16th September, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.news18.com/lifestyle/revolutionizing-rural-healthcare-9052731.html" target="_blank">
                      <img src={healthcareExecutive} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.news18.com/lifestyle/revolutionizing-rural-healthcare-9052731.html" target="_blank">
                    Harnessing AI to Overcome Healthcare Barriers in Rural India
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                    In rural India, healthcare is a constant battle. Millions of people live with limited access to medical care, 
                    facing not just the absence of healthcare professionals but also the burden of outdated infrastructure. These 
                    aren't just challenges—they are the reasons behind delayed treatments and preventable diseases that continue
                      <span><a rel="noreferrer" href="https://www.news18.com/lifestyle/revolutionizing-rural-healthcare-9052731.html" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      13th August, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.expresshealthcare.in/digital-issue/express-healthcare-august-2024/445022/" target="_blank">
                      <img src={expressHealthCare} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.expresshealthcare.in/digital-issue/express-healthcare-august-2024/445022/" target="_blank">
                    Express Healthcare August 2024
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                    Rural areas have pioneered cost-effective healthcare models, Innovative hybrid models integrating eClinics, digital franchisees, and a network of healthcare providers demonstrate how technology and empathy can bridge critical helathcare gaps.
                      <span><a rel="noreferrer" href="https://www.expresshealthcare.in/digital-issue/express-healthcare-august-2024/445022/" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      6th August, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://cxotoday.com/story/transformative-technologies-reshaping-healthcare-what-experts-have-to-say-about-the-era-of-health-tech/" target="_blank">
                      <img src={cxotoday} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://cxotoday.com/story/transformative-technologies-reshaping-healthcare-what-experts-have-to-say-about-the-era-of-health-tech/" target="_blank">
                    Transformative Technologies Reshaping Healthcare: What Experts have to say about the Era of Health Tech
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                    In the dynamic realm of healthcare, a profound transformation is underway, driven by the relentless march of 
                    transformative technologies. These advancements are not only reshaping medical practice but also revolutionizing 
                    patient care on a scale never seen before. Amidst this surge, experts from diverse corners of the healthcare ecosystem
                      <span><a rel="noreferrer" href="https://cxotoday.com/story/transformative-technologies-reshaping-healthcare-what-experts-have-to-say-about-the-era-of-health-tech/" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      23rd April, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3" >
                      <a href="https://timesofindia.indiatimes.com/blogs/voices/investing-in-the-future-of-rural-healthcare-why-health-tech-startups-are-attracting-investors-in-india/" target="_blank">
                        <img src={TOI} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://timesofindia.indiatimes.com/blogs/voices/investing-in-the-future-of-rural-healthcare-why-health-tech-startups-are-attracting-investors-in-india/" target="_blank">
                      Investing in the future of rural healthcare: Why health tech startups are attracting investors
                      in India
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      The ecosystem of Indian health tech startups is experiencing significant growth and development,
                      resulting in a flourishing landscape. The Indian health tech industry is predicted to grow to
                      $5.5 billion by 2025. A variety of causes, like increasing demand for innovative health care
                      services, <span><a rel="noreferrer" href="https://timesofindia.indiatimes.com/blogs/voices/investing-in-the-future-of-rural-healthcare-why-health-tech-startups-are-attracting-investors-in-india/" target="_blank" className="text-primaryColor font-medium">read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        1st August, 2023
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.wionews.com/india-news/future-ready-for-pandemics-how-an-indian-medtech-startup-is-changing-rural-healthcare-624136/amp?fbclid=IwAR3THcKJ5WSq2rEUw3NQyCAmGBiP7GmwQBgwOWTuHH4tmd30-zvRmtxAxSQ" target="_blank">
                      <img src={Wion} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.wionews.com/india-news/future-ready-for-pandemics-how-an-indian-medtech-startup-is-changing-rural-healthcare-624136/amp?fbclid=IwAR3THcKJ5WSq2rEUw3NQyCAmGBiP7GmwQBgwOWTuHH4tmd30-zvRmtxAxSQ" target="_blank">
                      Future-ready for pandemics: How an Indian medtech startup is transforming rural healthcare
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      COVID-19 showed the world how a pandemic can shatter the healthcare system, and leave it in an
                      abysmal state. Even the world's best healthcare facilities collapsed when the coronavirus was on
                      a rampage. The Covid surge in 2021 and 2022 exposed the weak leadership of several 
                      <span><a rel="noreferrer" href="https://www.wionews.com/india-news/future-ready-for-pandemics-how-an-indian-medtech-startup-is-changing-rural-healthcare-624136/amp?fbclid=IwAR3THcKJ5WSq2rEUw3NQyCAmGBiP7GmwQBgwOWTuHH4tmd30-zvRmtxAxSQ" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        10th August, 2023
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://mediabrief.com/exclusive-priyadarshi-mohapatra-curebay/" target="_blank">
                      <img src={mediaBrief} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://mediabrief.com/exclusive-priyadarshi-mohapatra-curebay/" target="_blank">
                      Priyadarshi Mohapatra, CureBay: Pressing need for doorstep healthcare services, innovative
                      solutions
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      In an exclusive interaction with MediaBrief, Priyadarshi Mohapatra, Founder of CureBay, speaks
                      about the importance of healthcare awareness, particularly in rural areas, and highlights the
                      problems that CureBay is addressing.He underscores the significant role of technology 
                      <span><a rel="noreferrer" href="https://mediabrief.com/exclusive-priyadarshi-mohapatra-curebay/" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      26th July, 2023
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.business-standard.com/companies/start-ups/health-services-firm-curebay-plans-expansion-in-rural-odisha-chhattisgarh-123091500539_1.html" target="_blank">
                      <img src={businessStandard} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.business-standard.com/companies/start-ups/health-services-firm-curebay-plans-expansion-in-rural-odisha-chhattisgarh-123091500539_1.html" target="_blank">
                      Health services firm CureBay plans expansion in rural Odisha, Chhattisgarh
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      CureBay, a startup for improving health care accessibility in rural Odisha, plans expanding to
                      the hinterland and neighbouring Chhattisgarh. CureBay is present in 18 districts in Odisha,
                      covering 8,500 villages and serving up to 200 patients daily. The company operates 52 clinics
                      in Odisha 
                      <span><a rel="noreferrer" href="https://www.business-standard.com/companies/start-ups/health-services-firm-curebay-plans-expansion-in-rural-odisha-chhattisgarh-123091500539_1.html" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      15th September, 2023
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://iiic.in/newsletter/market-pulse/jul-2023/inside1.html" target="_blank">
                      <img src={iic} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://iiic.in/newsletter/market-pulse/jul-2023/inside1.html" target="_blank">
                      Impact Enterprise Showcase : Dialogue with Priyadarshi Mohapatra,Founder & CEO, CureBay
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      Mr. Priyadarshi Mohapatra, a visionary in healthcare and a dynamic entrepreneur, is renowned for
                      his leadership across industries, showcasing a commitment to innovation and positive change.
                      His journey has led him to be the driving force behind CureBay, dedicated to revolutionizing
                      <span><a rel="noreferrer" href="https://iiic.in/newsletter/market-pulse/jul-2023/inside1.html" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        10th February, 2024
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.youtube.com/live/DcY0hT1VrFY?si=1i-m9d72DNdoS04G" target="_blank">
                      <img src={youtube} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.youtube.com/live/DcY0hT1VrFY?si=1i-m9d72DNdoS04G" target="_blank">
                      Live Interaction with Priyadarshi Mohapatra
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-11">
                      Mr. Priyadarshi Mohapatra, a visionary in healthcare and a dynamic entrepreneur, is renowned for
                      his leadership across industries, showcasing a commitment to innovation and positive change.
                      His journey has led him to be the driving force behind CureBay, dedicated to revolutionizing
                      <span><a rel="noreferrer" href="https://www.youtube.com/live/DcY0hT1VrFY?si=1i-m9d72DNdoS04G" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>

                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        7th October, 2023
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.timesnownews.com/technology-science/revolutionizing-healthcare-startups-break-barriers-and-bridge-disparities-with-cutting-edge-health-tech-solutions-article-105005099" target="_blank">
                      <img src={timesnow} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.timesnownews.com/technology-science/revolutionizing-healthcare-startups-break-barriers-and-bridge-disparities-with-cutting-edge-health-tech-solutions-article-105005099" target="_blank">
                      Revolutionizing Healthcare: Startups Break Barriers and Bridge Disparities with Cutting-Edge
                      Health Tech Solutions
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      India, a nation of stark contrasts, grapples with a glaring healthcare divide between its
                      urban and rural regions. The cities boast specialized hospitals and medical access, while rural
                      communities, constituting a significant 65% of the populace, struggle for even fundamental
                      <span><a rel="noreferrer" href="https://www.timesnownews.com/technology-science/revolutionizing-healthcare-startups-break-barriers-and-bridge-disparities-with-cutting-edge-health-tech-solutions-article-105005099" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>

                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        6th November, 2023
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.news9live.com/health/health-conditions/heart-attacks-in-2023-cardiologists-list-the-causes-of-heart-related-deaths-in-young-generation-2362768" target="_blank">
                      <img src={newsnine} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.news9live.com/health/health-conditions/heart-attacks-in-2023-cardiologists-list-the-causes-of-heart-related-deaths-in-young-generation-2362768" target="_blank">
                      Heart attacks in 2023: Cardiologists list the causes of heart-related deaths in young generation
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      Dr. Shilpa Bhatte, MBBS, MD Chief Program Officer at CureBay, blamed the rising incidence of
                      heart attacks on lifestyle disorders. “Lifestyle disorders are a very common occurrence in
                      today’s times, especially in the younger population. This includes most importantly what is called 
                      <span><a rel="noreferrer" href="https://www.news9live.com/health/health-conditions/heart-attacks-in-2023-cardiologists-list-the-causes-of-heart-related-deaths-in-young-generation-2362768" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      28th November, 2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            )}
            {selectedOption === "Funding News" && (
              <div className="flex justify-center">
                <div className="md:flex justify-center flex-wrap md:grid-cols-3 2xl:grid-cols-4 gap-5">
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://economictimes.indiatimes.com/tech/funding/curebay-raises-rs-62-crore-in-funding-round-led-by-elevar-equity/articleshow/106594523.cms?from=mdr" target="_blank">
                      <img src={economictimes} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://economictimes.indiatimes.com/tech/funding/curebay-raises-rs-62-crore-in-funding-round-led-by-elevar-equity/articleshow/106594523.cms?from=mdr" target="_blank">
                      Healthcare startup CureBay raises Rs 62 crore in funding round led by Elevar Equity
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      Healthcare startup CureBay has raised around Rs 62 crore in a funding round led by existing
                      investor Elevar Equity. The company said the funds will be used in creating access to affordable
                      healthcare in medically underserved locations, build a data platform, and hire tech and
                      healthcare talent.
                      <span><a rel="noreferrer" href="https://economictimes.indiatimes.com/tech/funding/curebay-raises-rs-62-crore-in-funding-round-led-by-elevar-equity/articleshow/106594523.cms?from=mdr" target="_blank" className="text-primaryColor font-medium">read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        6th January, 2024
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.ptinews.com/story/business/CureBay-raises-Rs-62-crore-from-Elevar--others/1019239" target="_blank">
                      <img src={pti} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-8">
                    <a href="https://www.ptinews.com/story/business/CureBay-raises-Rs-62-crore-from-Elevar--others/1019239" target="_blank">
                      CureBay raises Rs 62 crore from Elevar, others
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      CureBay, a startup focused on solving primary healthcare in remote locations, has raised Rs 62 crore
                      in a series-A funding round led by Elevar Equity, existing investor as well as its founders and
                      leadership team members. The fund will enable the health-tech innovator to continue creating 
                      <span><a rel="noreferrer" href="https://www.ptinews.com/story/business/CureBay-raises-Rs-62-crore-from-Elevar--others/1019239" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>

                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        8th January, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://health.economictimes.indiatimes.com/news/health-it/curebay-successfully-raises-rs-62-crore-in-series-a1-funding-round/106632275" target="_blank">
                      <img src={HealthWorld} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://health.economictimes.indiatimes.com/news/health-it/curebay-successfully-raises-rs-62-crore-in-series-a1-funding-round/106632275" target="_blank">
                      CureBay successfully raises Rs 62 crore in Series A1 funding round
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      As part of its growth strategy, the company aims to focus on driving profitability and data monetisation by investing in recurring revenue streams such as its membership programme and building its data practice - both in technology and on the go-to-market front.
                      <span><a rel="noreferrer" href="https://health.economictimes.indiatimes.com/news/health-it/curebay-successfully-raises-rs-62-crore-in-series-a1-funding-round/106632275" target="_blank" className="text-primaryColor font-medium">read more..</a></span>

                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        8th January, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.cnbctv18.com/startup/curebay-raises-62-crore-to-improve-healthcare-in-remote-areas-18745001.htm" target="_blank">
                      <img src={CNBC} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.cnbctv18.com/startup/curebay-raises-62-crore-to-improve-healthcare-in-remote-areas-18745001.htm" target="_blank">
                      CureBay raises ₹62 crore to improve healthcare in remote areas
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      Healthcare start-up CureBay recently secured ₹62 crore in its series A1 funding round, with Elevar Equity leading the way. CureBay focuses on solving primary healthcare challenges in remote locations using an innovative hybrid model.With this fresh funding, CureBay aims to create a smart 
                      <span><a rel="noreferrer" href="https://www.cnbctv18.com/startup/curebay-raises-62-crore-to-improve-healthcare-in-remote-areas-18745001.htm" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>

                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      8th January, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.moneycontrol.com/news/business/curebay-raises-rs-62-crore-from-elevar-others-12008501.html#google_vignette" target="_blank">
                      <img src={moneycontrol} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-8">
                    <a href="https://www.moneycontrol.com/news/business/curebay-raises-rs-62-crore-from-elevar-others-12008501.html#google_vignette" target="_blank">
                      CureBay raises Rs 62 crore from Elevar, others
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      CureBay, a startup focused on solving primary healthcare in remote locations, has raised Rs 62 crore
                      in a series-A funding round led by Elevar Equity, existing investor as well as its founders and
                      leadership team members. The fund will enable the health-tech innovator to continue creating 
                      <span><a rel="noreferrer" href="https://www.moneycontrol.com/news/business/curebay-raises-rs-62-crore-from-elevar-others-12008501.html#google_vignette" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      6th January, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://yourstory.com/2024/01/curebay-raises-rs-62-crore-from-elevar-others" target="_blank">
                      <img src={YourStory} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://yourstory.com/2024/01/curebay-raises-rs-62-crore-from-elevar-others" target="_blank">
                      Health-Tech startup CureBay raises RS 62 Cr from elever, others
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      The startup plans to use the fund to continue creating access to high quality and affordable healthcare in under-served locations, build an intelligent and robust health data platform, and hire over 200 professionals, including data scientists and healthcare experts.
                      <span><a rel="noreferrer" href="https://yourstory.com/2024/01/curebay-raises-rs-62-crore-from-elevar-others" target="_blank" className="text-primaryColor font-medium">read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      6th January, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://startup.outlookindia.com/sector/e-commerce/curebay-raises-rs-62-crore-from-elevar-others-news-10206" target="_blank">
                      <img src={OutlookStartup} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-9">
                    <a href="https://startup.outlookindia.com/sector/e-commerce/curebay-raises-rs-62-crore-from-elevar-others-news-10206" target="_blank">
                      CureBay raises RS 62 Cr from elever, others
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-11">
                      CureBay, a start-up focused on solving primary healthcare in remote locations, has raised Rs 62 crore in a series-A funding round led by Elevar Equity, an existing investor as well as its founders and leadership team members.The fund will enable the health-tech innovator to continue
                      <span><a rel="noreferrer" href="https://startup.outlookindia.com/sector/e-commerce/curebay-raises-rs-62-crore-from-elevar-others-news-10206" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        9th March, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.htsyndication.com/inc-42/article/healthtech-startup-curebay-raises--7.4-mn-from-existing-investor-elevar-equity%2C-others/78292367" target="_blank">
                      <img src={HTDS} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                    <a href="https://www.htsyndication.com/inc-42/article/healthtech-startup-curebay-raises--7.4-mn-from-existing-investor-elevar-equity%2C-others/78292367" target="_blank">
                      Healthtech Startup CureBay Raises $7.4 Mn From Existing Investor Elevar Equity, Others
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-11">
                      CureBay's founders and some members of its leadership team also participated in the funding round as investors.
                      The startup said it will deploy the funds to build an intelligent and robust health data platform and hire over 200 professionals, including data scientists, diagnostics and pharmacy experts, 
                      <span><a rel="noreferrer" href="https://www.htsyndication.com/inc-42/article/healthtech-startup-curebay-raises--7.4-mn-from-existing-investor-elevar-equity%2C-others/78292367" target="_blank" className="text-primaryColor font-medium" >read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      6th January, 2024
                      </p>
                    </div>
                  </div>

                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                    <a href="https://www.ndtvprofit.com/business/curebay-raises-rs-62-crore-from-elevar-others" target="_blank">
                      <img src={NDTV} alt="images" className="w-[400px] h-[280px]" />
                      </a>
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-8">
                    <a href="https://www.ndtvprofit.com/business/curebay-raises-rs-62-crore-from-elevar-others" target="_blank">
                      CureBay Raises Rs 62 Crore From Elevar, Others
                      </a>
                    </h6>
                    <p className="text-textGray text-sm mb-11">
                      CureBay, a startup focused on solving primary healthcare in remote locations, has raised Rs 62 crore in a series-A funding round led by Elevar Equity, existing investor as well as its founders and leadership team members.The fund will enable the health-tech innovator to continue creating
                      <span><a rel="noreferrer" href="https://www.ndtvprofit.com/business/curebay-raises-rs-62-crore-from-elevar-others" target="_blank" className="text-primaryColor font-medium"> read more..</a></span>
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      6th January, 2024
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            )}
            {selectedOption === "Events" && (
              <div className="flex justify-center">
                <div className="md:flex justify-center flex-wrap md:grid-cols-3 2xl:grid-cols-4 gap-5">
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                      <Image
                        src={kalingaChronicles}
                        alt="images"
                        width={400}
                        height={280}
                      />
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                      CureBay's 100th Eclinic
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      CureBay a healthcare innovator opened its 100th  eclinic at Tangi in Khurda.
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        20th January, 2024
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5  mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                      <Image
                        src={Biswababi}
                        alt="images"
                        width={400}
                        height={280}
                      />
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                      CureBay Acknowledges Swasthya Mitra's Vital Role in Health Care Accessibility at Swastya
                      Mitra Samagaman Event
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      CureBay, the leading healthcare provider, hosted the Swasthya Mitra Samagaman event at the
                      East Coast Railway Auditorium, celebrating the exceptional contributions of 450 Swasthya
                      Mitras from Odisha and Chhattisgarh. The event witnessed the presence of esteemed dignitaries
                      and renowned Ollywood actors Bhoomika Dash and Swaraj Barik, adding glamour to the celebration.
                      Swasthya Mitras, the digital franchises of CureBay, are the corner stone of our healthcare ecosystem,
                      delivering quality medical aid to the last nile. This event was a celebration of these healthcare he-
                      roes, epitomizing CureBay's dedication to healthcare accessibility.

                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                        1st February, 2024
                      </p>
                    </div>
                  </div>
                  <div className="bg-White rounded-xl p-5 mb-5 md:mb-0 md:w-[30%] min-w-[25%]">
                    <div className="w-full mb-3">
                      <Image
                        src={NewIndiaExpress}
                        alt="images"
                        width={400}
                        height={280}
                      />
                    </div>
                    <h6 className="text-primaryColor font-semibold text-lg mb-2">
                      Revolutionizing Affordable, Accessible, Quality Healthcare in Medically Underserved Regions.
                    </h6>
                    <p className="text-textGray text-sm mb-4">
                      Expressing enthusiasm for this milestone, Priyadarshi Mohapatra, CEO,Cure Bay stated,"The
                      inauguration of our 100th eClinic in Tangi signifies Cure Bay's nationwide, breaking profound
                      commitment to down barriers and transform healthcare in redefining healthcare for remote India.
                      Beyond the forgotten."Fuelled by technology and empathy, our eClinics bridge geographical
                      gaps and shatter healthcare disparities.
                    </p>

                    <div className="flex items-center gap-2">
                      <img src={Calendarimage} alt="images" />
                      <p className="text-textGray text-sm ">
                      20th January, 2024
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default MediaNews;