import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PrimeCureFamily from "../../../Assets/Images/Membership/primecure_family.jpg";
import PrimeCureSingle from "../../../Assets/Images/Membership/primecure_single.jpg";
import PrimeCureTwin from "../../../Assets/Images/Membership/primecure_twin.jpg";
import WellnessFamily from "../../../Assets/Images/Membership/wellness_family.jpg";
import WellnessSingle from "../../../Assets/Images/Membership/wellness_single.jpg";
import WellnessTwin from "../../../Assets/Images/Membership/wellness_twin.jpg";
import WellnessProFamily from "../../../Assets/Images/Membership/wellnesspro_family.jpg";
import WellnessProSingle from "../../../Assets/Images/Membership/wellnesspro_single.jpg";
import WellnessProTwin from "../../../Assets/Images/Membership/wellnesspro_twin.jpg";
import DiabeticImage from "../../../Assets/Images/Membership/diabetics.jpg";

import packagesService from "../../../Redux/Services/packagesService";

function MembershipDocument() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState(null);

  const patientID = location.pathname.split("/")[2];
  const membershipID = location.pathname.split("/")[3];

  const membershipImages = {
    "PrimeCure_Single": PrimeCureSingle,
    "PrimeCure_Twin": PrimeCureTwin,
    "PrimeCure_Family": PrimeCureFamily,
    "Wellness_Single": WellnessSingle,
    "Wellness_Twin": WellnessTwin,
    "Wellness_Family": WellnessFamily,
    "WellnessPro_Single": WellnessProSingle,
    "WellnessPro_Twin": WellnessProTwin,
    "WellnessPro_Family": WellnessProFamily,
    "DiabetesCare_Diabetes Care": DiabeticImage,
  };

  const getImageSrc = (productType, membership) => {
    const key = `${productType}_${membership}`;
    return membershipImages[key] || ""; 
  };

  useEffect(() => {
    setIsLoading(true);
    const payload = {
      patientId: patientID,
      membershipId: membershipID,
    };

    packagesService
      .getMembershipCard(payload)
      .then((response) => {
        setPatientDetails(response?.data?.data[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching membership card details:", error);
        setIsLoading(false);
      });
  }, [location.pathname, patientID, membershipID]);

  const downloadCard = (imageUrl, fileName) => {
    if (!imageUrl) {
      console.error("Image URL is not available for download");
      return;
    }
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = `${fileName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="h-full lg:h-screen justify-center flex flex-col">
      {isLoading && <div className="loader"></div>}
      {patientDetails && (
        <>
          <div id="abc" className="flex flex-col items-center">
            <div className="text-center mb-10 text-2xl text-green-400 font-bold pl-4 uppercase">
              {patientDetails.patientMembership} Membership - { patientDetails.membershipProductType}
            </div>
            <img
              className="w-96"
              src={getImageSrc(
                patientDetails.membershipProductType,
                patientDetails.patientMembership
              )}
              alt={`${patientDetails.patientMembership} membership card`}
            />
          </div>
          <div className="flex justify-center my-10">
            <button
              onClick={() =>
                downloadCard(
                  getImageSrc(
                    patientDetails.membershipProductType,
                    patientDetails.patientMembership
                  ),
                  `${patientDetails.firstName}_${patientDetails.patientMembership}`
                )
              }
              className="mt-4 px-4 py-2 bg-secondaryColor text-White rounded"
            >
              Download Card
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default MembershipDocument;
