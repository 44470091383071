import React, { useEffect, useMemo, useState } from "react";
import { Chart } from "primereact/chart";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const Graph = ({ title, field }) => {

  
  const lastWeekStart = moment().subtract(7, "days").format("YYYY-MM-DD"); 
  const lastWeekEnd = moment().subtract(1, "days").format("YYYY-MM-DD"); 
  
  const [fromdate, setFromdate] = useState(lastWeekStart);
  const [todate, setTodate] = useState(lastWeekEnd);
  
  const storedData = JSON.parse(localStorage.getItem("patientInfo"));
    const [graphdata, setGraphdata] = useState([]);

    console.log(lastWeekStart,lastWeekEnd,fromdate,todate,"gggyiuu")

  useEffect(() => {

    
    fetch(`
       ${process.env.REACT_APP_SMARTVITAL}user-metrics?patient_code=${storedData?.data?.code}&start_date=${fromdate}&end_date=${todate}`)
      
      // https://contactlessvitals-stage.curebay.in/user-metrics?patient_code=${storedData?.data?.code}&start_date=${fromdate}&end_date=${todate}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        setGraphdata(data?.data);
        console.log(data?.data)
       
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        
      });
  }, [fromdate,todate]);

  const filterDataByDateRange = (data, startDate, endDate) => {
    if (!startDate || !endDate) return data;

    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date(endDate).setHours(23, 59, 59, 999);

    return data.filter((item) => {
      const itemDate = new Date(item.CREATEDDATE).getTime();
      return itemDate >= start && itemDate <= end;
    });
  };

  const chartData = useMemo(() => {
    if (!graphdata || graphdata.length === 0) return null;

    const filteredData = filterDataByDateRange(graphdata, fromdate, todate)
      .filter((item) => item[field] !== null)
      .map((item) => ({
        x: new Date(item.CREATEDDATE),
        y: item[field],
      }));

    return {
      labels: filteredData.map((entry) =>
        entry.x.toLocaleDateString([], { year: "numeric", month: "short", day: "numeric" })
      ),
      datasets: [
        {
          label: title,
          data: filteredData.map((entry) => entry.y),
          fill: true,
          backgroundColor: "rgba(76, 175, 80, 0.2)",
          borderColor: "rgba(76, 175, 80, 1)",
          borderWidth: 2,
          tension: 0.4,
          pointRadius: 4,
          pointBackgroundColor: "rgba(76, 175, 80, 1)",
        },
      ],
    };
  }, [graphdata, field, title, fromdate, todate]);
  const chartOptions = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: true,
          backgroundColor: "#fff",
          borderColor: "rgba(76, 175, 80, 1)",
          borderWidth: 1,
          titleColor: "#000",
          bodyColor: "#000",
          titleFont: { weight: "bold" },
          bodyFont: { size: 14 },
          callbacks: {
            title: (tooltipItems) => {
              const date = new Date(graphdata[tooltipItems[0].dataIndex].CREATEDDATE);
              return date.toLocaleDateString();
            },
            label: (tooltipItem) => ` ${tooltipItem.raw} `,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            display: false, 
          },
          grid: {
            display: false, 
          },
        },
        y: {
          // title: {
          //   display: true,
          //   text: title,
          //   font: { weight: "bold", size: 14 },
          //   color: "#000",
          // },
          ticks: { color: "#444" },
          grid: { color: "rgba(0, 0, 0, 0.1)", borderDash: [4, 4] },
        },
      },
    }),
    [graphdata, title]
  );
  

  const handleFromDate = (date) => {
    if (!date) return;
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setFromdate(formattedDate);
  
  };

  const handleToDate = (date) => {
    if (!date) return;
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setTodate(formattedDate);
  
  };

  return (
    <div
      style={{
        width: "100%",
        // height: "400px",
        padding: "15px",
        background: "white",
        borderRadius: "10px",
        marginBottom: "20px",
      }}
    >
      <div className="flex justify-end">
        {/* <h2
          style={{
            textAlign: "center",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#4caf50",
          }}
        >
          {title} Over Time
        </h2> */}

       
        <div  className="flex gap-4 " >
          <Calendar
            value={fromdate ? new Date(fromdate) : null}
            onChange={(e) => handleFromDate(e.value)}
            dateFormat="yy-mm-dd"
            placeholder="From Date"
             className="border border-White border-b-gray rounded-md p-2"
            showIcon
          />
          <Calendar
            value={todate ? new Date(todate) : null}
            onChange={(e) => handleToDate(e.value)}
            dateFormat="yy-mm-dd"
            placeholder="To Date"
             className="border border-White border-b-gray rounded-md p-2"
            showIcon
          />
        </div>
      </div>
      <div className="mt-10">
        {chartData ? (
          <Chart type="line" data={chartData} options={chartOptions} />
        ) : (
          <p style={{ textAlign: "center", color: "#999" }}>No data available</p>
        )}
      </div>
    </div>
  );
};

export default Graph;
