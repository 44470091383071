import React, { useState, useRef, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MicIcon from "@mui/icons-material/Mic";
import "./SearchBarMic.css";

const SearchBarMic = ({ startRecording, getaudioUrl , recordingStatus}) => {
  const [isRecording, setIsRecording] = useState(false);
//   const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const silenceTimeout = useRef(null);
  const hasNoise = useRef(false); // Tracks if noise has been detected
  const initialSilence = useRef(true); // Tracks if the user hasn't spoken yet

  const detectSilence = (stream, onSilence, onNoise) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);

    scriptProcessor.onaudioprocess = () => {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);

      const volume = array.reduce((a, b) => a + b, 0) / array.length;

      if (volume < 10) {
        if (!silenceTimeout.current) {
          const timeout = initialSilence.current ? 6000 : 1500; // 3 seconds initially, then 1 second
          silenceTimeout.current = setTimeout(onSilence, timeout);
        }
      } else {
        if (silenceTimeout.current) {
          clearTimeout(silenceTimeout.current);
          silenceTimeout.current = null;
        }
        hasNoise.current = true; // Noise detected
        initialSilence.current = false; // User has started speaking
        onNoise();
      }
    };
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      audioChunks.current = [];
      hasNoise.current = false; // Reset noise detection
      initialSilence.current = true; // Reset initial silence state

      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(audioChunks.current, { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);
        if (hasNoise.current) {
            getaudioUrl(blob);
        } else {
          toast.info("Sorry! Didn't hear that.");
        }
      };

      mediaRecorder.current.start();
      setIsRecording(true);
      recordingStatus("start")
      detectSilence(
        stream,
        () => {
          handleStopRecording();
        },
        () => {
          console.log("Noise detected");
        }
      );
    } catch (error) {
      toast.error("Error accessing microphone. Please check your device settings.");
    }
  };

  const handleStopRecording = () => {
    console.log("RecordingSttoped")
    setIsRecording(false);
    recordingStatus("stop")
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
    if (mediaRecorder.current?.stream) {
      mediaRecorder.current.stream.getTracks().forEach((track) => track.stop());
    }
    silenceTimeout.current = null;
    initialSilence.current = true;
  };

  useEffect(() =>{
    if(startRecording){
        handleStartRecording()
    }else{
        handleStopRecording();
    }
  },[startRecording])

  return (
    <div className="modal-content">
    </div>
  );
};

export default SearchBarMic;
