import { Spin } from 'antd';
import React , {useEffect} from 'react'
import { useHistory , useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../PageRouting/AppRoutes';
function MedicineOrderPlaced() {
    const history = useHistory();
    const redirectTo = (event,location) => {
        event.preventDefault();
        history.push(location);
    };

    const search = useLocation().search;
    // const res = search.split("?search_query=")
    console.log(search , 'sdcndsicnsdipcdiv');

    // useEffect(() => {
      let params = (new URL(document.location)).searchParams
      console.log(params.get('Mode'), "dfidshfiods");
      const status = params.get("status");
      const trancationid = params.get("trancationid");
      const productinfo = params.get("productinfo");
      const remarks = params.get("remarks");
      const payMode = params.get("Mode");
     
      console.log(status , "dfidshfiods");
      console.log(trancationid , "dfidshfiods");
      console.log(productinfo , "dfidshfiods");

    
  
      if(status === 'success') {
          localStorage.setItem('paymentStatus', status);
          localStorage.setItem('trancationid', trancationid);
          localStorage.setItem('paymentRemarks', remarks);   
          localStorage.setItem('payMode', payMode);   
          // localStorage.setItem('status', "1");  
          const url = localStorage.getItem('redirectUrl');
          console.log(status, url , "dfsdfosdibobu");
          if(url && url !== ''){
            history.push(url);
          }else{
            history.push({
              pathname: APP_ROUTES.CART_ORDER_SUCESS,
              state: {
                remark: remarks,
                txnid: trancationid,
              },
            });
          }
         
      };
      if(status === 'pending') {
        localStorage.setItem('paymentStatus', status);
          localStorage.setItem('trancationid', trancationid);
          localStorage.setItem('paymentRemarks', remarks);   
          localStorage.setItem('payMode', payMode);   
          // localStorage.setItem('status', "1");  
          const url = localStorage.getItem('redirectUrl');
          console.log(status, url , "dfsdfosdibobu");
          if(url){
            history.push(url);
          }
       
    };
      if(status === 'failure'){
        // localStorage.setItem('status', "31");
        localStorage.setItem('paymentStatus', status);
        localStorage.setItem('paymentRemarks', remarks);       
          const url = localStorage.getItem('redirectUrl');
          history.push(url);
      }
  
    // }, [search]);

    

    let getParameterByName = (name) => {
      var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };

    return (
        <>
        <div className="flex flex-wrap justify-center items-center h-screen">
							<Spin tip="Please wait..." size="large">
                 <div className='w-28 h-4' ></div>
                </Spin>
						</div>
        </>

    );
}
export default MedicineOrderPlaced;