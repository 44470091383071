import React, { useEffect, useState } from "react";
import Header from "../../Shared/Header";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { BarChartOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Popover,Tabs } from "antd";

import InfoDetails from "./InfoDetails";
import Graph from "./Graph";
import moment from "moment";

const { TabPane } = Tabs;

const COLORS = [
  "#f0ad4e", // Yellow - warning (mild risk)
  "#ff9800", // Orange - elevated (moderate risk)
  "#4caf50", // Green - healthy (optimal range)
  "#dc3545", // Red - high concern (high risk)
  "#b22222", // Dark Red - critical (severe risk)
];

// Define ranges for different metrics globally
const systolicBPRanges = [
  { min: 0, max: 59, color: COLORS[0] },
  { min: 60, max: 79, color: COLORS[1] },
  { min: 80, max: 105, color: COLORS[2] },
  { min: 106, max: 130, color: COLORS[2] },
  { min: 131, max: 160, color: COLORS[3] },
  { min: 161, max: 200, color: COLORS[4] },
];

const diastolicBPRanges = [
  { min: 0, max: 30, color: COLORS[0] },
  { min: 31, max: 49, color: COLORS[1] },
  { min: 50, max: 70, color: COLORS[2] },
  { min: 71, max: 90, color: COLORS[2] },
  { min: 91, max: 100, color: COLORS[3] },
  { min: 101, max: 120, color: COLORS[4] },
];

const heartRateRanges = [
  { min: 0, max: 29, color: COLORS[0] },
  { min: 30, max: 59, color: COLORS[1] },
  { min: 60, max: 75, color: COLORS[2] },
  { min: 76, max: 100, color: COLORS[2] },
  { min: 101, max: 120, color: COLORS[3] },
  { min: 121, max: 150, color: COLORS[4] },
];

const breathingRateRanges = [
  { min: 0, max: 4, color: COLORS[0] },
  { min: 5, max: 11, color: COLORS[1] },
  { min: 12, max: 15, color: COLORS[2] },
  { min: 16, max: 20, color: COLORS[2] },
  { min: 21, max: 30, color: COLORS[3] },
  { min: 31, max: 40, color: COLORS[4] },
];

const hrvRanges = [
  { min: 0, max: 19, color: COLORS[0] },
  { min: 20, max: 49, color: COLORS[1] },
  { min: 50, max: 75, color: COLORS[2] },
  { min: 76, max: 110, color: COLORS[2] },
  { min: 111, max: 160, color: COLORS[3] },
  { min: 161, max: 200, color: COLORS[4] },
];

const bmiRanges = [
  { min: 0, max: 14.9, color: COLORS[0] },
  { min: 15, max: 18.4, color: COLORS[1] },
  { min: 18.5, max: 24.9, color: COLORS[2] },
  { min: 25, max: 29.9, color: COLORS[3] },
  { min: 30, max: 39.9, color: COLORS[3] },
  { min: 40, max: 50, color: COLORS[4] },
];

const stressIndexRanges = [
  { min: 0, max: 50, color: COLORS[2] }, // 0% - 50% (Normal)
  { min: 51, max: 75, color: COLORS[3] }, // 51% - 75% (Elevated)
  { min: 76, max: 100, color: COLORS[4] }, // 76% - 100% (High Stress)
];
const fatigueIndexRange =[
  { min: 0, max: 30, color: COLORS[2] }, // 0% - 50% (Normal)
  { min: 31, max: 60, color: COLORS[1] }, // 51% - 75% (Elevated)
  { min: 61, max: 80, color: COLORS[3]},
  { min: 81, max: 100, color: COLORS[4] },
]

const recoveryIndexRange =[
  { min: 0, max: 20, color: COLORS[4] }, // 0% - 50% (Normal)
  { min: 21, max: 40, color: COLORS[3] }, // 51% - 75% (Elevated)
  { min: 41, max: 70, color: COLORS[1]},
  { min: 71, max: 100, color: COLORS[2] },
]

const oxygenLevelRanges = [
  { min: 90, max: 94, color: COLORS[3] },
  { min: 95, max: 100, color: COLORS[2] },
];

const GaugeCard = ({
  title,
  value,
  minValue,
  maxValue,
  ranges,
  unit,
  status,
  healthyRange,
}) => {
    

  const [selectedGraph, setSelectedGraph] = useState(null); 
  const [isModalVisible, setIsModalVisible] = useState(false);  




  const storedData = JSON.parse(localStorage.getItem("patientInfo"));




  const getColorForValue = (value) => {
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      if (value >= range.min && value <= range.max) {
        return range.color;
      }
    }
    return "#b22222"; 
  };
  

 

  const getField = (title) => {
    const fieldMap = {
      "Systolic Blood Pressure": "SYSTOLIC",
      "Diastolic Blood Pressure": "DIASTOLIC",
      "Heart Rate": "HEARTRATE",
      "Breathing Rate": "RESPIRATIONRATE",
      "Heart Rate Variability":"HEARTRATEVARIABILITY",
      "BMI":"BMI",
      "Oxygen Level": "SPO2",
      "Stress Index":"STRESSINDEX",
      "Fatigue Risk": "FATIGUE_RISK_SCORE",
      "Anxiety Risk":"ANXIETY_RISK_SCORE",
      "Burnout Risk":"BURNOUT_RISK_SCORE",
      "Recovery Score": "RECOVERY_SCORE",
    };
    return fieldMap[title] || "";
  };

  // Function to render the popover content dynamically
  const renderGraphContent = () => {
    if (!selectedGraph) return <p style={{ textAlign: "center", color: "#999" }}>No graph selected</p>;
    return (
      <Graph
        title={selectedGraph}
        field={getField(selectedGraph)}
        // graphdata={graphdata}
       
      
      />
    );
  };

  const openModal = (graphTitle) => {
    setSelectedGraph(graphTitle);
    setIsModalVisible(true);
    console.log("Opening Modal for:", graphTitle);
  };


  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedGraph(null);
  };
  

console.log(selectedGraph,"dfcvgbh")

  const renderInfoContent = (title) => <InfoDetails title={title} />;

  const isStressIndex = title === "Stress Index";

  let gaugeValue;
  let displayValue;
  let adjustedMin = minValue;
  let adjustedMax = maxValue;

  if (isStressIndex) {
    // Ensure that the value is between 0 and 6
    gaugeValue = value;
    displayValue = value;
    // gaugeValue = Math.min(Math.max(value, 0), 6); // Clamping value between 0 and 6
    // displayValue = ((gaugeValue / 6) * 100).toFixed(2); // Convert to percentage
    // adjustedMin = 0;
    // adjustedMax = 100;
  } else {
    gaugeValue = value;
    displayValue = value;
  }
  

  const color = getColorForValue(gaugeValue);

  return (
    
<div className="bg-gradient-to-b from-[#abc9fb] to-White rounded-2xl shadow-lg p-6 flex flex-col items-center justify-center">
  {/* Title */}
  <h3 className=" text-xl sm:text-2xl   font-bold text-primaryColor mb-4 flex items-center gap-2">
    {title}
    {/* <Popover
          content={renderGraphContent}
          trigger="click"
          placement="top"
          open={popoverVisible}
          onOpenChange={(visible) => {
           
            setSelectedGraph(visible ? title : null);
            setPopoverVisible(visible);
          }}
        >
          <BarChartOutlined className="text-primaryColor cursor-pointer" />
        </Popover> */}
         <BarChartOutlined
          className="text-primaryColor cursor-pointer"
          onClick={() => openModal(title)}
        />

<Modal
        title={`${selectedGraph} Over Time`}
        open={isModalVisible}
        onCancel={closeModal}
        footer={null} 
        width={1000} 
        centered 
      >
        {selectedGraph ? (
          <Graph
            title={selectedGraph}
            field={getField(selectedGraph)}
            // graphdata={graphdata}
          />
        ) : (
          <p style={{ textAlign: "center", color: "#999" }}>No graph selected</p>
        )}
      </Modal>
        <Popover content={renderInfoContent(title)} trigger="click" placement="top">
          <InfoCircleOutlined className="text-primaryColor cursor-pointer" />
        </Popover>
  </h3>

  {/* Gauge with Glow Effect */}
  <div
    style={{
      width: "200px",
      height: "200px",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: `0 0 15px 5px ${color}80`,
      borderRadius: "50%",
      backgroundColor: "white",
    }}
    className="mt-6 mb-6"
  >
    <Gauge
      value={gaugeValue}
      min={adjustedMin}
      max={adjustedMax}
      thickness={12}
      sx={{
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: "32px",
          fill: color,
          fontWeight: "bold",
          textAnchor: "middle",
          dominantBaseline: "central",
        },
        [`& .${gaugeClasses.valueArc}`]: {
          fill: color,
          filter: `drop-shadow(0 0 10px ${color}80)`,
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: "#e0e7ff",
        },
      }}
      renderValueLabel={() => (
        <text
          x="50%"
          y="50%"
          fill={color}
          fontSize="32px"
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {isStressIndex ? `${displayValue}%` : displayValue}
        </text>
      )}
    />
  </div>

  {/* Unit */}
  {unit && (
    <p className=" text-sm sm:text-base  font-medium mt-4 text-gray-700">
      {unit}
    </p>
  )}

  {/* Healthy Range */}
  <p className="text-sm sm:text-base text-gray-600 mt-2">
    Healthy Range: <strong>{healthyRange}</strong>
  </p>

  {/* Status Button */}

  <button className=" text-sm sm:text-base font-semibold mt-4 bg-White text-[#313131] rounded-full px-4 py-2 shadow-md transition-all">
    {status}
  </button>
</div>

  );
};

const SmartVitalsReport = (props) => {
  const [vitalsData, setVitalsData] = useState(null);

  useEffect(() => {
    if (props.location.state && props.location.state.responseData) {
      setVitalsData(props.location.state.responseData);
    }
  }, [props.location.state]);

  if (!vitalsData) {
    return <div className="text-center p-6">Loading vitals data...</div>;
  }

  return (
    <div className="p-6 min-h-screen mt-24 bg-gradient-to-b from-[#E3F2FD] to-White">
    <Header />
    
    {/* Title Section */}
    <header className="text-center text-5xl font-extrabold mb-6 text-[#1E3A8A]">
      My <span className="text-secondaryColor">Vitals</span>
    </header>

  
    <div className="p-4 mx-auto max-w-[118rem]">
      <Tabs defaultActiveKey="1" centered className="mb-6 text-lg">
        {/* Physical Index Tab */}
        <TabPane tab={<span className="text-primaryColor text-lg sm:text-2xl font-semibold">Physical Index</span>} key="1">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Systolic Blood Pressure */}
            <GaugeCard
              title="Systolic Blood Pressure"
              value={parseFloat(vitalsData.blood_pressure.split("/")[0])}
              minValue={0}
              maxValue={160}
              ranges={systolicBPRanges}
              unit="mm Hg"
              status={vitalsData.blood_pressure_status}
              healthyRange="90-120 mm Hg"
            />

            {/* Diastolic Blood Pressure */}
            <GaugeCard
              title="Diastolic Blood Pressure"
              value={parseFloat(vitalsData.blood_pressure.split("/")[1])}
              minValue={0}
              maxValue={100}
              ranges={diastolicBPRanges}
              unit="mm Hg"
              status={vitalsData.blood_pressure_status}
              healthyRange="60-80 mm Hg"
            />

            {/* Heart Rate */}
            <GaugeCard
              title="Heart Rate"
              value={parseFloat(vitalsData.predicted_heart_rate)}
              minValue={0}
              maxValue={120}
              ranges={heartRateRanges}
              status={vitalsData.heart_rate_status}
              unit="bpm"
              healthyRange="60-100 bpm"
            />

            {/* Breathing Rate */}
            <GaugeCard
              title="Breathing Rate"
              value={parseFloat(vitalsData.breathing_rate)}
              minValue={0}
              maxValue={30}
              ranges={breathingRateRanges}
              unit="breaths/min"
              status={vitalsData.breathing_rate_status}
              healthyRange="12-20 breaths/min"
            />

            {/* Heart Rate Variability */}
            <GaugeCard
              title="Heart Rate Variability"
              value={parseFloat(vitalsData.heart_rate_variability)}
              minValue={0}
              maxValue={160}
              ranges={hrvRanges}
              unit="ms"
              status={vitalsData.heart_rate_variability_status}
              healthyRange="50-100 ms"
            />

            {/* BMI */}
            <GaugeCard
              title="BMI"
              value={parseFloat(vitalsData.bmi_score)}
              minValue={0}
              maxValue={40}
              ranges={bmiRanges}
              unit="kg/m²"
              status={vitalsData.bmi_status}
              healthyRange="18.5-25"
            />

            {/* Oxygen Level */}
            <GaugeCard
              title="Oxygen Level"
              value={vitalsData.oxygen_level}
              minValue={90}
              maxValue={100}
              ranges={oxygenLevelRanges}
              unit="SpO2 (%)"
              status={vitalsData.oxygen_level < 95 ? "Low" : "Normal"}
              healthyRange="95-100%"
            />

            {/* Stress Index */}
            <GaugeCard
              title="Stress Index"
              value={vitalsData.curebay_stress_index}
              minValue={0}
              maxValue={100}
              ranges={stressIndexRanges}
              unit="%"
              status={vitalsData?.curebay_stress_status}
              healthyRange="0-25%"
            />
          </div>
        </TabPane>

        {/* Mental Index Tab */}
        
        <TabPane tab={<span className="text-primaryColor text-lg sm:text-2xl font-semibold">Wellness Index</span>} key="2">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Fatigue Risk */}
            <GaugeCard
              title="Fatigue Risk"
              value={vitalsData.fatigue_risk_score * 100}
              minValue={0}
              maxValue={100}
              ranges={fatigueIndexRange}
              unit="%"
              status={vitalsData.fatigue_risk_status}
              healthyRange="Less than 30%"
            />

            {/* Anxiety Risk */}
            <GaugeCard
              title="Anxiety Risk"
              value={vitalsData.anxiety_risk_score * 100}
              minValue={0}
              maxValue={100}
              ranges={fatigueIndexRange}
              unit="%"
              status={vitalsData.anxiety_risk_status}
              healthyRange="Less than 30%"
            />

            {/* Burnout Risk */}
            <GaugeCard
              title="Burnout Risk"
              value={vitalsData.burnout_risk_score * 100}
              minValue={0}
              maxValue={100}
              ranges={fatigueIndexRange}
              unit="%"
              status={vitalsData.burnout_risk_status}
              healthyRange="Less than 30%"
            />

            {/* Recovery Score */}
            <GaugeCard
              title="Recovery Score"
              value={vitalsData.recovery_score * 100}
              minValue={0}
              maxValue={100}
              ranges={recoveryIndexRange}
              unit="%"
              status={vitalsData.recovery_status}
              healthyRange="More than 70%"
            />
          </div>
        </TabPane>
      </Tabs>
    </div>

    {/* Back Button */}
    <div className="text-center mt-10">
      <button
        type="button"
        className="bg-[#0EA5E9] text-White px-8 py-3 rounded-lg font-semibold shadow-md hover:bg-[#0284C7] transition-all duration-300"
        onClick={() => {
          window.history.back();
        }}
      >
        Back to Home
      </button>
    </div>
  </div>
  );
};

export default SmartVitalsReport;