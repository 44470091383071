import React, { } from "react";

import Mobileai from "../../../Assets/Images/mobile_ai.svg";
import Slider from "react-slick";
import PrevArrow from "../../../Assets/Images/left_arrow.png";
import NextArrow from "../../../Assets/Images/right_arrow.png";
import { RightOutlined } from "@ant-design/icons";
import "react-toastify/dist/ReactToastify.css";
import QuantumBackgroundImage from '../../../Assets/Images/Home/qantum-homepage-background.png'
import { useHistory } from "react-router-dom";

const Services = ({data}) =>{
    const history = useHistory()
    // let result = data.headerComp[0]
    const settings = {
        dots: false,
        // infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        // centerMode: true,
        // autoplay: true,
        autoplaySpeed: 3500,
        // centerPadding: "100px",
        prevArrow: <img src={PrevArrow} alt="icons" />,
        nextArrow: <img src={NextArrow} alt="icons" />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
        // centerMode: true,
        // centerPadding: "100px"
            //   dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              adaptiveHeight: true,
              arrows: false
            },
          },
        ],
      };

      const splitHeading = (sentence) => {
        // Trim sentence and extract words
        const trimmedSentence = sentence.trim();
        const words = trimmedSentence.split(' ');
      
        // Get the last word and the rest of the sentence
        const lastWord = words.pop(); // Removes the last word from the array
        const restOfSentence = words.join(' '); // Joins the remaining words
      
        return (
          <p className="text-center mb-4 md:mb-8" >
            <span className="text-2xl md:text-4xl font-bold" style={{ color: '#004171' }}>{restOfSentence}</span>{' '}
            <span className="text-2xl md:text-4xl font-bold" style={{ color: '#42BA85' }}>{lastWord}</span>
          </p>
        );
      };

return(
    <section className="my-12 md:my-20"> 
    {splitHeading(data?.heading)}
    <div className="max-w-6xl m-auto" >
    <Slider {...settings}>
    <button onClick={()=> history.push("/services/book-an-appointment")} className="flex flex-col justify-center items-center" >
        <img  style={{borderRadius: "50%", height: 150, width: 150, border: "2px solid black", margin: "auto"}} src ={data?.secondComp[0].image_url} />
        <p className="text-center text-base text-semibold mt-2" >{data?.secondComp[0]?.subHeading}</p>
      </button>
      <button onClick={()=> history.push("/services/lab")}  className="flex flex-col justify-center">
        <img style={{borderRadius: "50%", height: 150, width: 150, border: "2px solid black", margin: "auto"}} src ={data?.secondComp[1].image_url} />
        <p className="text-center text-base text-semibold mt-2">{data?.secondComp[1]?.subHeading}</p>
      </button>
      {/* <button onClick={()=> history.push("/services/lab?type=radio")}  className="flex flex-col justify-center">
        <img style={{borderRadius: "50%", height: 150, width: 150, border: "2px solid black", margin: "auto"}} src ={data?.secondComp[1].image_url} />
        <p className="text-center text-base text-semibold mt-2">{data?.secondComp[6]?.subHeading}</p>
      </button> */}
      <button onClick={()=> history.push("/services/order-medicines")}  className="flex flex-col justify-center">
        <img style={{borderRadius: "50%", height: 150, width: 150, border: "2px solid black", margin: "auto"}} src ={data?.secondComp[2].image_url} />
        <p className="text-center text-base text-semibold mt-2">{data?.secondComp[2]?.subHeading}</p>
      </button>
      <button onClick={()=> history.push("/services/surgery")}  className="flex flex-col justify-center">
        <img style={{borderRadius: "50%", height: 150, width: 150, border: "2px solid black", margin: "auto"}} src ={data?.secondComp[3].image_url} />
        <p className="text-center text-base text-semibold mt-2">{data?.secondComp[3]?.subHeading}</p>
      </button>
      <button onClick={()=> history.push("/services/membership")}  className="flex flex-col justify-center">
        <img style={{borderRadius: "50%", height: 150, width: 150, border: "2px solid black", margin: "auto"}} src ={data?.secondComp[4].image_url} />
        <p className="text-center text-base text-semibold mt-2">{data?.secondComp[4]?.subHeading}</p>
      </button>
      <button onClick={()=> history.push("/services/concierge")} className="flex flex-col justify-center">
        <img style={{borderRadius: "50%", height: 150, width: 150, border: "2px solid black", margin: "auto"}} src ={data?.secondComp[5].image_url} />
        <p className="text-center text-base text-semibold mt-2">{data?.secondComp[5]?.subHeading}</p>
      </button>
            </Slider>
            </div>
  </section>
)
}

export default Services



