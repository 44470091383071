import { useState } from 'react';
import http from '../../../../Redux/Services/http-common'

const MobDrugTable = ({ items,orderId }) => {

  // const [invoiceUrl, setInvoiceUrl] = useState(null);

  // const fetchInvoices = async () => {
  //   try {
  //     const url = `${process.env.REACT_APP_NEWBASEURL}EvitalInvoice/list?cOrderId=${orderId}`;
  //     const response = await http.get(url);

  //     if (response.status !== 200) {
  //       console.error('Failed to fetch invoices:', response.statusText);
  //       return;
  //     }

  //     const data = response.data;

  //     console.log(data, 'Fetched Invoices Data');

  //     const validItems = items.filter(item => item.consignmentId); // Filter out invalid items
  //     if (validItems.length === 0) {
  //       console.warn('No valid items found with consignmentId');
  //       return;
  //     }

  //     // Match consignmentId and get the invoice URL
  //     const matchedItem = data.data.find(invoice =>
  //       validItems.some(item => {
  //         console.log('Checking consignmentId:', item.consignmentId);
  //         return item.consignmentId === invoice.consignmentId;
  //       })
  //     );
      
     

  //     if (matchedItem) {
  //       setInvoiceUrl(matchedItem.invoiceUrl); 
  //     } else {
  //       console.log('No matching consignmentId found');
  //       setInvoiceUrl(null);
  //     }

      
  //   } catch (error) {
  //     console.error('Error fetching invoices:', error);
  //   }
  // };

  // console.log(invoiceUrl,"ftrtfgh")

  // const handleViewInvoiceClick = () => {
  //   fetchInvoices();
  // };

    return (
      <>
       {/* <div  className="text-blue-500 underline cursor-pointer text-right mt-4 mb-4"> 
       <button
  className="text-primaryColor underline cursor-pointer"
  onClick={() => {
    window.open(invoiceUrl, '_blank');
    handleViewInvoiceClick();
  }}
>
  View Invoice
</button>
         </div>  */}
      <div className="w-full mx-auto space-y-4">
        {items.map((drug, idx) => (
          <div
            key={idx}
            className="flex justify-between items-center p-5 bg-gradient-to-r from-lightBlue to-White rounded-xl transform hover:scale-105 transition-transform duration-200 ease-in-out"
          >
          
            <div className="flex flex-col">
              <span className="text-sm font-semibold text-gray-800">
                {drug?.drugName || "Medicine Name"}
              </span>
              <span className="text-sm text-[#313131] mt-1">
               <span className="font-medium text-[#313131]">{drug?.quantity || 0}</span> 
                &nbsp; x &nbsp; ₹{drug?.unitPrice || 0}
              </span>
            </div>
  
           
            <div className="flex flex-col items-end space-x-4">
            
              {drug?.discountAmount  ? (
                <span className="text-[#313131] line-through text-base">
                  ₹{drug?.unitPrice *  drug?.quantity }
                </span>
              ) : null}
           
              <span className="text-base font-semibold text-secondaryColor">
                ₹{drug?.totalAmount || 0}
              </span>
            </div>
          </div>
        ))}
      </div>
      </>
    );
  };
  
  export default MobDrugTable;
  