import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { TimePicker, message } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { trackCheckout } from "../../utils/analytics/events";
import axios from "axios";
import { useParams } from "react-router-dom";
const SlotButton = ({
  slot,
  index,
  type,
  selectedDate,
  selectTimeIndex,
  timeIndex,
  setIsProcessEnabled,
  onSelectSlot,
}) => {
  const now = moment();
  const selectedMoment = moment(selectedDate, "MM/DD/yyyy");
  const slotEndDateTime = moment(
    `${selectedDate} ${slot.toTime}`,
    "MM/DD/yyyy hh:mm A"
  );

  // Determine if the slot is in the past only if the selected date is today
  const slotInPast =
    selectedMoment.isSame(now, "day") && slotEndDateTime.isBefore(now);

  // Determine if the slot should be disabled
  const isDisabled = slotInPast || slot.slotAvailability === "N";

  // Handle Slot Selection
  const handleClick = () => {
    if (isDisabled) return;

    selectTimeIndex({
      [type]: true,
      index: index,
      time: slot.fromTime,
      slotData: slot,
    });

    setIsProcessEnabled(true);

    if (onSelectSlot) {
      onSelectSlot(slot);
    }
  };

  return (
    <button
      type="button"
      disabled={isDisabled}
      onClick={handleClick}
      className={`${
        timeIndex[type] && timeIndex.index === index
          ? "bg-secondaryColor text-White"
          : "border text-brand-biscay"
      } ${
        isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
      } p-1 w-20 m-1 rounded text-xs font-medium`}
      title={
        isDisabled
          ? slotInPast
            ? "This slot is in the past"
            : "This slot is unavailable"
          : ""
      }
    >
      {slot.fromTimeDisplay}
    </button>
  );
};

function NewDoctorSlots(props) {
  console.log(props,"fbfnnjfjnfj")
  const history = useHistory();
  const location = useLocation();

  const [refreshSlots, setRefreshSlots] = useState(false);

  // Extract query parameters
  const params = useParams();
  const search = useLocation().search;
  const when_appointment_search_param = new URLSearchParams(search).get("when");
  const paid_search_param = new URLSearchParams(search).get("paid");
  const id_search_param = new URLSearchParams(search).get("id");
  const reason_search_param = new URLSearchParams(search).get("reason");
  const rel_search_param = new URLSearchParams(search).get("rel");
  const key_search_param = new URLSearchParams(search).get("key");
  const from_time_search_param = new URLSearchParams(search).get("from");
  const { userData, doctorData } = props;

  console.log(reason_search_param,"fgvhbjn")

  const redirectTo = (data, query) => {
    console.log(data,"edrfghb")
    if (userData?.sessionid) {
      history.push({
        pathname: APP_ROUTES.PAYMENT,
        search: `?${query}`,
        state: data,
        // state:{data : data, patientId : patientId}
      });
    } else {
      history.push({
        pathname: APP_ROUTES.HOME,
        state: { background: location, isLoginModalOpen: true },
      });
    }
  };
  useEffect(() => {
    if (paid_search_param == "no" && when_appointment_search_param) {
      console.log(paid_search_param, "sdvdshosdhouds");
      setSelectedDate(when_appointment_search_param);
    }
  }, [when_appointment_search_param]);

  useEffect(() => {
    if (paid_search_param !== "no") {
      if (doctorData?.starDoctor == "1" || doctorData?.starDoctor == 1) {
        getSlots(
          moment().add(1, "days").format("MM/DD/yyyy"),
          moment().add(1, "days").format("MM/DD/yyyy")
        );
        setSelectedDate(moment().add(1, "days").format("MM/DD/yyyy"));
        setCurrentDate(moment().add(1, "days"));
      } else {
        getSlots(moment().format("MM/DD/yyyy"), moment().format("MM/DD/yyyy"));
        setCurrentDate(moment());
        setSelectedDate(moment().format("MM/DD/yyyy"));
      }
    } else {
      if (doctorData?.starDoctor == "1" || doctorData?.starDoctor == 1) {
        getSlots(when_appointment_search_param, when_appointment_search_param);
        setSelectedDate(when_appointment_search_param);
        setCurrentDate(when_appointment_search_param);
      } else {
        getSlots(when_appointment_search_param, when_appointment_search_param);
        setCurrentDate(when_appointment_search_param);
        setSelectedDate(when_appointment_search_param);
      }
    }
  }, [doctorData?.starDoctor, refreshSlots]);
  // State Management
  const [screen, setScreenWidth] = useState(window.innerWidth);
  const [currentDate, setCurrentDate] = useState(
    Number(doctorData?.starDoctor) === 1 ? moment().add(1, "days") : moment()
  );
  const [days, setDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState({
    morning: [],
    afternoon: [],
    evening: [],
  });
  const [sortedSlots, setSortedSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    currentDate?.format("MM/DD/yyyy")
  );
  const [isShow, setShowTimeSlots] = useState(false);
  const [timeIndex, selectTimeIndex] = useState({});
  const [isTimeSlotEmptyForEliteDoctor, setIsTimeSlotEmptyForEliteDoctor] =
    useState(false);
  const [timeSelectedFromInput, setTimeSelectedFromInput] = useState(null);
  const [isProcessEnabled, setIsProcessEnabled] = useState(false);


  const getSlots = async (payload) => {
    try {
      const baseURL = process.env.REACT_APP_BASEURL;
      if (!baseURL) {
        throw new Error("Base URL is not defined in environment variables.");
      }

      const response = await axios.get(
        `${baseURL}AppointmentTemplate/getAppointmentSlots`,
        {
          params: payload,
        }
      );


      if (response.status === 200) {
        const slots = response.data;

        if (
          slots.morning &&
          Array.isArray(slots.morning) &&
          slots.afternoon &&
          Array.isArray(slots.afternoon) &&
          slots.evening &&
          Array.isArray(slots.evening)
        ) {
          setTimeSlots(slots);
          setSortedSlots([
            ...slots.morning,
            ...slots.afternoon,
            ...slots.evening,
          ]);
          const hasSlots =
            slots.morning.length > 0 ||
            slots.afternoon.length > 0 ||
            slots.evening.length > 0;

          setShowTimeSlots(hasSlots);

        
          if (Number(doctorData?.starDoctor) === 1 && !hasSlots) {
            setIsTimeSlotEmptyForEliteDoctor(true);
          } else {
            setIsTimeSlotEmptyForEliteDoctor(false);
          }
        } else {
      
          setShowTimeSlots(false);
          setIsTimeSlotEmptyForEliteDoctor(
            Number(doctorData?.starDoctor) === 1
          );
        }
      } else {
        toast.error("Failed to fetch slots. Please try again later.");
        setShowTimeSlots(false);
        setIsTimeSlotEmptyForEliteDoctor(Number(doctorData?.starDoctor) === 1);
      }
    } catch (error) {
      console.error("Error fetching slots:", error);
      // toast.error("An error occurred while fetching slots.");
      setShowTimeSlots(false);
      setIsTimeSlotEmptyForEliteDoctor(Number(doctorData?.starDoctor) === 1);
    }
  };

  // Initial Slot Fetch Logic
  useEffect(() => {
    const initialFetchSlots = () => {
      let fromDate =
        Number(doctorData?.starDoctor) === 1
          ? moment().add(1, "days").format("MM/DD/yyyy")
          : moment().format("MM/DD/yyyy");

      const payload = {
        userId: doctorData?.code,
        fromDate: fromDate,
        toDate: fromDate,
        status: 1,
        video: "Y",
      };

      getSlots(payload);
    };

    initialFetchSlots();
  }, [doctorData?.starDoctor, doctorData?.code]);

  // Function to fetch and set days (for initial load)
  const getDays = () => {
    let daysArray = [];
    let daysRequired = 3;
    for (let i = 0; i < daysRequired; i++) {
      daysArray.push({
        vis: moment(currentDate).add(i, "days").format("ddd,DD"),
        date: moment(currentDate).add(i, "days").format("MM/DD/yyyy"),
      });
    }
    setDays(daysArray);
  };
  const momentTime = moment(timeSelectedFromInput?.$d);

  const timeOnly = momentTime.format("HH:mm");

  const reason= localStorage.getItem("complain")
  console.log(reason,selectedDate,timeIndex.slotData?.fromTime,"reasonbvdfhf")


  const goToPayment = () => {
    // toast("we are currently in the process of upgrading our website, apologies for the inconvenience");

    let dataObj = {
      consultationsReason: "Fever",
      consultationsType: props?.appointmentType,
      doctorType: doctorData?.quickConsulted == 0 ? "I" : "E",
      createdBy: userData?.sessionid,
      fromTime: timeIndex.slotData?.fromTime,
      hospitalId: props?.hospitalId,
      locationId: props?.locationId,
      modifiedBy: userData?.code,
      patientEmail: userData?.email,
      patientId: userData?.code,
      patientMobileNo: userData?.mobile,
      patientName: userData.firstName,
      // status: 1,
      toTime: timeIndex.slotData?.toTime,
      userEmail: doctorData?.email,
      userId: doctorData?.code,
      userMobile: doctorData?.mobile,
      userName: doctorData?.firstName + " " + doctorData?.lastName,
      userQualification: doctorData?.qualification,
      userSalutation: doctorData?.salutation,
      whenAppointment: selectedDate,
      amount:
        props?.appointmentType === "V"
          ? props?.fees?.videoConsFee
          : props.fees?.inPersonConsFee,
    };

    trackCheckout("checkout_doctor");

    if (localStorage.getItem("state")) {
      localStorage.removeItem("state");
    }
    if (
      id_search_param &&
      reason_search_param &&
      // rel_search_param &&
      key_search_param
    ) {
      redirectTo(
        dataObj,
        `q=pay&id=${id_search_param}&reason=${reason_search_param}&key=${key_search_param}`
      );
    } else {
      redirectTo(dataObj, `q=pay`);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const confirmAppointment = () => {
    let dataObj = {
      consultationsReason: "fever",
      consultationsType: props?.appointmentType,
      doctorType: doctorData?.quickConsulted == 0 ? "I" : "E",
      createdBy: userData?.id,
      fromTime: timeOnly,
      hospitalId: props?.hospitalId,
      locationId: props?.locationId,
      modifiedBy: userData?.code,
      patientEmail: userData?.email,
      patientId: userData?.code,
      patientMobileNo: userData?.mobile,
      patientName: userData.firstName,
      status: 14,
      toTime: moment(timeOnly, "hh:mm A").add(15, "minutes").format("HH:mm"),
      userEmail: doctorData?.email,
      userId: doctorData?.code,
      userMobile: doctorData?.mobile,
      userName: doctorData?.firstName + " " + doctorData?.lastName,
      userQualification: doctorData?.qualification,
      userSalutation: doctorData?.salutation,
      whenAppointment: selectedDate,
      amount:
        props?.appointmentType === "V"
          ? props?.fees?.videoConsFee
          : props.fees?.inPersonConsFee,
    };
    if (localStorage.getItem("state")) {
      localStorage.removeItem("state");
    }
    console.log(dataObj, "svshvoishobvsh");
    redirectTo(dataObj, "q=confirm");
  };

 
  const getselectedSlot = (date) => {
    
    setIsProcessEnabled(false);
    selectTimeIndex({});
    setSelectedDate(date);

    const payload = {
      userId: doctorData?.code,
      fromDate: date,
      toDate: date,
      status: 1,
      video: "Y",
    };

    getSlots(payload);
  };

  // Function to handle custom slot selection via TimePicker
  const onCustomSlotSelect = (timeMoment) => {
    if (!timeMoment) return;
  
    const selectedDateFormatted = moment(selectedDate, "MM/DD/yyyy").format(
      "MM/DD/yyyy"
    );
    const selectedTime = moment(
      `${selectedDateFormatted} ${timeMoment.format("HH:mm")}`,
      "MM/DD/yyyy HH:mm"
    );
  
    const now = moment(); 
  
   
    if (doctorData?.starDoctor === 1) {
      const minutesDifference = selectedTime.diff(now, "minutes");
  
      
      if (
        moment(selectedDate).isSame(moment().add(1, "day"), "day") &&
        minutesDifference < 1440
      ) {
        message.warning(
          "This doctor can't be booked within 24 hours for tomorrow."
        );
        return;
      }
    }
  
    if (now.isAfter(selectedTime)) {
      message.warning("Please select a future time slot.");
      return;
    }
  
    setTimeSelectedFromInput(timeMoment);
    setIsProcessEnabled(true);
  };
  
  const disabledTime = () => {
    const now = moment();
    const isTomorrow = moment(selectedDate).isSame(
      moment().add(1, "day"),
      "day"
    );
  
    if (isTomorrow) {
      const restrictedStart = now.clone();
      const restrictedEnd = now.clone().add(24, "hours");
  
      return {
        disabledHours: () => {
          const hours = [];
          for (let i = 0; i < 24; i++) {
            const hourMoment = moment(selectedDate)
              .startOf("day")
              .hour(i);
            if (
              hourMoment.isBetween(restrictedStart, restrictedEnd, "hour", "[]")
            ) {
              hours.push(i);
            }
          }
          return hours;
        },
        disabledMinutes: (selectedHour) => {
          const minutes = [];
          const restrictedStartMinute = restrictedStart.minute();
          const restrictedEndMinute = restrictedEnd.minute();
  
          if (
            restrictedStart.hour() === selectedHour &&
            restrictedEnd.hour() === selectedHour
          ) {
            for (let i = 0; i < 60; i++) {
              if (
                i >= restrictedStartMinute &&
                i <= restrictedEndMinute
              ) {
                minutes.push(i);
              }
            }
          }
          return minutes;
        },
      };
    }
  
    return {}; 
  };



  

  // Effect to fetch initial days
  useEffect(() => {
    getDays();
  }, [currentDate]);


  return (
    <>
      <div className="">
        <ToastContainer />

        {/* Date Navigation */}
        {/* {screen > 1334 ? ( */}
          {/* <div className="flex gap-5 justify-between pb-4 mt-5 items-center">
            <button
              aria-label="Navigate to previous dates"
              onClick={() =>
                setCurrentDate(currentDate.clone().subtract(3, "days"))
              }
              type="button"
              className="disabled:opacity-50 text-primaryColor bg-lightBlue w-[50px] h-[50px] rounded-full"
              //disabled={currentDate.isSameOrBefore(moment(), "day")}
            >
              <LeftOutlined />
            </button>
            <div className="grid grid-cols-3 gap-2 md:gap-4 w-full">
              {days.map((res, i) => (
                <button
                  onClick={() => {
                    getselectedSlot(res.date);
                  }}
                  
                  key={`day-${i}`}
                  type="button"
                  className={`${
                    selectedDate === res.date
                      ? "border-secondaryColor border-[1.5px] bg-lightGreen border-opacity-100"
                      : "border border-brand-biscay2"
                  } border-gray h-11 rounded-lg whitespace-nowrap border text-textGray`}
                >
                  <span
                    className={`inline-block text-sm ${
                      selectedDate === res.date
                        ? "text-secondaryColor font-medium"
                        : "text-textGray"
                    } `}
                  >
                    {moment(res?.date, "MM/DD/yyyy").format("DD,MMM,yyyy")}
                  </span>
                  <span
                    className={`inline-block text-sm pl-2 ${
                      selectedDate === res.date
                        ? "text-secondaryColor font-medium"
                        : "text-textGray"
                    } `}
                  >
                    {res?.date === moment().format("MM/DD/yyyy")
                      ? "[Today]"
                      : `[${res?.vis.split(",")[0]}]`}
                  </span>
                </button>
              ))}
            </div>
            <button
              aria-label="Navigate to next dates"
              onClick={() => setCurrentDate(currentDate.clone().add(3, "days"))}
              type="button"
              className="disabled:opacity-50 text-primaryColor bg-lightBlue w-[50px] h-[50px] rounded-full"
            >
              <RightOutlined />
            </button>
          </div> */}

<div className="flex items-center justify-between pb-4 mt-5">
 
  <button
    aria-label="Navigate to previous dates"
    onClick={() =>
      setCurrentDate(
        currentDate.clone().subtract(
          window.innerWidth < 640 ? 1 : 3, 
          "days"
        )
      )
    }
    disabled={currentDate.isSameOrBefore(moment(), "day")}
    type="button"
    className="disabled:opacity-50 text-primaryColor bg-lightBlue w-10 h-10 rounded-full flex items-center justify-center"
  >
    <LeftOutlined />
  </button>

  {/* Date Buttons */}
  <div className="flex gap-4 w-full justify-center">
    {days.map((res, i) => (
      <button
        onClick={() => {
          getselectedSlot(res.date);
        }}
        key={`day-${i}`}
        type="button"
        className={`${
          selectedDate === res.date
            ? "border-secondaryColor border-[1.5px] bg-lightGreen"
            : "border-gray-300"
        } h-11 rounded-lg border text-textGray px-4 ${
          i === 1 ? "block" : "hidden sm:block"
        } ${moment(res.date).isBefore(moment(), "day") ? "opacity-50 cursor-not-allowed" : ""}`}
        disabled={moment(res.date).isBefore(moment(), "day")}
 
      >
        <span
          className={`inline-block text-sm ${
            selectedDate === res.date
              ? "text-secondaryColor font-medium"
              : "text-textGray"
          }`}
        >
          {moment(res?.date, "MM/DD/yyyy").format("DD/MM/yyyy")}
        </span>
      </button>
    ))}
  </div>

  {/* Next Button */}
  <button
    aria-label="Navigate to next dates"
    onClick={() =>
      setCurrentDate(
        currentDate.clone().add(
          window.innerWidth < 640 ? 1 : 3, 
          "days"
        )
      )
    }
    type="button"
    className="disabled:opacity-50 text-primaryColor bg-lightBlue w-10 h-10 rounded-full flex items-center justify-center"
  >
    <RightOutlined />
  </button>
</div>



        {/* ) : null} */}

        {/* Slot Display */}
        {isShow ? (
          <>
            {timeSlots.morning?.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2 text-primaryColor">
                  Morning Slots
                </h3>
                <div className="flex flex-wrap gap-2 md:gap-4 text-primaryColor">
                  {timeSlots.morning.map((res, i) => (
                    <SlotButton
                      key={`morning-${i}`}
                      slot={res}
                      index={i}
                      type="m"
                      selectedDate={selectedDate}
                      selectTimeIndex={selectTimeIndex}
                      timeIndex={timeIndex}
                      setIsProcessEnabled={setIsProcessEnabled}
                      onSelectSlot={() => {}}
                      className={`${
                        timeIndex === i
                          ? "bg-green-500 text-white"
                          : "bg-white text-blue-800 border border-blue-300"
                      } px-4 py-2 rounded-lg hover:bg-blue-50 transition-all md:px-6 md:py-3 cursor-pointer w-28 md:w-32 text-center`}
                    />
                  ))}
                </div>
              </div>
            )}
            {timeSlots.afternoon?.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2 text-primaryColor">
                  Afternoon Slots
                </h3>
                <div className="flex flex-wrap gap-2 md:gap-4 text-primaryColor">
                  {timeSlots.afternoon.map((res, i) => (
                    <SlotButton
                      key={`afternoon-${i}`}
                      slot={res}
                      index={i}
                      type="a"
                      selectedDate={selectedDate}
                      selectTimeIndex={selectTimeIndex}
                      timeIndex={timeIndex}
                      setIsProcessEnabled={setIsProcessEnabled}
                      onSelectSlot={() => {}}
                      className={`${
                        timeIndex === i
                          ? "bg-green-500 text-White"
                          : "bg-white text-primaryColor border border-blue-300"
                      } px-4 py-2 rounded-lg hover:bg-blue-50 transition-all md:px-6 md:py-3 cursor-pointer w-28 md:w-32 text-center`}
                    />
                  ))}
                </div>
              </div>
            )}
            {timeSlots.evening?.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2 text-primaryColor">
                  Evening Slots
                </h3>
                <div className="flex flex-wrap gap-2 md:gap-4 text-primaryColor">
                  {timeSlots.evening.map((res, i) => (
                    <SlotButton
                      key={`evening-${i}`}
                      slot={res}
                      index={i}
                      type="e"
                      selectedDate={selectedDate}
                      selectTimeIndex={selectTimeIndex}
                      timeIndex={timeIndex}
                      setIsProcessEnabled={setIsProcessEnabled}
                      onSelectSlot={() => {}}
                      className={`${
                        timeIndex === i
                          ? "bg-green-500 text-white"
                          : "bg-white text-blue-800 border border-blue-300"
                      } px-4 py-2 rounded-lg hover:bg-blue-50 transition-all md:px-6 md:py-3 cursor-pointer w-28 md:w-32 text-center`}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          !isTimeSlotEmptyForEliteDoctor ? <div className="m-4 text-xs md:text-sm text-center">No doctor slots available at the moment.</div> : null
        )}

        {isProcessEnabled && timeIndex.time && (
          <div className="flex items-center flex-col justify-center">
            <div className="text-center w-full sm:text-right md:text-right lg:text-right pb-4">
              <button
                // disabled={!timeIndex.time || !hideSlot}
                onClick={goToPayment}
                className={`disabled:opacity-50 ${
                  !timeIndex.time && " cursor-not-allowed "
                } bg-primaryColor text-White w-full sm:w-48 md:w-48 lg:w-48  px-5 py-3 text-sm  font-medium rounded-md`}
              >
                <b>Proceed to Payment</b>
              </button>
            </div>
          </div>
        )}

        {/* Elite Doctor TimePicker */}
        {isTimeSlotEmptyForEliteDoctor && (
          <div className="flex items-center flex-col justify-center mt-5">
            <p className="font-medium text-sm text-textGray">
              Select preferred time for doctor appointment
            </p>
            <div className="md:relative my-2 md:my-4">
              {/* <TimePicker
                use12Hours
                format="h:mm A"
                inputStyle={{ background: "transparent" }}
                value={timeSelectedFromInput}
                placeholder="Select preferred time"
                onChange={onCustomSlotSelect}
                className="w-[200px] h-10"
                
              /> */}
 <TimePicker
    use12Hours={false}
    format="HH:mm"
    inputStyle={{ background: "transparent" }}
    value={timeSelectedFromInput}
    placeholder="Select preferred time"
    onChange={onCustomSlotSelect}
    disabledTime={disabledTime}
    className="w-[200px] h-10"
  />


            </div>
            {isProcessEnabled && (
              <div className="text-center w-full sm:text-right md:text-right lg:text-right pb-4">
                <button
                  onClick={confirmAppointment}
                  disabled={!timeSelectedFromInput}
                  className={`disabled:opacity-50 bg-primaryColor text-White w-full sm:w-48 md:w-48 lg:w-48 px-5 py-3 text-sm font-medium rounded-md`}
                >
                  Proceed to Payment
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default NewDoctorSlots;
