import React, { useState } from "react";
import Layouts from "../../Layouts";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { RightOutlined } from "@ant-design/icons";
import LabImage from "../../../Assets/LabImages/dummylabimage.jpeg";
import ReportsImage from "../../../Assets/Images/Icons/reports.svg";
import HomeTestImage from "../../../Assets/Images/Icons/hometest.svg";
import FastingImage from "../../../Assets/Images/Icons/fasting.svg";
import OffersImage from "../../../Assets/Images/Icons/offersdiscount.svg";
import { Collapse, Modal, message } from "antd";
import Arrowimage from "../../../Assets/Images/Icons/linearrow.png";
import LabTestIcon from "../../../Assets/Images/LabTestIcon.svg";
import { AddtoCart, getCartDetails } from "../../../Redux/Action/cartPlaceOrderAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import { trackAddToCartEvent } from "../../utils/analytics/events";
import parameter from '../../../Assets/Images/parameter.svg'
const { Panel } = Collapse;

function LabView() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.authReducer.patientData);
  const { cartList } = useSelector((state) => state.cartReducer);
  const [openDialog, setDialog] = useState(false);
  const [labNewName, setLabNewName] = useState("");
  const [isAdding, setIsAdding] = useState(-1);
  const labData = location.state.labdata;
  const labTestcode = location.state.labTestCode;
  const isaddedincart = location.state.isaddedincart;
  const labName = location.state?.labName;
  const pathName = location.state?.pathName;
  const [cartaddedlabdata, setcartaddedlabdata] = useState(labTestcode);
  const [isAddedInCart, setIsAddedInCart] = useState(isaddedincart || false);
  console.log(labData, labTestcode, isAddedInCart, 'asdfjnnjnj')

  const CustomPanelHeader = ({ headerText }) => {
    return (
      <div className="flex gap-4 items-center">
        <img src={Arrowimage} alt="images" className="h-[15px]" />
        <span>{headerText}</span>
      </div>
    );
  };

  const redirectToLogin = (event, location) => {
    event.preventDefault();
    history.push(location);
  };

  const addTocart = (e, data, index, labTestCode, quantity = 1) => {
    setcartaddedlabdata(data.labTestCode)
    console.log(e, data, index, labTestCode, "labtestttt")
    if (isAddedInCart) return;
    if (!userData.code) {
      history.push({
        pathname: APP_ROUTES.HOME,
        state: { isLoginModalOpen: true }
      });
    }

    if (cartList?.patientLabTestsOrder) {
      let availableItem = cartList?.patientLabTestsOrder.find(
        (item) => item.hospitalId === data.labId
      );

      if (availableItem === undefined) {
        // setLabNewName(
        //   cartList?.patientLabTestsOrder[0]?.patientLabTestsOrderDetailsList[0]
        //     ?.hospitalName
        // );
        message.warning('Select this test from the same lab or replace the tests in your cart')
        return;
      }
    }

    let prescriptionRequired = "N";
    let ePrescriptionRequired = "N";

    if (data?.medicinePrescriptionRequired == "N") {
      prescriptionRequired = "N";
      ePrescriptionRequired = "N";
    } else if (data?.medicinePrescriptionRequired == "Y") {
      prescriptionRequired = "Y";
      ePrescriptionRequired = "Y";
    }
    let calculatedAmount = data?.amount;
    const totalAmount = calculatedAmount * quantity;
    if (!labTestcode) {

      let dataObj = {};
      if (cartList && cartList.patientLabTestsOrder) {
        console.log(cartList, "dsvsdhvousdhvshvsov");
        cartList.totalAmount = cartList?.totalAmount
          ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
          : parseFloat(totalAmount);
        cartList.patientLabTestsOrder.map((res) => {
          res.patientLabTestsOrderDetailsList.push({
            patientLabTestOrderId: data.id,
            labTestCode: data.labTestCode,
            labTestName: data.labTestName,
            hospitalId: data.labId,
            locationId: data.locationId,
            amount: data.amount,
            discountAmount: data?.discountAmount ? data?.discountAmount : 0,
            totalAmount: totalAmount,
            status: 1,
            labTestType: data.testType,

            quantity: quantity,
            cartId: cartList && cartList.id ? cartList.cartId : "",
            createdBy: userData.code,
            createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            modifiedBy: userData.code,
            modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
            orderId: null,
            patientId: userData.code,
            prescriptionRequired: prescriptionRequired,
            ePrescriptionRequired: ePrescriptionRequired,
            medicineTypeOfSell: null,
            membershipCode: null,
            membershipName: null,
            membershipCard: null,
            membershipDiscountPercentage: 0.0,
          });
        });
        dataObj = cartList;
      } else {
        console.log(cartList, "dsvsoudvdvhsdoivs");
        dataObj = {
          cartId: cartList && cartList.id ? cartList.cartId : "",
          createdBy: userData.code,
          createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          modifiedBy: userData.code,
          modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
          orderId: null,
          patientId: userData.code,
          status: 1,

          labOrdersYN: true,
          drugsOrdersYN: false,
          totalAmount: cartList?.totalAmount
            ? parseFloat(totalAmount) + parseFloat(cartList?.totalAmount)
            : parseFloat(totalAmount),
          patientLabTestsOrder: [
            {
              hospitalId: data.labId,
              locationId: data.locationId,

              orderId: "",
              patientId: userData.code,
              orderDetailsRequired: "Y",
              prescriptionId: null,
              cartId: cartList && cartList.id ? cartList.cartId : "",
              txnId: "",
              amount: totalAmount,
              address1: data.address1,
              address2: data.address2,
              address3: null,
              city: data.city,
              state: data.state,
              country: null,
              pincode: data.pinCode,
              deliveryAddress1: data.address1,
              deliveryAddress2: data.address2,
              deliveryAddress3: null,
              deliveryCity: data.city,
              deliveryState: data.state,
              deliveryCountry: null,
              deliveryZipcode: data.pinCode,
              createdBy: userData.code,
              createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              modifiedBy: userData.code,
              modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
              status: 1,
              procedureStatus: -1,
              payMode: "E",
              collectionStatus: null,
              paymentLinkForPatient: "N",
              discountCouponCode: null,
              patientName: userData.firstName,
              patientGender: userData?.gender,
              patientMobile: userData?.mobile,
              patientLabTestsOrderDetailsList: [
                {
                  patientLabTestOrderId: data.id,
                  labTestCode: data.labTestCode,
                  labTestName: data.labTestName,
                  hospitalId: data.labId,
                  locationId: data.locationId,
                  amount: data.amount,
                  discountAmount: data?.discountAmount ? data?.discountAmount : 0,
                  totalAmount: totalAmount,
                  status: 1,
                  labTestType: data.testType,
                  tat: data?.tat,
                  labStartTime: data?.labStartTime,
                  labEndTime: data?.labEndTime,
                  description: data?.description,

                  quantity: quantity,
                  cartId: cartList && cartList.id ? cartList.cartId : "",
                  createdBy: userData.code,
                  createdDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  modifiedBy: userData.code,
                  modifiedDate: moment().format("yyyy-MM-DD HH:mm:ss"),
                  orderId: null,
                  patientId: userData.code,
                  prescriptionRequired: prescriptionRequired,
                  ePrescriptionRequired: ePrescriptionRequired,
                  medicineTypeOfSell: null,
                  membershipCode: null,
                  membershipName: null,
                  membershipCard: null,
                  membershipDiscountPercentage: 0.0,
                },
              ],
            },
          ],
          // };
        };
      }

      // dispatch(AddtoCart(dataObj)).then((res) => {
      //   trackAddToCartEvent(data.amount, data.labTestName)
      //   dispatch(getCartDetails(userData.code));
      // });
      let payload = {


        patientId: userData.code,
        labTestCode: data.labTestCode,
        amount: data.amount,
        hospitalId: data.labId,
        locationId: data.locationId,
        labTestName: data.labTestName,
        labTestType: data.testType,
        sourceChannel: "D2C",

      };


      fetch(`${process.env.REACT_APP_BASEURL}cart/addToCart/labtest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then(response => response.json())
        .then((res) => {
          console.log(res, "vghsdvhcvdsg")
          setIsAddedInCart(true);
          trackAddToCartEvent(data.amount, data.labTestName)



          dispatch(getCartDetails(userData.code));
        })
        .catch((err) => {
          console.error("Error adding to cart:", err);

        });
    }
  };


  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Layouts>
      <section className="bg-lightGray">
        <div className="w-[90%] md:w-[80%] min-h-[80vh] mx-auto py-[20px] md:py-[30px]">
          <div className="w-full bg-primaryColor rounded-md px-8 py-4 mb-5">
            <div className=" md:text-xl text-White">
              <Link to="/services/lab"> Lab Test </Link>
              <RightOutlined className="text-base" />

              <span className="cursor-pointer text-secondaryColor">
                <Link to={pathName}> {labName} </Link>
              </span>

            </div>
          </div>
          {/* <div className="bg-White p-5 md:p-7 rounded-md mt-5">
            <div className=" md:flex justify-between gap-4 ">
              <div className="md:w-[85%] md:flex gap-5">
                <div className="md:w-[30%] mb-5 md:mb-0">
                  <img
                    src={process.env.REACT_APP_IMG_BASEURL + labData?.logo}
                    alt={labData?.logo}
                    className="w-full h-full rounded-md"
                  />
                </div>
                <div className="md:w-[70%]">
                  <h4 className="font-semibold text-lg text-primaryColor mb-2">
                    {labData?.testDisplayName}
                  </h4>
                  <p className="text-textGray text-sm">
                    {labData?.description}
                  </p>
                  <div className="bg-lightBlue text-primaryColor w-fit px-4 rounded-full py-1 text-sm mt-5">
                    <span>
                      Total <b>{labData?.noOfTestIncluded}</b> test will be
                      there in {labData?.testKnownAs}
                    </span>
                  </div>
                </div>
              </div>
              <div className="md:w-[15%] flex justify-between md:flex-col mt-5 md:mt-0">
                <div className="flex flex-col">
                  <label className="text-textGray text-xs font-light text-right">
                    Total Amount
                  </label>
                  <div className="text-secondaryColor font-semibold text-lg md:text-right">
                    ₹{labData?.amount}
                  </div>
                </div>
                <div className="justify-end flex mb-3">
                  <button onClick={(e) => { addTocart(e, labData,labTestcode) }}
                   className={`${
                    cartaddedlabdata
                    
                      ? "text-secondaryColor bg-White border border-secondaryColor"
                      : "bg-primaryColor text-White"
                      }  w-[150px] h-[45px]  text-sm font-medium rounded cursor-pointer `}
                  >
                  {cartaddedlabdata ? "Added":  "Add to Cart"}
                  </button>
                </div>
              </div>
            </div>
          </div> */}

          <div className="bg-White p-5 md:p-7 rounded-lg mt-5 shadow-lg border border-[#E8E8E8]">
            <div className="md:flex justify-between gap-8 items-start">
              {/* Image Section */}
              <div className="flex-shrink-0 w-full md:w-[20%] mb-5 md:mb-0">
                <img
                  src={process.env.REACT_APP_IMG_BASEURL + labData?.logo}
                  alt={labData?.logo}
                  className="w-full h-auto rounded-md"
                />
              </div>

              {/* Content Section */}
              <div className="flex-grow w-full md:w-[55%]">
                <h4 className="font-semibold text-xl md:text-2xl text-primaryColor mb-3">
                  {labData?.testDisplayName}
                </h4>
                <p className="text-textGray text-sm md:text-base leading-relaxed mb-4">
                  {labData?.description}
                </p>
                <div className="bg-lightBlue text-primaryColor w-fit px-4 py-2 rounded-full  text-xs md:text-sm">
                  <span>
                    Total <b>{labData?.noOfTestIncluded}</b> test(s) will be included in{" "}
                    {labData?.testKnownAs}
                  </span>
                </div>
              </div>

              {/* Action Section */}
              <div className="w-full md:w-[25%] mt-4 md:mt-0 flex flex-row md:flex-col justify-between md:justify-start items-center mmd:items-start text-left">
                {/* Amount */}
                <p className="text-secondaryColor font-bold text-xl md:text-2xl  mb-3">₹{labData?.amount}</p>

                {/* Add to Cart Button */}
                <button
                  onClick={(e) => addTocart(e, labData, labTestcode)}
                  className={`${cartaddedlabdata || isAddedInCart
                      ? "text-secondaryColor border border-secondaryColor bg-White"
                      : "bg-primaryColor text-White"
                    } p-2 text-sm md:text-base font-medium rounded shadow-md hover:bg-opacity-90`}
                  disabled={isAddedInCart || cartaddedlabdata}
                >
                  {cartaddedlabdata || isAddedInCart ? "Added" : "Add to Cart"}
                </button>
              </div>
            </div>
          </div>



          {/* <div className="md:flex gap-5 mt-5">
            <div className="bg-lightGreen p-5 flex gap-4 border border-secondaryColor rounded-md mb-5 md:mb-0">
              <div className="">
                <img src={ReportsImage} alt="images" />
              </div>
              <div>
                <h4 className="text-secondaryColor text-base font-medium">
                  Reports
                </h4>
                <p className="text-textGray text-sm">Get it on same day</p>
              </div>
            </div>
            <div className="bg-lightGreen p-5 flex gap-4 border border-secondaryColor rounded-md mb-5 md:mb-0">
              <div className="">
                <img src={HomeTestImage} alt="images" />
              </div>
              <div>
                <h4 className="text-secondaryColor text-base font-medium">
                  Home Test
                </h4>
                <p className="text-textGray text-sm">Get home test at home</p>
              </div>
            </div>
            <div className="bg-lightGreen p-5 flex gap-4 border border-secondaryColor rounded-md mb-5 md:mb-0">
              <div className="">
                <img src={FastingImage} alt="images" />
              </div>
              <div>
                <h4 className="text-secondaryColor text-base font-medium">
                  Fasting
                </h4>
                <p className="text-textGray text-sm">Not required</p>
              </div>
            </div>
            <div className="bg-lightGreen p-5 flex gap-4 border border-secondaryColor rounded-md">
              <div className="">
                <img src={OffersImage} alt="images" />
              </div>
              <div>
                <h4 className="text-secondaryColor text-base font-medium">
                  Offers & Discount
                </h4>
                <p className="text-textGray text-sm">
                  Get affordable discount & Offers
                </p>
              </div>
            </div>
          </div> */}

          <div className="bg-White p-5   md:p-7 rounded-md mt-5">
          {labData?.testOverview &&
              <div className="mb-5">
                <h3 className="text-primaryColor font-medium text-base mb-2">
                  Overview
                </h3>
                <p className="text-textGray text-sm">{labData?.testOverview}</p>
              </div>
            }

            {/* {labData?.testParameters?.length && (
              <div className="mb-5">
                <h3 className="text-primaryColor font-medium text-base mb-2">
                  Test Parameter
                </h3>
                <div className="flex gap-6 flex-wrap ">
                  <div className="pr-4 py-2 rounded-md flex flex-wrap gap-2 lg:gap-4 w-max">
                    {labData?.testParameters.map((item) => (
                      <div
                        className="py-1 px-3 rounded-lg text-sm sm:text-base"
                        style={{
                          fontWeight: "400",
                          lineheight: "30px",
                          color: "#3A3A3A",
                          border: "0.5px solid rgba(0, 93, 141, 0.34)",
                        }}
                        key={item}
                      >
                        {capitalizeWords(item)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )} */}
            {labData?.testParameters?.length >0 && (
             
              <div className="mb-5 ">
                <h3 className="text-primaryColor font-medium text-base mb-2">
                  {labData?.testParameters?.length} parameters <span className="text-green-500">covered</span>
                </h3>
                <div className="flex gap-6 flex-wrap">
                  <div className="pr-4 py-2 rounded-md flex flex-wrap gap-2 lg:gap-4 w-max">
                    {labData?.testParameters.map((item, index) => (
                      <div
                        key={index}
                        className="py-2 px-4 flex items-center gap-2 rounded-lg text-sm sm:text-base bg-lightBlue shadow-sm"
                        style={{
                          fontWeight: "400",
                          lineHeight: "30px",
                          color: "#3A3A3A",
                          border: "1px solid rgba(0, 93, 141, 0.34)",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {/* Replace with an appropriate icon */}
                        <img
                          src={parameter}
                          alt="Icon"
                          className="w-6 h-6"
                        />
                        {capitalizeWords(item)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}


            {labData?.preTestInfo &&
              <div>
                <h3 className="text-primaryColor font-medium text-base mb-2">
                  Test Information
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
                  {labData?.preTestInfo ? (
                    <div className="mb-0">
                      <label className="text-textGray font-light text-sm">
                        Pre-test Information
                      </label>
                      <p className="text-textGray font-medium text-sm">
                        {labData?.preTestInfo}
                      </p>
                    </div>
                  ) : null}
                  {labData?.method ? (
                    <div className="mb-0">
                      <label className="text-textGray font-light text-sm">
                        Method
                      </label>
                      <p className="text-textGray font-medium text-sm">
                        {labData?.method}
                      </p>
                    </div>
                  ) : null}

                  {labData?.category ? (
                    <div className="mb-0">
                      <label className="text-textGray font-light text-sm">
                        Category
                      </label>
                      <p className="text-textGray font-medium text-sm">
                        {labData?.category}
                      </p>
                    </div>
                  ) : null}
                  {labData?.specimen ? (
                    <div className="mb-0">
                      <label className="text-textGray font-light text-sm">
                        Specimen
                      </label>
                      <p className="text-textGray font-medium text-sm">
                        {labData?.specimen}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            }
          </div>
          {labData?.qa.length > 0 ? (
            <div className="bg-primaryColor p-7 rounded-md mt-5">
              <h3 className="text-White font-medium text-xl mb-2 text-center">
                Frequent Asked Questions
              </h3>
              <Collapse
                accordion
                className="faqCollapse faqSecond faqLab"
                expandIconPosition="end"
              >
                {labData?.qa &&
                  Object.keys(labData.qa).map((key) => (
                    <Panel
                      header={labData.qa[key].label}
                      key={key} // Use 'key' instead of hardcoded "1"
                    >
                      <p className="text-White font-extralight">
                        {labData.qa[key].value}
                      </p>
                    </Panel>
                  ))}
              </Collapse>
            </div>
          ) : null}
        </div>
      </section>

      <Modal
        visible={openDialog}
        modal={false}
        style={{ width: "700px", height: "auto" }}
        // className="w-20 m-auto lg:w-5/6 h-auto"
        onHide={() => setDialog(false)}
      >
        <>
          <div className="flex justify-center  align-middle inline-bloc">
            <div className="flex">
              <img src={LabTestIcon} alt="No Lab test found Available" />
            </div>
            <div className="font my-8 text-center  ">
              {" "}
              {`You have added tests from  ${labNewName} lab in your cart. Select this test from the same lab or replace the tests in your cart.`}
              {``}
            </div>
          </div>
        </>
      </Modal>
    </Layouts>
  );
}

export default LabView;
