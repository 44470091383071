import React, { useState } from "react";
import pills from "../../../Assets/Images/Cart/Medicines.jpg";
import RXicon from "../../../Assets/Images/Cart/rxicon.png";
import {
  MinusOutlined,
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Divider } from "antd";

const MedicineList = (props) => {
  const {
    patientMedicineOrder,
    deleteMedicine,
    quantityUpdate, // not used in this snippet
    readOnly = false,
    debouncedQuantityUpdate,
  } = props;

  // Local state to track loading for individual medicine items.
  const [loadingItems, setLoadingItems] = useState({});

  if (!patientMedicineOrder.length) {
    return null;
  }

  const calculateMRP = (res) => {
    if (res?.totalAmount) {
      return res.totalAmount.toFixed(2);
    } else {
      return parseFloat(res?.unitPrice).toFixed(2);
    }
  };

  const calculateDiscountAmount = (res) => {
    return parseFloat(res?.unitPrice * res?.quantity).toFixed(2);
  };

  const calculateDiscountPrecentage = (data) => {
    let totalAmount = parseFloat(data?.unitPrice * data?.quantity).toFixed(2);
    let discountAmount = data?.totalAmount.toFixed(2);
    let precentage = Math.round(100 - (discountAmount / totalAmount) * 100);
    return precentage;
  };

  // Handle quantity changes with an individual loading state.
  const handleQuantityChange = (e, res, newQuantity, i) => {
    // Set loader for this medicine item.
    setLoadingItems((prev) => ({ ...prev, [res.id]: true }));
    
    // Call the debounced update function.
    const result = debouncedQuantityUpdate(e, res, newQuantity, i);
    
    // If the function returns a promise, use it; otherwise simulate a delay.
    if (result && typeof result.finally === "function") {
      result.finally(() => {
        setLoadingItems((prev) => ({ ...prev, [res.id]: false }));
      });
    } else {
      // Fallback delay (adjust as needed)
      setTimeout(() => {
        setLoadingItems((prev) => ({ ...prev, [res.id]: false }));
      }, 500);
    }
  };

  // Handle deletion with an individual loading state.
  const handleDeleteMedicine = (e, res) => {
    setLoadingItems((prev) => ({ ...prev, [res.id]: true }));
    const result = deleteMedicine(e, res, { medicineId: res.id });
    if (result && typeof result.finally === "function") {
      result.finally(() => {
        setLoadingItems((prev) => ({ ...prev, [res.id]: false }));
      });
    } else {
      setTimeout(() => {
        setLoadingItems((prev) => ({ ...prev, [res.id]: false }));
      }, 500);
    }
  };

  // Component for the quantity changer.
  const MedicineQuantityChanger = ({ res, i }) => {
    const isLoading = loadingItems[res.id];
    return (
      <div className="flex items-center justify-center w-[90px] md:w-[100px] h-[30px]">
        <button
          onClick={(e) => {
            if (res.quantity > 1) {
              handleQuantityChange(e, res, res.quantity - 1, i);
            } else {
              handleDeleteMedicine(e, res);
            }
          }}
          disabled={!!isLoading}
          className="w-[35%] h-full text-sm font-light border-l border-t border-b border-l-primaryColor border-t-primaryColor border-b-primaryColor md:border md:border-primaryColor text-primaryColor rounded-s-md"
        >
          <MinusOutlined />
        </button>
        <div className="w-[50%] flex justify-center items-center h-full border-x-0 border-y border-primaryColor text-base text-primaryColor font-medium">
          {isLoading ? <LoadingOutlined spin /> : res.quantity.toString().padStart(2, "0")}
        </div>
        <button
          onClick={(e) => {
            handleQuantityChange(e, res, res.quantity + 1, i);
          }}
          disabled={!!isLoading}
          className="w-[35%] h-full text-sm font-light border-r border-t border-b border-r-primaryColor border-t-primaryColor border-b-primaryColor md:border md:border-primaryColor rounded-e-md"
        >
          <PlusOutlined />
        </button>
      </div>
    );
  };

  const DeleteMedicine = ({ res }) => {
    return (
      <>
        {!readOnly ? (
          <div className="flex mt-1 justify-end lg:justify-center flex-col items-center">
            <DeleteOutlined
              onClick={(e) => {
                handleDeleteMedicine(e, res);
              }}
              className="text-danger text-lg cursor-pointer"
            />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="bg-White mt-5">
      {patientMedicineOrder[0].patientMedicineOrderDetailsList.map((res, i) =>
        res.status === 1 ? (
          <React.Fragment key={res.id}>
            <div className="md:bg-lightBlue items-center flex mb-3 last:mb-0 rounded-md md:p-4 lg:w-full">
              <div className="flex gap-3 w-full">
                <div className="h-20 w-20">
                  <img src={pills} alt="tablet" className="w-full h-full object-fit" />
                </div>
                <div className="w-full">
                  <div className="flex justify-between items-baseline">
                    <div>
                      <div className="flex gap-1 items-center">
                        {res.prescriptionRequired === "Y" ? (
                          <img src={RXicon} className="h-4" alt="Rx icon" />
                        ) : null}
                        <p className="text-sm md:text-md text-primaryColor font-medium">
                          {res.drugName}
                        </p>
                      </div>
                      <div className="text-xs text-textGray capitalize mt-1 md:mt-0">
                        {res?.medicineTypeOfSell}
                      </div>
                    </div>
                    <div className="block md:hidden">
                      <MedicineQuantityChanger res={res} i={i} />
                    </div>
                    <div className="hidden md:block">
                      <DeleteMedicine res={res} />
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <div className="flex items-baseline md:mt-3">
                      <p className="text-secondaryColor md:text-primaryColor mr-1 md:mr-0 font-semibold text-base">
                        ₹ {calculateMRP(res)}
                      </p>
                      {res?.discountAmount ? (
                        <>
                          <p className="text-base line-through text-textGray md:pl-2">
                            ₹ {calculateDiscountAmount(res)}
                          </p>
                          <p className="hidden md:block text-xs font-medium ml-1" style={{ color: "#DD1A1A" }}>
                            ({calculateDiscountPrecentage(res)}% off)
                          </p>
                          <p
                            className="block md:hidden text-xs font-medium ml-1 p-1 px-2"
                            style={{ color: "#373737", backgroundColor: "#F9BBC4", borderRadius: 4 }}
                          >
                            {calculateDiscountPrecentage(res)}% OFF
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="hidden md:block">
                      <MedicineQuantityChanger res={res} i={i} />
                    </div>
                    <div className="block md:hidden">
                      <DeleteMedicine res={res} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider
              className="md:hidden"
              variant="dashed"
              style={{ borderColor: "#C4C4C4" }}
              dashed
            />
          </React.Fragment>
        ) : null
      )}
    </div>
  );
};

export default MedicineList;
