import Search from "antd/es/input/Search";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import LocateIcon from "../../../Assets/Images/locate.svg";
import {
  getPatientDetails,
  patientaddresslists,
} from "../../../Redux/Action/UserprofileActions";
import hospitalservice from "../../../Redux/Services/hospitalservice";
import doctorService from "../../../Redux/Services/doctorService";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import axios from "axios";
import http from "../../../Redux/Services/http-common";
import { APP_ROUTES } from "../../PageRouting/AppRoutes";
import MicIcon from "@mui/icons-material/Mic";

import {
  setAddressString,
  setLatLong,
  actioncustomPinCode,
  //actioncustomRadius
} from "../../../Redux/Action/userActions";
import { debounce } from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { AudioOutlined } from "@ant-design/icons";
import "../../../Assets/Css/slide_vertical.css";
import { AutoComplete, Input, Modal, Drawer } from "antd";
import LocationPicker from "./LocationPicker";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import "./index.css";
import SearchBarMic from "../SearchBarMic/SearchBarMic";
// import locationSetter from "../../utils/locationSetter";

const words = [
  "Medicines or Healthcare Products",
  "Lab Tests",
  "Surgery",
  "Doctors or symtoms or speciality",
  "Hospitals",
];

const isMobile = window.innerWidth < 600 ? true : false;

function SearchBar({ visible = true, searchedText = () => {} }) {
  const history = useHistory();
  const prevScrollY = useRef(0);
  const inputSearchRef = useRef(null);
  const menuRef = useRef();
  const locationDropdownRef = useRef(null);
  const [wordIndex, setWordIndex] = useState(0);
  const location = useLocation();
  const pincodelocation = useSelector(
    (state) => state.authReducer.pincodelocation
  );
  const address = useSelector((state) => state.authReducer.address);
  const userData = useSelector((state) => state.authReducer.patientData);
  const dispatch = useDispatch();
  const [screen, setscreen] = useState(window.innerWidth);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const patientinfo = useSelector((state) => state.patientinfo);
  const { patientinfoData, isLoading, isSuccess } = patientinfo;
  const addressinfo = useSelector((state) => state.patientaddresslist);
  const { patientaddressinfoData } = addressinfo;
  const commonHealtList = useSelector((state) => state.doctspecialitylist);
  const { doctspecialityData } = commonHealtList;
  const [searchData, setSearchData] = useState();
  const { radius, customPinCode, coords } = useSelector(
    (state) => state.authReducer
  );
  const [newPinCode, setPinCode] = useState("");
  const [banner, setBanner] = useState("");
  const [searchedLabtest, setSearchedLabtest] = useState([]);
  const [pincoder, setPincode] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState({
    isMMILoading: false,
    locationError: "",
  });
  const [placeholderText, setPlaceholderText] = useState("Search for ");
  const [isRecordingModal, setIsRecordingModal] = useState(false);
  const [isRecordingDrawer, setIsRecordingDrawer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(location, "dsviodsbvousdbvuosdvds");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setBanner(location.pathname);
  }, [location]);

  React.useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () =>
      window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Clean up the interval
  }, []);

  useEffect(() => {
    if (location.pathname === APP_ROUTES.HOSPITALSERVICE) {
      setPlaceholderText(`Search for ${words[4]}`);
    } else if (location.pathname === APP_ROUTES.DIAGNOSTICDETAILS) {
      setPlaceholderText(`Search for ${words[1]}`);
    } else if (location.pathname === APP_ROUTES.MEDICINE) {
      setPlaceholderText(`Search for ${words[0]}`);
    } else if (location.pathname === APP_ROUTES.BOOKANAPPOINTMENT) {
      setPlaceholderText(`Search for ${words[3]}`);
    } else {
      setPlaceholderText(`Search for ${words[wordIndex]}`);
    }
  }, [wordIndex, location.pathname]);

  const DEBOUNCE_DELAY = 300;
  const debouncedSearch = useRef(
    debounce(async (coords, searchTerm, radius) => {
      if (searchTerm) {
        // Initialize separate arrays for different types of data
        let api_list_doctor = [];
        let api_list_hospital = [];
        let api_list_lab = [];
        let api_list_medicine = [];
        let api_list_surgery = [];

        // API call for doctors (if applicable)
        if (
          location.pathname !== APP_ROUTES.HOSPITALSERVICE &&
          location.pathname !== APP_ROUTES.DIAGNOSTICDETAILS &&
          location.pathname !== APP_ROUTES.MEDICINE
        ) {
          const resDoctor = await doctorService.esgetDoctorslist(
            searchTerm,
            1,
            40
          );
          resDoctor.data?.forEach((search) => {
            api_list_doctor.push({
              code: search.userId,
              text: search.userName,
              symptoms: search.symptoms,
              specialities: search.specialities,
            });
          });
        }

        // API call for hospitals (if applicable)
        if (
          location.pathname !== APP_ROUTES.BOOKANAPPOINTMENT &&
          location.pathname !== APP_ROUTES.DIAGNOSTICDETAILS &&
          location.pathname !== APP_ROUTES.MEDICINE
        ) {
          const resHospital = await hospitalservice.esgetHopitalslist(
            coords,
            searchTerm,
            1,
            40,
            radius
          );
          resHospital.data?.forEach((search) => {
            api_list_hospital.push({
              code: search.code,
              text: search.hospitalName,
            });
          });
        }

        // API call for lab tests (if applicable)
        if (
          location.pathname !== APP_ROUTES.BOOKANAPPOINTMENT &&
          location.pathname !== APP_ROUTES.HOSPITALSERVICE &&
          location.pathname !== APP_ROUTES.MEDICINE
        ) {
          const circle = JSON.parse(secureStorage.getItem("circle"));
          const resLab = await axios.get(
            `${process.env.REACT_APP_ELASTIC_BASEURL}labtest?freeTextSearch=${searchTerm}&source=b2c&labCircle=${circle}`
          );

          resLab.data?.forEach((search) => {
            api_list_lab.push({
              code: search.labTestCode,
              text: search.testDisplayName,
              otherNames: search.testKnownAs,
            });
          });
        }

       
        if (
          location.pathname !== APP_ROUTES.BOOKANAPPOINTMENT &&
          location.pathname !== APP_ROUTES.HOSPITALSERVICE &&
          location.pathname !== APP_ROUTES.DIAGNOSTICDETAILS
        ) {
          let encodeSearchTerm = encodeURIComponent(searchTerm);
          const resMedicine = await axios.get(
            `${process.env.REACT_APP_ELASTIC_BASEURL}medicine-name?freeTextSearch=${encodeSearchTerm}`
          );
          resMedicine.data?.forEach((search) => {
            api_list_medicine.push({
              code: search.id,
              text: search.medicineName,
              symptoms: "",
              type: "medicine",
            });
          });
        }

        // (Optional) API call for surgeries is commented out below:
        // const resSurgery = await axios.get(`${process.env.REACT_APP_ELASTIC_BASEURL}surgeries?freeTextSearch=${searchTerm}&pageNo=1&pageSize=200`);
        // resSurgery.data?.forEach((search, index) => {
        //   api_list_surgery.push({
        //     code: search.surgeryId,
        //     text: search.surgeryName,
        //     seoName: search.seoNameId,
        //     index: index,
        //   });
        // });

        // Concatenate all lists
        let api_list = [
          ...api_list_doctor,
          ...api_list_hospital,
          ...api_list_lab,
          ...api_list_medicine,
          ...api_list_surgery,
        ];

        // Set the concatenated list as the search data and map it for AutoComplete.
        setSearchData(api_list);
        let result = api_list.map((res) => {
          return {
            value: res.text,
            label: (
              <>
                <div className="font-medium text-sm mt-2 ml-2 flex">
                  <div className="w-8">
                    <SearchIcon
                      className="h-6 p-1 rounded-full mr-2 w-6"
                      color={"#AEAEAE"}
                    />
                  </div>
                  {res.specialities ? (
                    <div className="flex flex-col gap-2 justify-between">
                      <div className="text-secondaryColor">
                        {res.text + " "}
                      </div>
                      <div className="text-secondaryColor text-xs font-normal">
                        {res.specialities + " "}
                      </div>
                    </div>
                  ) : (
                    <div className="text-secondaryColor">
                      {res.text + " "}
                    </div>
                  )}
                </div>
                {res.otherNames && (
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "40px",
                    }}
                  >
                    <div className="text-xs font-medium">
                      <span className="text-xs font-bold text-gray-800">
                        Also know as:{" "}
                      </span>
                      {" " + res.otherNames}
                    </div>
                  </div>
                )}
              </>
            ),
            // Include the type so we know if this is a medicine suggestion.
            type: res.type ? res.type : "",
          };
        });
        setSearchResults(result);
      }
    }, DEBOUNCE_DELAY)
  ).current;

  // Called when the user types in the search box.
  const onSearchText = (text) => {
    setSearchValue(text);
    setSearchData([]);
    debouncedSearch(coords, text, radius);
  };

  const redirectTo = (e = "", text, option) => {
    if (text.length === 0) return;
  
    // If the selected suggestion is a medicine, use the old API and then redirect.
    if (option && option.type === "medicine") {
      let encodeSearchTerm = encodeURIComponent(text);
      axios
        .get(
          `${process.env.REACT_APP_ELASTIC_BASEURL}medicine?freeTextSearch=${encodeSearchTerm}&pageNo=1&pageSize=20`
        )
        .then((res) => {
          // Process the response if needed.
          searchedText(text);
          // Maintain the original behavior:
          // If we are already on the medicine page, simply call searchedText;
          // otherwise, redirect to the medicine page (adjust URL as required).
          if (location.pathname === APP_ROUTES.MEDICINE) {
            return;
          } else {
            history.push(`/all/${text}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching old API for medicine", error);
        });
      return;
    }
  
    // For non-medicine suggestions, preserve the original redirection logic.
    if (location.pathname === APP_ROUTES.BOOKANAPPOINTMENT) {
      searchedText(text);
      return;
    }
  
    if (location.pathname === APP_ROUTES.DIAGNOSTICDETAILS) {
      searchedText(text);
      return;
    }
  
    if (location.pathname === APP_ROUTES.MEDICINE) {
      searchedText(text);
      return;
    }
  
    if (text) {
      history.push(`/all/${text}`);
    }
  };
  
  

  var customClass =
    "absolute w-full md:w-7/12 lg:w-7/12 -top-1 md:top-0 lg:top-15 flex flex-wrap justify-center mt-2 md:p-3 lg:p-6 bg-brand-secondary";
  var isIOS = (function () {
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return isIOS;
  })();

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && !scroll) {
        setScroll(true);
      }
      if (currentScrollY < 5) {
        setScroll(false);
      }
      prevScrollY.current = currentScrollY;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [location]);

  const handleNotFound = (text) => {
    console.log(text, "sdvdshovusdguogdsvvds");
  };

  const handleMicClick = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const suffix = (
    <AudioOutlined
      onClick={handleMicClick}
      style={{
        fontSize: 16,
        color: "#134171",
        padding: 18,
        paddingRight: 4,
      }}
    />
  );

  const getaudioUrl = async (audioData) => {
    const formData = new FormData();
    formData.append("audio_file", audioData);

    try {
      const response = await fetch("https://revapi.reverieinc.com/", {
        method: "POST",
        headers: {
          src_lang: "en", // Replace with the source language code
          domain: "generic",
          "REV-API-KEY": "d430158b6a7dd8f0756a38a6e653ff3380027092", // Replace with your API key
          "REV-APPNAME": "stt_file",
          "REV-APP-ID": "com.curebay",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`STT API call failed: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("STT API Response:", data);

      if (data?.text) {
        setSearchValue(data.text);
        onSearchText(data.text);
        inputSearchRef.current.focus();
      } else {
        alert("No text recognized from the audio.");
      }
    } catch (error) {
      console.error("Error during STT API call:", error);
      alert("Failed to process the audio for speech-to-text.");
    }
  };

  const getRecordingStatus = (status) => {
    if (status === "start") {
      if (isMobile) {
        setIsRecordingDrawer(true);
      } else {
        setIsRecordingModal(true);
      }
    } else if (status === "stop") {
      setIsModalOpen(false);
      if (isMobile) {
        setIsRecordingDrawer(false);
      } else {
        setIsRecordingModal(false);
      }
    }
  };

  console.log(
    inputSearchRef?.current?.input?.value,
    "vsdguigsdugsdiucds"
  );

  return (
    <div className="w-full h-[75px] bg-[#bfe4ff] py-3 sticky top-[80px] z-[999] header-search-bar">
      <div
        className={` ${visible} flex justify-center sticky top-16 lg:top-10 z-10 ${
          location.pathname === "/" ? "pb-0 sm:pb-0" : "pb-20 sm:pb-0"
        }`}
      >
        <LocationPicker />
        <AutoComplete
          className="w-full sm:w-2/5 mr-2 sm:mx-0"
          style={{ borderRadius: 18 }}
          options={searchResults}
          // Pass both the value and option to onSelect so we can check its type.
          onSelect={(value, option) => redirectTo("", value, option)}
          onSearch={onSearchText}
          size="large"
          value={searchValue}
        >
          <Input.Search
            onClear={() => {
              setSearchResults([]);
              searchedText(" ");
            }}
            onPressEnter={(e) => redirectTo("", e.target.value)}
            onSearch={(value) => redirectTo("", value)}
            className="animated-placeholder"
            style={{ width: "100%" }}
            styles={{
              affixWrapper: {
                borderRadius: 0,
                height: 44,
                backgroundColor: "white",
              },
            }}
            ref={inputSearchRef}
            value={searchValue}
            size="large"
            placeholder={placeholderText}
            variant="borderless"
            enterButton
            allowClear
            // suffix={suffix}
          />
        </AutoComplete>
      </div>
      {/* <SearchBarMic startRecording={isModalOpen} recordingStatus={getRecordingStatus} getaudioUrl={getaudioUrl} /> */}
      <Modal
        style={{ width: "50%" }}
        centered={true}
        footer={null}
        open={isRecordingModal}
        onCancel={handleCancel}
      >
        <div className="mic-container">
          <div className="oscillation">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <MicIcon style={{ fontSize: "30px", color: "white", zIndex: 1 }} />
        </div>
      </Modal>
      <Drawer
        title=""
        placement="bottom"
        closable={true}
        mask={true}
        onClose={handleCancel}
        open={isRecordingDrawer}
        key="bottom"
      >
        <div className="mic-container">
          <div className="oscillation">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <MicIcon style={{ fontSize: "30px", color: "white", zIndex: 1 }} />
        </div>
      </Drawer>
    </div>
  );
}

export default SearchBar;
