// import React, { useState, useRef, useEffect } from "react";
// // import arrowicon from "../../Assets/Images/arrowicon.png";
// // import attach from "../../Assets/Images/attach.png";
// import { InputText } from "primereact/inputtext";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";
// import { Menu, Dropdown, Button, Typography } from "antd";
// import { secureStorage } from "../../../Redux/Reducer/authReducer";
// import ChatLoading from "../../Shared/chatLoading";
// import TranslateIcon from "@mui/icons-material/Translate";
// import AttachFileIcon from "../../../Assets/Images/AttachFile.svg";
// import SendIcon from "@mui/icons-material/Send";
// import { Card } from "primereact/card";
// import { useSelector } from "react-redux";
// import { Modal, Drawer, message } from "antd";
// import VolumeUpIcon from "@mui/icons-material/VolumeUp";
// import PauseIcon from "@mui/icons-material/Pause";


// import moment from "moment";
// import {
//   MenuOutlined,
//   UploadOutlined,
//   SearchOutlined,
// } from "@ant-design/icons";
// import MicRecorder from "./MicRecorder";
// import http from "../../../Redux/Services/http-common";
// import Layouts from "../../Layouts";
// import Header from "../../Shared/Header";
// import { useHistory, useLocation } from "react-router-dom";
// import { APP_ROUTES } from "../../../Components/PageRouting/AppRoutes";
// import CallToActionComponent from "./CallToActionComponent";
// import Caresathi from "../../../Assets/Images/caresathi_logo_ai.svg";
// import Infocircle from "../../../Assets/Images/infocircle_ai.svg";
// import Send from "../../../Assets/Images/send_icon.svg";

// import { Segmented } from "antd";
// import AIConcierge from "./AiConcierge";
// const { Title, Text } = Typography;
// const CareSathi = () => {
//   const [messageApi, contextHolder] = message.useMessage();
//   const [getAnswerBot, setgetAnswerBot] = useState([]);
//   const [sampleMessage, setSampleMessage] = useState("");
//   const [question, setQuestion] = useState("");
//   let s_token = secureStorage.getItem("token");
//   const [formDataState, setFormDataState] = useState(null);
//   const [qaHistory, setQaHistory] = useState([]);
//   const [flagselectedagent, setFlagselectedagent] = useState(false);

//   const [serviceRecommendationObject, setServiceRecommendationObject] =
//     useState({
//       ["-1"]: [
//         {
//           link: "",
//           reasoning: "",
//           service: "",
//         },
//       ],
//     });
//   const fileInputRef = useRef(null);
//   const [extractedData, setExtractedData] = useState("");
//   const [uploadedFileName, setUploadedFileName] = useState("");
//   const [loading, setLoading] = useState(false);
//   const chatContainerRef = useRef(null);
//   const activePatient = JSON.parse(localStorage.getItem("activePatient"));
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   var circleCode = localStorage.getItem("userId");
//   const [base64pdf, setBase64pdf] = useState("");
//   const [snackbarmsg, setSnackbarmsg] = useState({ msg: "", type: "" });
//   const userData = useSelector((state) => state.authReducer.patientData);
//   const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
//   const [documanttype, setDocumanttype] = useState("");
//   const [chatHistory, setChatHistory] = useState({});
//   const [chatHistoryDrawer, setChatHistoryDrawer] = useState(false);
//   const [screenHeight, setScreenHeight] = useState(window.innerHeight);
//   const [timeStamp, setTimestamp] = useState("");
//   const [textAnimationFinish, setTextAnimationFinish] = useState(false);
//   const [answerCount, setAnswerCount] = useState(-1);
//   const [languageModalVisible, setLanguageModalVisible] = useState(true);
//   const [serviceRecommendation, setServiceRecommendation] = useState([]);
//   const [isSpeaking, setIsSpeaking] = useState(false);
//   const [audioInstances, setAudioInstances] = useState({});
//   const [playingAudioId, setPlayingAudioId] = useState(null);
//   const [openLabReportAnalyserModal, setopenLabReportAnalyserModal] =
//     useState(false);
//   const history = useHistory();
//   const location = useLocation(); // Get the location object from the router
//   const queryParams = new URLSearchParams(location.search); // Create a URLSearchParams object with the query string
//   const [selectedLanguage, setSelectedLanguage] = React.useState("en");
//   const [alignValue, setAlignValue] = React.useState("center");
//   // Extracting individual query parameters
//   const lab_report_analyser = queryParams.get("lab_report_analyser");
//   const lab_report_analyser_popup = queryParams.get("popup");
//   const reportUrl = queryParams.get("reportUrl");
//   const reportExtType = queryParams.get("reportExtType");
//   const orderId = queryParams.get("orderId");
//   const [translatedQuestion, setTranslatedQuestion] = useState({});
//   const [translatedAnswer, setTranslatedAnswer] = useState({});

//   const [convertedText, setConvertedText] = useState({
//     index: -1,
//     type: "",
//     text: "",
//   });

//   const scrollToBottom = () => {
//     if (chatContainerRef.current) {
//       const container = chatContainerRef.current;

//       // Check if the content is scrollable
//       console.log(container, "ContainerDimensions")
//       if (container.scrollHeight > container.clientHeight) {
//         container.scroll({
//           top: container.scrollHeight,
//           behavior: 'smooth',
//         });
//       }
//     }
//   };

//   useEffect(() => {
//     // Initially hide the iframe with id="launcher-frame"
//     const iframe = document.getElementById("freshworks-container");
//     if (iframe) {
//       iframe.style.display = "none";
//     }
//   }, [])



//   useEffect(() => {
//     const handleResize = () => setScreenHeight(window.innerHeight);
//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   useEffect(() => {
//     if (reportUrl && reportExtType) {
//       fetchLabReportAsBase64(reportUrl, reportExtType);
//     }
//   }, [reportUrl]);

//   // useEffect(() =>{
//   //     if(sampleMessage && uploadedFileName){
//   //     handleArrowClick();
//   //     }
//   // },[sampleMessage, uploadedFileName])

//   const fetchLabReportAsBase64 = async (url, type) => {
//     try {
//       const response = await fetch(url);
//       const blob = await response.blob();

//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const base64data = reader.result;
//         let converttype =
//           type.toLowerCase() === "application/pdf"
//             ? "pdf"
//             : type.toLowerCase() === "image/jpg"
//               ? "jpg"
//               : type.toLowerCase() === "image/jpeg"
//                 ? "jpeg"
//                 : type.toLowerCase() === "image/png"
//                   ? "png"
//                   : type;
//         setDocumanttype(converttype);
//         const base64Content = base64data.split(",")[1];
//         setBase64pdf(base64Content);
//         setUploadedFileName(reportUrl);
//         // setSampleMessage("Analyse this Report");
//         // Perform any further actions with the base64 data here
//       };

//       if (type === "application/pdf") {
//         reader.readAsDataURL(blob); // Read PDF as Data URL
//       } else if (type.startsWith("image/")) {
//         reader.readAsDataURL(blob); // Read Image as Data URL
//       }
//     } catch (error) {
//       console.error("Error fetching or converting lab report:", error);
//     }
//   };

//   useEffect(() => {
//     if (lab_report_analyser_popup == "true") {
//       setopenLabReportAnalyserModal(true);
//     } else {
//       setopenLabReportAnalyserModal(false);
//     }
//   }, [lab_report_analyser_popup]);

//   useEffect(() => {
//     if (textAnimationFinish) {
//       setServiceRecommendationObject((prevState) => ({
//         ...prevState,
//         [answerCount]: serviceRecommendation,
//       }));
//     }
//   }, [textAnimationFinish]);

//   useEffect(() => {
//     if (qaHistory.length) {
//       scrollToBottom();  // Scroll to bottom when chat history updates
//     }
//   }, [qaHistory]);

//   useEffect(() => {
//     if (qaHistory.length) {
//       scrollToBottom(); // Scrolls to the bottom when chat history is updated
//     }
//   }, [qaHistory]);

//   useEffect(() => {
//     if (loading === false && !showHeader) {
//       scrollToBottom(); // Scrolls when loading is done or after a new message is added
//     }
//   }, [loading]);

//   useEffect(() => {
//     setshowaddmemberpopup(true);
//   }, []);

//   useEffect(() => {
//     genrateRandomTimestamp();
//   }, []);

//   const genrateRandomTimestamp = () => {
//     const currentDateTime = new Date();
//     const unixTimestamp = Math.floor(currentDateTime.getTime() / 1000);

//     setTimestamp(unixTimestamp);
//   };

//   useEffect(() => {
//     if (userData.code) {
//       getChatHistory();
//     }
//   }, [userData.code]);

//   const getChatHistory = () => {
//     let payload = {
//       // identifier: "user13",
//       identifier: userData.code,
//       page: 1,
//       page_size: 10,
//     };

//     fetch(`${process.env.REACT_APP_CARE_SATHI_CHATHISTORY}qa`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${s_token}`,
//       },
//       body: JSON.stringify(payload),
//     }).then(async (res) => {
//       let result = await res.json();
//       if (result.data.length) {
//         // const groupedHistory = result?.data.reduce((acc, session) => {
//         //   const date = new Date(
//         //     session.qa_pairs[session.qa_pairs.length - 1].created_date
//         //   ).toLocaleDateString();
//         //   if (!acc[date]) {
//         //     acc[date] = [];
//         //   }
//         //   acc[date].push({
//         //     session_id: session.session_id,
//         //     question: session.qa_pairs[session.qa_pairs.length - 1].question,
//         //     qa_pairs: session.qa_pairs,
//         //   });
//         //   return acc;
//         // }, {})
//         let filterDataWithSessionId = {};
//         result.data.forEach((res) => {
//           const date = new Date(
//             res.qa_pairs[res.qa_pairs.length - 1].created_date
//           ).toLocaleDateString();
//           if (/^\d+$/.test(res.session_id)) {
//             if (!filterDataWithSessionId[date]) {
//               filterDataWithSessionId[date] = [res];
//             } else {
//               filterDataWithSessionId[date].push(res);
//             }
//           }
//         });
//         setChatHistory(filterDataWithSessionId);
//         // let ee = Object.keys(filterDataWithSessionId)
//         // console.log(filterDataWithSessionId[ee[0]],filterDataWithSessionId, "sdvihsdouvghsdudsvsdv")
//       }
//     });
//   };

//   const typeEffect = (text, callback) => {
//     let index = 0;
//     const delay = 10; // Adjust the typing speed here (milliseconds per character)

//     const typeCharacter = () => {
//       if (index < text.length) {
//         callback(text.slice(0, index + 1));
//         index++;
//         setTimeout(typeCharacter, delay);
//       } else {
//         callback(text); // Ensure the full text is set at the end
//         setTextAnimationFinish(true);
//       }
//     };

//     typeCharacter();
//   };

//   const getAnswer = async (reData) => {
//     try {
//       const src_language = detectMajorLanguage(reData.slice(0, 40));


//       setQaHistory((prevHistory) => [
//         ...prevHistory,
//         {
//           question: reData,
//           answer: "",
//           isLoading: true,
//           questionLanguage: src_language,
//           serviceRecommendation: null,
//         },
//       ]);

//       setLoading(true);


//       let url = `${process.env.REACT_APP_CARE_SATHI}all_in_one/get_answer_v2`;

//       const response = await fetch(url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${s_token}`,
//         },
//         body: JSON.stringify({
//           user_prompt: reData,
//           classify: 1,
//           session_id: timeStamp.toString(),
//           identifier: userData.code,
//           src_lang: src_language,

//         }),
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       setServiceRecommendation(null);
//       const data = await response.json();
//       if (data.status == 200) {
//         let answer = data.data.answer;
//         let count = answerCount + 1;
//         console.log(data.data, "vgdcbdfh")
//         const detectedLanguage = detectMajorLanguage(answer);
//         const recommendation = data.data.ServiceRecommendation || null;

//         setAnswerCount(count);
//         const formattedAnswer = formatAnswer(answer);


//         setQaHistory((prevHistory) =>
//           prevHistory.map((item, index) =>
//             index === prevHistory.length - 1
//               ? {
//                 ...item,
//                 answer: formattedAnswer,
//                 answerLanguage: detectedLanguage,
//                 serviceRecommendation: recommendation,
//                 isLoading: false,
//               }
//               : item
//           )
//         );

//         setTextAnimationFinish(false);
//         typeEffect(formattedAnswer, (typedText) => {
//           updateAnswer(typedText);
//         });

//         setgetAnswerBot((prevAnswers) => [
//           ...prevAnswers,
//           { response: answer },
//         ]);

//         saveMessageToApi({
//           question: reData,
//           answer: formattedAnswer,
//           createdBy: userData ? userData.code : "",
//           chatSession: timeStamp,
//           sourceChannel: "B2C",
//         });
//       }
//     } catch (error) {
//       console.log(error);
//       setSnackbarmsg({
//         msg: "It seems there was an issue. Would you mind trying again?",
//         type: "error",
//       });
//       setOpenSnackbar(true);
//     } finally {
//       setLoading(false);
//     }
//   };



//   const handleInputChange = (e) => {
//     setSampleMessage(e.target.value);
//   };

//   const getQuestionFromSpeechToText = (text) => {
//     setSampleMessage(text);
//   };
//   const [showHeader, setShowHeader] = useState(true);
//   const handleArrowClick = async (e) => {
//     setShowHeader(false);
//     if (loading) {
//       setSnackbarmsg({
//         msg: "Please wait till the previous response is loading.",
//         type: "warning",
//       });
//       setOpenSnackbar(true);
//       return;
//     }
//     if (!sampleMessage) {
//       setOpenSnackbar(true);
//       setSnackbarmsg({ msg: "Please write a sampleMessage", type: "warning" });
//       return;
//     }
//     if (base64pdf) {
//       try {
//         const src_language = detectMajorLanguage(sampleMessage.slice(0, 40)); // Detect language for the question

//         // Set qaHistory with the detected question language
//         setQaHistory((prevHistory) => [
//           ...prevHistory,
//           {
//             question: `File: ${uploadedFileName} Question: ${sampleMessage}`,
//             answer: "",
//             isLoading: true,
//             questionLanguage: src_language,
//             serviceRecommendation: null,
//           },
//         ]);
//         setLoading(true);

//         const requestBody = {
//           user_prompt: sampleMessage,
//           classify: 1,
//           document: base64pdf,
//           document_type: documanttype,
//           identifier: userData.code,
//           session_id: timeStamp.toString(),
//           src_lang: src_language, // Pass the detected language as source language
//         };

//         let url = `${process.env.REACT_APP_CARE_SATHI}all_in_one/get_answer_v2`;
//         const response = await fetch(url, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${s_token}`,
//           },
//           body: JSON.stringify(requestBody),
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         } else {
//           const data = await response.json();
//           if (data.status == 200) {
//             let answer = data.data.answer;
//             let count = answerCount + 1;
//             const detectedLanguage = detectMajorLanguage(answer); // Detect the language for the answer
//             const recommendation = data.data.ServiceRecommendation || null;
//             setAnswerCount(count);
//             const formattedAnswer = formatAnswer(answer);

//             // Update qaHistory with the detected language for both question and answer
//             setQaHistory((prevHistory) =>
//               prevHistory.map((item, index) =>
//                 index === prevHistory.length - 1
//                   ? {
//                     ...item,
//                     answer: formattedAnswer,
//                     answerLanguage: detectedLanguage,
//                     serviceRecommendation: recommendation,
//                     isLoading: false,
//                   }
//                   : item
//               )
//             );

//             setTextAnimationFinish(false);
//             typeEffect(formattedAnswer, (typedText) => {
//               updateAnswer(typedText);
//             });

//             setgetAnswerBot((prevAnswers) => [
//               ...prevAnswers,
//               { response: answer },
//             ]);

//             const removeEmojis = formattedAnswer.replace(
//               /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{FE00}-\u{FE0F}]|[\u{1F910}-\u{1F9FF}]|[\u{1F1E6}-\u{1F1FF}]/gu,
//               ""
//             );
//             saveMessageToApi({
//               question: sampleMessage,
//               answer: removeEmojis,
//               createdBy: userData ? userData.code : "",
//               chatSession: timeStamp,
//               sourceChannel: "B2C",
//             });

//             // Ensure the chat scrolls to the bottom when a new answer is received
//             scrollToBottom();
//           }
//         }
//       } catch (error) {
//         console.error("Failed to fetch data:", error);
//       } finally {
//         setLoading(false);
//         setUploadedFileName("");
//         setFormDataState(null);
//         setSampleMessage(""); // Clear the sampleMessage state after uploading the file.
//         fileInputRef.current.value = ""; // Clear the file input
//       }
//     } else {
//       setQuestion(sampleMessage);
//       await getAnswer(sampleMessage); // Use the same getAnswer function for non-PDF cases
//       setSampleMessage("");
//       scrollToBottom(); // Ensure chat scrolls to the bottom when a new question is typed
//     }
//   };



//   const saveMessageToApi = async (data) => {
//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_NEWBASEURL}ChatBotQa/`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${s_token}`,
//           },
//           body: JSON.stringify(data),
//         }
//       );

//       if (!response.ok) {
//         throw Error(`HTTP error! status: ${response.status}`);
//       } else {
//         const responseData = await response.json();
//         getChatHistory();
//       }
//     } catch (error) {
//       console.error("Failed to save sampleMessage:", error);
//     }
//   };
//   const updateAnswer = (typedText) => {
//     setQaHistory((prevHistory) =>
//       prevHistory.map((item, index) =>
//         index === prevHistory.length - 1 ? { ...item, answer: typedText } : item
//       )
//     );
//   };
//   const finalizeAnswer = (formattedAnswer, detectedLanguage) => {
//     setQaHistory((prevHistory) =>
//       prevHistory.map((item, index) =>
//         index === prevHistory.length - 1
//           ? {
//             ...item,
//             answer: formattedAnswer,
//             answerLanguage: detectedLanguage,
//             isLoading: false,
//           }
//           : item
//       )
//     );
//   };

//   // const handleFileChange = async (event) => {
//   //   if (event.target.files.length > 0) {
//   //     const selectedFile = event.target.files[0];

//   //     if (selectedFile.type == "application/pdf") {
//   //       // console.error("File is not a PDF.");
//   //       // setOpenSnackbar(true);
//   //       // setSnackbarmsg("Please upload pdf file.");
//   //       // return;

//   //     console.log(selectedFile, "selectedFile");
//   //     const base64 = await convertToBase64(selectedFile);
//   //     const base64Content = base64.split(",")[1];
//   //     setBase64pdf(base64Content);
//   //     console.log(base64Content, "base64 PDF");
//   //     setUploadedFileName(selectedFile.name);
//   //     const formData = new FormData();
//   //     formData.append("pdf_file", selectedFile, selectedFile.name);
//   //     }
//   //     else{

//   //     }
//   //   }
//   // };
//   const stripHtmlTags = (htmlString) => {
//     const tempElement = document.createElement("div");
//     tempElement.innerHTML = htmlString;

//     const plainText = tempElement.textContent || tempElement.innerText || "";
//     return plainText.replace(/\*/g, "");
//   };

//   useEffect(() => {
//     return () => {
//       Object.values(audioInstances).forEach((audio) => {
//         audio.pause();
//         audio.currentTime = 0;
//       });
//     };
//   }, [audioInstances]);

//   const handleSpeak = async (answer, index) => {
//     const plainText = stripHtmlTags(answer);

//     // If this answer is already playing, pause it
//     if (playingAudioId === index) {
//       audioInstances[index].pause();
//       audioInstances[index].currentTime = 0;
//       setPlayingAudioId(null); // Reset the current playing audio
//       return;
//     }

//     // Pause any currently playing audio
//     if (playingAudioId !== null) {
//       audioInstances[playingAudioId].pause();
//       audioInstances[playingAudioId].currentTime = 0;
//     }

//     const detectedLanguage = detectMajorLanguage(plainText);
//     let speaker =
//       detectedLanguage === "hi"
//         ? "hi_male"
//         : detectedLanguage === "or"
//           ? "or_male"
//           : "en_male";

//     const requestBody = {
//       text: [plainText],
//       speaker: speaker,
//       speed: 1,
//       pitch: 0,
//     };

//     try {
//       const response = await fetch(
//         `${process.env.REACT_APP_CARE_SATHI}translation/text-to-speech`,

//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(requestBody),
//         }
//       );

//       if (!response.ok) {
//         throw new Error(`Error: ${response.statusText}`);
//       }

//       const data = await response.json();
//       const audioBase64 = data.audio_base64;
//       const audioBlob = base64ToBlob(audioBase64, "audio/wav");

//       // Create a URL for the Blob and play the audio
//       const audioUrl = URL.createObjectURL(audioBlob);
//       const audio = new Audio(audioUrl);

//       // Store the audio instance in state
//       setAudioInstances((prev) => ({
//         ...prev,
//         [index]: audio,
//       }));

//       // Play the audio and set the currently playing answer
//       audio.play();
//       setPlayingAudioId(index);

//       // When the audio finishes playing, reset the state
//       audio.onended = () => {
//         setPlayingAudioId(null);
//       };
//     } catch (error) {
//       console.error("Error converting text to speech:", error);
//     }
//   };

//   // Helper function to convert base64 to Blob
//   const base64ToBlob = (base64, mimeType) => {
//     const byteCharacters = atob(base64);
//     const byteArrays = [];

//     for (let offset = 0; offset < byteCharacters.length; offset += 512) {
//       const slice = byteCharacters.slice(offset, offset + 512);
//       const byteNumbers = new Array(slice.length);

//       for (let i = 0; i < slice.length; i++) {
//         byteNumbers[i] = slice.charCodeAt(i);
//       }

//       const byteArray = new Uint8Array(byteNumbers);
//       byteArrays.push(byteArray);
//     }

//     return new Blob(byteArrays, { type: mimeType });
//   };

//   const handleFileChange = async (event) => {
//     if (event.target.files.length > 0) {
//       const selectedFile = event.target.files[0];

//       // Function to convert file to base64
//       const convertToBase64 = (file) => {
//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsDataURL(file);
//         });
//       };

//       const fileType = selectedFile.type;
//       const documentType = fileType.split("/")[1];
//       const validFileTypes = ["application/pdf", "image/jpeg", "image/png"];

//       if (!validFileTypes.includes(fileType)) {
//         console.error("Unsupported file type.");
//         // setOpenSnackbar(true);
//         // setSnackbarmsg("Please upload a PDF or image file.");
//         return;
//       }

//       console.log(documentType, "selectedFile");
//       setDocumanttype(documentType);
//       const base64 = await convertToBase64(selectedFile);
//       const base64Content = base64.split(",")[1];
//       setBase64pdf(base64Content);
//       console.log(base64Content, "base64 Content");
//       setUploadedFileName(selectedFile.name);
//       const formData = new FormData();
//       formData.append("file", selectedFile, selectedFile.name);
//       setopenLabReportAnalyserModal(false);
//       // setSampleMessage("Analyse this Report");
//       // Perform any further actions with the base64 data here
//     }
//   };

//   const handleCloseSnackbar = () => {
//     setOpenSnackbar(false);
//   };

//   const formatAnswer = (response) => {
//     return response
//       .split("\n")
//       .map((line) => {
//         let result = "";
//         let inBold = false;

//         if (line.startsWith("* **")) {
//           const parts = line.split("**");
//           return `<li><strong>${parts[1]}</strong>${parts[2]}</li>`;
//         }

//         if (line.startsWith("    * **")) {
//           line = "  • " + line.slice(2);
//         }

//         for (let i = 0; i < line.length; i++) {
//           if (line[i] === "*" && line[i + 1] === "*") {
//             if (inBold) {
//               result += "</strong>";
//               inBold = false;
//             } else {
//               result += "<strong>";
//               inBold = true;
//             }
//             i++;
//           } else {
//             result += line[i];
//           }
//         }

//         if (inBold) {
//           result += "</strong>";
//         }

//         if (line.startsWith("• ")) {
//           return `<li>${result}</li>`;
//         } else {
//           return `<p>${result}</p>`;
//         }
//       })
//       .join("");
//   };

//   // const formatAnswer = (response) => {
//   //   return response
//   //     .split("\n")
//   //     .map((line) => {
//   //       if (line.startsWith("* **")) {
//   //         const parts = line.split("**");
//   //         return `<li><strong>${parts[1]}</strong>${parts[2]}</li>`;
//   //       }
//   //       if (line.startsWith("* ")) {
//   //         return `<li>${line.slice(2)}</li>`;
//   //       }
//   //       if (line.startsWith("**") && line.endsWith("**")) {
//   //         return `<strong>${line.slice(2, -2)}</strong>`;
//   //       }
//   //       if (line.startsWith("**")) {
//   //         return `<p><strong>${line.slice(2)}</strong></p>`;
//   //       }
//   //       if (line.endsWith("**")) {
//   //         return `<p>${line.slice(0, -2)}<strong></strong></p>`;
//   //       }
//   //       return `<p>${line}</p>`;
//   //     })
//   //     .join("");
//   // };

//   const handleChatHistoryQuestionClick = (sessionId, qaPairs) => {
//     Object.values(audioInstances).forEach((audio) => {
//       audio.pause();
//       audio.currentTime = 0;
//     });
//     setPlayingAudioId(null);
//     setShowHeader(false);
//     let result = qaPairs
//       .map((res) => {
//         return {
//           question: res.question,
//           answer: res.answer,
//           isLoading: false,
//           created_date: res.created_date,
//         };
//       })
//       .sort((a, b) => new Date(a.created_date) - new Date(b.created_date));

//     setTimestamp(sessionId);
//     setQaHistory(result);
//     setChatHistoryDrawer(false);

//     // Ensure chat scrolls to the bottom after chat history is loaded
//     scrollToBottom();
//   };

//   useEffect(() => {
//     scrollToBottom(); // Scroll to the bottom when qaHistory changes (new messages/answers)
//   }, [qaHistory]);
//   const handleNewChatWindow = () => {
//     genrateRandomTimestamp();
//     setQaHistory([]);
//     setChatHistoryDrawer(false);
//     Object.values(audioInstances).forEach((audio) => {
//       audio.pause();
//       audio.currentTime = 0;
//     });


//     setPlayingAudioId(null);






//   };

//   const chatHistoryUI = () => {
//     return (
//       <>
//         <div className="flex items-center justify-end sm:justify-between w-full p-4 bg-gray-800 rounded-lg  relative shadow-md">
//           <h4
//             className="hidden sm:block text-White text-lg font-bold text-center py-2"
//             style={{ fontSize: 16, fontWeight: 700 }}
//           >
//             Chat History
//           </h4>
//           <button
//             onClick={handleNewChatWindow}
//             aria-label="New chat"
//             className="flex items-center justify-center w-10 h-10 rounded-full bg-gray hover:bg-gray focus:outline-none focus:ring-2 focus:ring-lightBlue transition"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="24"
//               height="24"
//               fill="currentColor"
//               viewBox="0 0 24 24"
//               className="text-textGray hover:text-White transition-colors duration-200"
//             >
//               <path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path>
//             </svg>
//           </button>
//         </div>

//         <div className="space-y-4">
//           {Object.keys(chatHistory)?.map((res, index) => (
//             <div key={index} className="bg-gray-900 p-4 rounded-lg shadow">
//               <h4 className="py-2 text-white font-medium text-sm text-White ">
//                 {res}
//               </h4>
//               <div className="space-y-2">
//                 {chatHistory[res].map((res1, index1) => (
//                   <button
//                     key={index1}
//                     style={
//                       res1.session_id === timeStamp
//                         ? {
//                           backgroundColor: "rgb(230, 230, 230)",
//                           color: "#fff",
//                         }
//                         : { backgroundColor: "transparent", color: "#fff" }
//                     }
//                     className={`w-full text-left rounded-md px-3 py-1 transition duration-200 text-White text-sm font-normal
//               ${res1.session_id === timeStamp
//                         ? "bg-White text-[#000]"
//                         : "bg-gray-800 text-White hover:bg-gray-700"
//                       }
//             `}
//                     onClick={() =>
//                       handleChatHistoryQuestionClick(
//                         Number(res1.session_id),
//                         res1.qa_pairs
//                       )
//                     }
//                   >
//                     {res1.qa_pairs[res1.qa_pairs.length - 1].question}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           ))}
//         </div>
//       </>
//     );
//   };

//   // const generateItems = (arrayReference, type, text, language) =>  [
//   //   {
//   //     label: 'English to Odia',
//   //     key: '1',
//   //     onClick: () => onClickLanguageDropdown(arrayReference, type , text, 'en', 'or'),
//   //   },
//   //   {
//   //     label: 'Odia to English',
//   //     key: '2',
//   //     onClick: () => onClickLanguageDropdown(arrayReference, type, text, 'or', 'en'),
//   //   },
//   //   {
//   //     label: 'Hindi to Odia',
//   //     key: '3',
//   //     onClick: () => onClickLanguageDropdown(arrayReference, type, text, 'hi', 'or'),
//   //   },
//   //   {
//   //     label: 'Odia to Hindi',
//   //     key: '4',
//   //     onClick: () => onClickLanguageDropdown(arrayReference, type , text, 'or', 'hi'),
//   //   },
//   //   {
//   //     label: 'English to Hindi',
//   //     key: '5',
//   //     onClick: () => onClickLanguageDropdown(arrayReference, type, text, 'en', 'hi'),
//   //   },
//   //   {
//   //     label: 'Hindi to English',
//   //     key: '6',
//   //     onClick: () => onClickLanguageDropdown(arrayReference, type, text, 'hi', 'en'),
//   //   },
//   // ];

//   const generateItems = (arrayReference, type, text, language) => {
//     console.log(language, "LanguageGenerateItems")
//     switch (language) {
//       case "en":
//         return [
//           {
//             label: "Hindi",
//             key: "hi",
//             onClick: () =>
//               onClickLanguageDropdown(arrayReference, type, text, "en", "hi"),
//           },
//           {
//             label: "Odia",
//             key: "or",
//             onClick: () =>
//               onClickLanguageDropdown(arrayReference, type, text, "en", "or"),
//           },
//         ];
//       case "hi":
//       case "or":
//         return [
//           {
//             label: "English",
//             key: "en",
//             onClick: () =>
//               onClickLanguageDropdown(
//                 arrayReference,
//                 type,
//                 text,
//                 language,
//                 "en"
//               ),
//           },
//         ];
//       default:
//         return [];
//     }
//   };

//   console.log(serviceRecommendationObject, "svsdgusduodsdsbbsd");

//   const getDropdownTrigger = () => {
//     const isMobile = window.innerWidth <= 768;
//     return isMobile ? ["click"] : ["hover"];
//   };

//   const countCharactersInRange = (text, start, end) => {
//     return Array.from(text).filter(
//       (char) => start <= char.charCodeAt(0) && char.charCodeAt(0) <= end
//     ).length;
//   };

//   const detectMajorLanguage = (text) => {
//     const totalChars = text.length;
//     if (totalChars === 0) {
//       return "Empty Text";
//     }

//     const englishCount = countCharactersInRange(text, 0x0000, 0x007f);
//     const hindiCount = countCharactersInRange(text, 0x0900, 0x097f);
//     const odiaCount = countCharactersInRange(text, 0x0b00, 0x0b7f);


//     const englishProportion = englishCount / totalChars;
//     const hindiProportion = hindiCount / totalChars;
//     const odiaProportion = odiaCount / totalChars;


//     const proportions = {
//       en: englishProportion,
//       hi: hindiProportion,
//       or: odiaProportion,
//     };

//     return Object.keys(proportions).reduce((a, b) =>
//       proportions[a] > proportions[b] ? a : b
//     );
//   };

//   const onClickLanguageDropdown = async (
//     arrayReference,
//     type,
//     text,
//     src_language,
//     tgt_language
//   ) => {
//     try {

//       let cleanText = text.replace(/<\/?[^>]+(>|$)/g, "");

//       let payload = {
//         text: [cleanText],
//         src_lang: src_language,
//         tgt_lang: tgt_language,
//       };

//       // Send the translation request
//       let response = await http.post(
//         `${process.env.REACT_APP_CARE_SATHI}translation/translate`,
//         payload
//       );

//       if (response.status === 200) {
//         // Update the text with the translated response
//         setConvertedText({
//           index: arrayReference,
//           type: type,
//           text: response.data.responseList[0].outString,
//         });

//         // Update the specific question or answer language in qaHistory
//         setQaHistory((prevHistory) => {
//           const updatedHistory = [...prevHistory];

//           if (type === "question") {
//             // Update the language and translated question for the translated question
//             updatedHistory[arrayReference] = {
//               ...updatedHistory[arrayReference],
//               questionLanguage: tgt_language, // Set the new target language for the question
//               question: response.data.responseList[0].outString, // Update question text
//             };
//           } else if (type === "answer") {
//             // Update the language and translated answer for the translated answer
//             updatedHistory[arrayReference] = {
//               ...updatedHistory[arrayReference],
//               answerLanguage: tgt_language, // Set the new target language for the answer
//               answer: response.data.responseList[0].outString, // Update answer text
//             };
//           }

//           return updatedHistory; // Return the updated history
//         });
//       }
//     } catch (error) {
//       console.error("Translation Error:", error);
//       messageApi.open({
//         type: "error",
//         content:
//           error?.response?.data?.detail[0]?.msg || "Something went wrong.",
//       });
//     }
//   };

//   const sendError = (message) => {
//     messageApi.open({
//       type: "error",
//       content: message,
//     });
//   };

//   const handleNavigateToLabSelection = () => {
//     const query = new URLSearchParams({
//       referrer: "care-sathi", // Example query parameter, you can add more as needed
//     }).toString();

//     history.push(`/mylabreports?${query}`);
//   };

//   const onLanguageClick = (language) => {
//     setSelectedLanguage(language);
//     setLanguageModalVisible(false);
//   };

//   const handleOpenPopup = () => {
//     setopenLabReportAnalyserModal(true);
//     return true;

//     return false;
//   };

//   console.log(lab_report_analyser, "vgvggvdcgvs")

//   return (
//     <>
//       {contextHolder}
//       <Snackbar
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//         open={openSnackbar}
//         autoHideDuration={2500}
//         onClose={handleCloseSnackbar}
//       >
//         <Alert
//           onClose={handleCloseSnackbar}
//           severity={snackbarmsg.type}
//           variant="filled"
//           sx={{ width: "100%" }}
//         >
//           {snackbarmsg.msg}
//         </Alert>
//       </Snackbar>
//       <Header />
//       <div className="flex  justify-center items-center mt-20">
//         {/* <div className="hidden sm:block" style={{ width: "18%" }}>
//           <div
//             style={{
//               backgroundColor: "#004172",
//               paddingLeft: "1rem",
//               paddingRight: "1rem",
//               height: screenHeight - 2,
//             }}
//             className=" overflow-y-scroll"
//           >
//             {chatHistoryUI()}
//           </div>
//         </div> */}
//         <MenuOutlined
//           className=" hidden sm:block pt-2 absolute top-24 left-2"
//           onClick={() => setChatHistoryDrawer(true)}
//         />

//         <div className="flex items-center flex-col overflow-hidden relative px-2 sm:px-20 chat-container">
//           <div className="flex flex-col items-center w-full justify-center z-10 ">
//             <div className="flex gap-16">
//               {flagselectedagent === false && showHeader && (
//                 <div className="flex  items-center sm:items-start">
//                   <div>
//                     <img
//                       src={Caresathi}
//                       alt="Care Sathi Logo"
//                       className="h-16 w-16 sm:h-20 sm:w-20 mt-2"
//                     />
//                   </div>
//                   <div className="flex flex-col">
//                     <h1 className="text-xl sm:text-3xl text-[#005D8E] text-center sm:text-left font-bold pt-3">
//                       CareSathi
//                     </h1>
//                     <p className="text-center sm:text-left text-gray-600 text-sm sm:text-base mt-2">
//                       <span className="font-semibold">Care to Cure,</span>{" "}
//                       <span className="font-normal">your journey starts here</span>
//                     </p>
//                   </div>
//                 </div>
//               )}


//               <div className="p-2 sm:p-4 text-center">
//                 <p className="mb-2 sm:mb-3 ">Select language:</p>

//                 <div className="flex flex-wrap justify-center border border-gray rounded-full text-sm sm:text-base">
//                   <button
//                     className={`px-3 sm:px-5 py-1 sm:py-2 ${selectedLanguage === "en"
//                       ? "bg-secondaryColor text-White"
//                       : "bg-transparent text-black"
//                       } rounded-full`}
//                     onClick={() => setSelectedLanguage("en")}
//                   >
//                     English
//                   </button>

//                   <button
//                     className={`px-3 sm:px-5 py-1 sm:py-2 ${selectedLanguage === "hi"
//                       ? "bg-secondaryColor text-White"
//                       : "bg-transparent text-black"
//                       } rounded-full`}
//                     onClick={() => setSelectedLanguage("hi")}
//                   >
//                     Hindi
//                   </button>
//                   <button
//                     className={`px-3 sm:px-5 py-1 sm:py-2 ${selectedLanguage === "or"
//                       ? "bg-secondaryColor text-White"
//                       : "bg-transparent text-[#000]"
//                       } rounded-full`}
//                     onClick={() => setSelectedLanguage("or")}
//                   >
//                     Odia
//                   </button>
//                 </div>
//               </div>

//             </div>


//             {showHeader &&

//               // <div className="flex flex-col justify-center items-center mt-8">
//               //   <h2 className="text-xl sm:text-2xl font-bold mb-4">
//               //     Talk to Digital Concierge AI Agents
//               //   </h2>
//               //   <div className="flex justify-center gap-4">
//               //     {/* Card 1 */}
//               //     <div className="flex items-center bg-white rounded-lg shadow-md border border-gray-300 p-4 w-96">
//               //       <img
//               //         src={caresathiagent}
//               //         alt="Agent"
//               //         className="rounded-full w-16 h-16 mr-4"
//               //       />
//               //       <div>
//               //         <h3 className="text-lg font-semibold flex items-center">
//               //           <img
//               //             src={rx_image}
//               //             alt="Icon"
//               //             className="mr-2"
//               //           />
//               //           Prescription
//               //         </h3>
//               //         <p className="text-sm text-gray-600">
//               //           <strong>Rohit:</strong> Simplifies e-prescription, ensuring accuracy &
//               //           direct connectivity with pharmacies.
//               //         </p>
//               //       </div>
//               //     </div>

//               //     {/* Card 2 */}
//               //     <div className="flex items-center bg-white rounded-lg shadow-md border border-gray-300 p-4 w-96">
//               //       <img
//               //         src={caresathiagent}
//               //         alt="Agent"
//               //         className="rounded-full w-16 h-16 mr-4"
//               //       />
//               //       <div>
//               //         <h3 className="text-lg font-semibold flex items-center">
//               //           <img
//               //             src={rx_image}
//               //             alt="Icon"
//               //             className="mr-2"
//               //           />
//               //           Prescription
//               //         </h3>
//               //         <p className="text-sm text-gray-600">
//               //           <strong>Rohit:</strong> Simplifies e-prescription, ensuring accuracy &
//               //           direct connectivity with pharmacies.
//               //         </p>
//               //       </div>
//               //     </div>
//               //   </div>
//               // </div>
//               <AIConcierge setFlagselectedagent={setFlagselectedagent} />

//             }


//           </div>

//           <MenuOutlined
//             className="block sm:hidden pt-2 absolute top-2 right-2 z-20"
//             onClick={() => setChatHistoryDrawer(true)}
//           />
//           {

//             flagselectedagent === false &&
//             <div
//               className="flex flex-col justify-between mt-2 overflow-y-scroll"
//               style={{
//                 scrollbarWidth: "none",
//                 //  marginTop: !showHeader ? '0px' :'100px'
//               }}
//               ref={chatContainerRef}
//             >
//               {qaHistory.length ? (
//                 qaHistory.map((item, index) => (
//                   <div key={index} className="mb-8">
//                     <div
//                       className="p-5 float-right mb-2 w-9/12 sm:w-1/2 mr-2 sm:mr-0"
//                       style={{ backgroundColor: "#f4f4f4", borderRadius: "10px" }}
//                     >
//                       <div className="w-full flex justify-between">
//                         <div className="flex">
//                           <img
//                             src={Caresathi}
//                             alt="Care Sathi Logo"
//                             className="h-7 w-7 mt-1"
//                           />
//                           <p className="sm:pt-1 pl-3" style={{ fontSize: 14 }}>
//                             {convertedText.index == index &&
//                               convertedText.type == "question"
//                               ? convertedText.text
//                               : item.question}
//                           </p>
//                         </div>

//                         <Dropdown
//                           overlay={
//                             <Menu
//                               items={generateItems(
//                                 index,
//                                 "question",
//                                 item.question,
//                                 item.questionLanguage // Use the correct language for this specific question
//                               )}
//                             />
//                           }
//                           trigger={getDropdownTrigger()}
//                         >
//                           <Button>
//                             <TranslateIcon />
//                           </Button>
//                         </Dropdown>
//                       </div>
//                     </div>

//                     <div className="flex bgclr px-2 sm:px-4 py-3 w-full gap-3">
//                       <div className=" ">
//                         {item.isLoading ? (
//                           <ChatLoading />
//                         ) : (
//                           <div className="font-normal flex flex-col text-lg mb-6 bg-primaryColor text-White p-4 rounded-xl">
//                             <div className="flex justify-between">
//                               <div
//                                 style={{ fontSize: "14px" }}
//                                 dangerouslySetInnerHTML={{
//                                   __html:
//                                     convertedText.index == index &&
//                                       convertedText.type == "answer"
//                                       ? convertedText.text
//                                       : item.answer,
//                                 }}
//                               ></div>
//                               {textAnimationFinish && (
//                                 <Dropdown
//                                   overlay={
//                                     <Menu
//                                       items={generateItems(
//                                         index,
//                                         "answer",
//                                         item.answer,
//                                         item.answerLanguage // Use the correct language for this specific answer
//                                       )}
//                                     />
//                                   }
//                                   trigger={getDropdownTrigger()}
//                                 >
//                                   <Button>
//                                     <TranslateIcon />
//                                   </Button>
//                                 </Dropdown>
//                               )}
//                               {textAnimationFinish && (
//                                 <div className="flex flex-col">
//                                   <Button
//                                     icon={
//                                       playingAudioId === index ? (
//                                         <PauseIcon />
//                                       ) : (
//                                         <VolumeUpIcon />
//                                       )
//                                     }
//                                     onClick={() =>
//                                       handleSpeak(item.answer, index)
//                                     }
//                                     style={{
//                                       marginLeft: "10px",
//                                       fontSize: "16px",
//                                       display: "flex",
//                                       alignItems: "center",
//                                       padding: "0 12px",
//                                       gap: "8px",
//                                     }}
//                                   >
//                                     {playingAudioId === index ? "Pause" : "Play"}
//                                   </Button>
//                                 </div>
//                               )}
//                             </div>
//                             {/* {Object.hasOwn(
//                             serviceRecommendationObject,
//                             index.toString()
//                           ) && (
//                             <CallToActionComponent
//                               data={
//                                 serviceRecommendationObject[index.toString()]
//                               }
//                               snackbarmsg={(msg) => setSnackbarmsg(msg)}
//                               setOpenSnackbar={setOpenSnackbar}
//                               sessionId={timeStamp.toString()}
//                             />
//                           )}  */}


//                             {item.serviceRecommendation && (
//                               <CallToActionComponent
//                                 data={item.serviceRecommendation}
//                                 snackbarmsg={(msg) => setSnackbarmsg(msg)}
//                                 setOpenSnackbar={setOpenSnackbar}
//                                 sessionId={timeStamp.toString()}
//                               />
//                             )}


//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <div className="flex flex-col justify-center sm:flex-row gap-6 p-4 ">
//                   {/* Card 1 */}
//                   <button
//                     onClick={() =>
//                       setSampleMessage(
//                         selectedLanguage == "en"
//                           ? "What are diabetes symptoms?"
//                           : selectedLanguage == "hi"
//                             ? " डायबिटीज के लक्षण क्या हैं?"
//                             : selectedLanguage == "or"
//                               ? "ଡାଇବେଟିସର ଲକ୍ଷଣଗୁଡ଼ିକ କ’ଣ?"
//                               : ""
//                       )
//                     }
//                     className="h-max ai-suggestion-card flex flex-col  p-4 bg-White rounded-lg shadow-md hover:shadow-lg transition-shadow border border-lightGray h-40"
//                   >
//                     <p
//                       className="text-center text-gray-600 break-word"
//                       style={{ color: "#676767" }}
//                     >
//                       {selectedLanguage == "en"
//                         ? "What are diabetes symptoms?"
//                         : selectedLanguage == "hi"
//                           ? " डायबिटीज के लक्षण क्या हैं?"
//                           : selectedLanguage == "or"
//                             ? "ଡାଇବେଟିସର ଲକ୍ଷଣଗୁଡ଼ିକ କ’ଣ?"
//                             : ""}{" "}
//                     </p>
//                   </button>

//                   {/* Card 2 */}
//                   <button
//                     onClick={() =>
//                       setSampleMessage(
//                         selectedLanguage == "en"
//                           ? "How to manage high BP?"
//                           : selectedLanguage == "hi"
//                             ? "उच्च रक्तचाप को कैसे नियंत्रित करें?"
//                             : selectedLanguage == "or"
//                               ? "ଉଚ୍ଚ ରକ୍ତଚାପ କେମିତି ପରିଚାଳନା କରିବେ?"
//                               : ""
//                       )
//                     }
//                     className="h-max ai-suggestion-card flex flex-col  p-4 bg-White rounded-lg shadow-md w-full sm:w-[250px] hover:shadow-lg transition-shadow border border-lightGray  "
//                   >
//                     <p
//                       className="text-center text-gray-600 break-word"
//                       style={{ color: "#676767" }}
//                     >
//                       {selectedLanguage == "en"
//                         ? "How to manage high BP?"
//                         : selectedLanguage == "hi"
//                           ? "उच्च रक्तचाप को कैसे नियंत्रित करें?"
//                           : selectedLanguage == "or"
//                             ? "ଉଚ୍ଚ ରକ୍ତଚାପ କେମିତି ପରିଚାଳନା କରିବେ?"
//                             : ""}{" "}
//                     </p>
//                   </button>

//                   {/* Card 3 */}
//                   <button
//                     onClick={() =>
//                       setSampleMessage(
//                         selectedLanguage == "en"
//                           ? "Exercises for back pain?"
//                           : selectedLanguage == "hi"
//                             ? "पीठ दर्द के लिए कौन से व्यायाम करें?"
//                             : selectedLanguage == "or"
//                               ? "ପିଠିର ବେଥା ପାଇଁ କେଉଁସବୁ ଅଭ୍ୟାସ କରିବେ?"
//                               : ""
//                       )
//                     }
//                     className="h-max ai-suggestion-card flex flex-col  p-4 bg-White rounded-lg shadow-md w-full sm:w-[250px] hover:shadow-lg transition-shadow border border-lightGray  sm:mb-0"
//                   >
//                     <p
//                       className="text-center text-gray-600 break-word"
//                       style={{ color: "#676767" }}
//                     >
//                       {selectedLanguage == "en"
//                         ? "Exercises for back pain?"
//                         : selectedLanguage == "hi"
//                           ? "पीठ दर्द के लिए कौन से व्यायाम करें?"
//                           : selectedLanguage == "or"
//                             ? "ପିଠିର ବେଥା ପାଇଁ କେଉଁସବୁ ଅଭ୍ୟାସ କରିବେ?"
//                             : ""}{" "}
//                     </p>
//                   </button>
//                 </div>
//               )}
//               {/* </div> */}
//             </div>
//           }
//           { flagselectedagent  === false &&
//           <div
//             className=" w-full sm:w-[70%] mx-auto boxposition fixed bottom-0 px-2 sm:px-20"
//             style={{
//               paddingBottom: 5,
//               backgroundColor: "white",
//             }}
//           >
//             <div className="pt-2">
//               {uploadedFileName && (
//                 <span
//                   className="text-sm font-normal mb-2 ml-7 py-2 px-2 shadow rounded"
//                   style={{ backgroundColor: "#ffff" }}
//                 >
//                   <strong>Uploaded File:</strong> {uploadedFileName}
//                 </span>
//               )}
//               <div className="w-full flex justify-between relative items-center pb-3">
//                 <label
//                   className="flex text-lg font-normal p-3 cursor-pointer absolute"
//                   onClick={handleOpenPopup}
//                 >
//                   <img src={AttachFileIcon} alt="Attach File" className="w-6" />

//                 </label>

//                 <InputText
//                   value={sampleMessage}
//                   onChange={handleInputChange}
//                   onKeyUp={(e) => {
//                     if (e.key === "Enter" && !e.shiftKey) {
//                       e.preventDefault();

//                       handleArrowClick(e);
//                     }
//                   }}
//                   placeholder={
//                     selectedLanguage == "en"
//                       ? "Type your Medical Problems only ..."
//                       : selectedLanguage == "hi"
//                         ? "केवल अपनी चिकित्सा समस्याएं टाइप करें।"
//                         : selectedLanguage == "or"
//                           ? "କେବଳ ଆପଣଙ୍କ ଚିକିତ୍ସା ସମସ୍ୟାଗୁଡ଼ିକ ଲେଖନ କରନ୍ତୁ।"
//                           : ""
//                   }
//                   lang={selectedLanguage}
//                   style={{
//                     maxHeight: "200px",
//                     height: "52px",
//                     width: "100%",
//                     border: "none",
//                     backgroundColor: "#f4f4f4",
//                     outline: "none",

//                   }}

//                   className={` pr-20 pl-4 sm:text-base  text-sm p-inputtext p-component p-filled sm:pl-12 ${lab_report_analyser === "true" ? "pl-[35px]" : "pl-[40px]"
//                     }`}
//                 />

//                 <div className="absolute right-2 sm:right-4 flex">
//                   <MicRecorder
//                     getQuestionFromSpeechToText={getQuestionFromSpeechToText}
//                     sendError={sendError}
//                     selectedLanguage={selectedLanguage}
//                   />
//                   <button onClick={(e) => handleArrowClick(e)}>
//                     <img src={Send} alt="#" />
//                   </button>
//                 </div>
//               </div>
//               {/* <p class="flex text-lg font-normal pr-3">Disclaimer</p> */}
//             </div>
//           </div>
//           }
//         </div>
//       </div>
//       <Drawer
//         styles={{ body: { padding: 0 } }}
//         title="Chat History"
//         onClose={() => setChatHistoryDrawer(false)}
//         placement="left"
//         open={chatHistoryDrawer}
//       >
//         <div
//           style={{
//             minWidth: "25%",
//             backgroundColor: "#004172",
//             paddingLeft: "1rem",
//             paddingRight: "1rem",
//           }}
//           className="min-w-3/12 overflow-y-hidden h-screen"
//         >
//           {chatHistoryUI()}
//         </div>
//       </Drawer>

//       <Modal
//         title="Disclaimer"
//         className="commonModal"
//         open={false}
//         onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
//         onCancel={() => {
//           setshowaddmemberpopup(!showaddmemberpopup);
//         }}
//         width="65%"
//         style={{ top: 20 }}
//         footer={[]}
//       >
//         <p className="sm:text-base text-sm">
//           {" "}
//           CareSathi AI is designed to provide an automatic response with a
//           general overview of the user's health status based on the available
//           research data. CareSathi AI is not tailored to detect individual
//           symptoms and is not a substitute for professional medical diagnosis.
//           Users bear sole responsibility for their reliance on the response and
//           we expressly disclaim any and all liability arising from the use and
//           reliance on CareSathi AI responses. For personalized advice and
//           recommendations, pls visit our CureBay clinic.
//         </p>
//       </Modal>

//       <Modal
//         open={openLabReportAnalyserModal}
//         onOk={() => { }}
//         onCancel={() => setopenLabReportAnalyserModal(false)}
//         footer={null} // Remove the default footer
//         centered // Center the modal
//         width={400} // Adjust width to match your design
//         bodyStyle={{ padding: "30px", textAlign: "center" }} // Custom body styles
//       >
//         {/* Modal Content */}
//         <Title level={4}>Share your report here</Title>
//         <Text
//           type="secondary"
//           style={{ display: "block", marginBottom: "20px" }}
//         >
//           You can upload the report from your device or select it from the Lab
//           Reports section.
//         </Text>

//         {/* Buttons */}
//         <Button
//           onClick={() => fileInputRef.current.click()}
//           icon={<UploadOutlined />}
//           style={{
//             width: "100%",
//             marginBottom: "15px",
//             backgroundColor: "#004171",
//             color: "#ffff",
//           }}
//         >
//           <input
//             type="file"
//             style={{ display: "none" }}
//             onChange={handleFileChange}
//             ref={fileInputRef}
//           />
//           Upload Your Report
//         </Button>
//         <Button
//           type="default"
//           onClick={handleNavigateToLabSelection}
//           icon={<SearchOutlined />}
//           style={{ width: "100%", borderColor: "#004171", color: "#004171" }}
//         >
//           Find Your Lab Report
//         </Button>
//       </Modal>
//     </>
//   );
// };

// export default CareSathi;

import React, { useState, useRef, useEffect } from "react";
// import arrowicon from "../../Assets/Images/arrowicon.png";
// import attach from "../../Assets/Images/attach.png";
import { InputText } from "primereact/inputtext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Menu, Dropdown, Button, Typography } from "antd";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import ChatLoading from "../../Shared/chatLoading";
import TranslateIcon from "@mui/icons-material/Translate";
import AttachFileIcon from "../../../Assets/Images/AttachFile.svg";
import SendIcon from "@mui/icons-material/Send";
import { Card } from "primereact/card";
import { useSelector } from "react-redux";
import { Modal, Drawer, message } from "antd";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import PauseIcon from "@mui/icons-material/Pause";

import moment from "moment";
import {
  MenuOutlined,
  UploadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import MicRecorder from "./MicRecorder";
import http from "../../../Redux/Services/http-common";
import Layouts from "../../Layouts";
import Header from "../../Shared/Header";
import { useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../../Components/PageRouting/AppRoutes";
import CallToActionComponent from "./CallToActionComponent";
import Caresathi from "../../../Assets/Images/caresathi_logo_ai.svg";
import Infocircle from "../../../Assets/Images/infocircle_ai.svg";
import Send from "../../../Assets/Images/send_icon.svg";
import { Segmented } from "antd";
const { Title, Text } = Typography;
const CareSathi = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [getAnswerBot, setgetAnswerBot] = useState([]);
  const [sampleMessage, setSampleMessage] = useState("");
  const [question, setQuestion] = useState("");
  let s_token = secureStorage.getItem("token");
  const [formDataState, setFormDataState] = useState(null);
  const [qaHistory, setQaHistory] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(); 

  const [serviceRecommendationObject, setServiceRecommendationObject] =
    useState({
      ["-1"]: [
        {
          link: "",
          reasoning: "",
          service: "",
        },
      ],
    });
  const fileInputRef = useRef(null);
  const [extractedData, setExtractedData] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const activePatient = JSON.parse(localStorage.getItem("activePatient"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  var circleCode = localStorage.getItem("userId");
  const [base64pdf, setBase64pdf] = useState("");
  const [snackbarmsg, setSnackbarmsg] = useState({ msg: "", type: "" });
  const userData = useSelector((state) => state.authReducer.patientData);
  const [showaddmemberpopup, setshowaddmemberpopup] = useState(false);
  const [documanttype, setDocumanttype] = useState("");
  const [chatHistory, setChatHistory] = useState({});
  const [chatHistoryDrawer, setChatHistoryDrawer] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [timeStamp, setTimestamp] = useState("");
  const [textAnimationFinish, setTextAnimationFinish] = useState(false);
  const [answerCount, setAnswerCount] = useState(-1);
  const [languageModalVisible, setLanguageModalVisible] = useState(true);
  const [serviceRecommendation, setServiceRecommendation] = useState([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioInstances, setAudioInstances] = useState({});
  const [playingAudioId, setPlayingAudioId] = useState(null);
  const [openLabReportAnalyserModal, setopenLabReportAnalyserModal] =
    useState(false);
  const history = useHistory();
  const location = useLocation(); // Get the location object from the router
  const queryParams = new URLSearchParams(location.search); // Create a URLSearchParams object with the query string
  const [selectedLanguage, setSelectedLanguage] = React.useState("en");
  const [alignValue, setAlignValue] = React.useState("center");
  // Extracting individual query parameters
  const lab_report_analyser = queryParams.get("lab_report_analyser");
  const lab_report_analyser_popup = queryParams.get("popup");
  const reportUrl = queryParams.get("reportUrl");
  const reportExtType = queryParams.get("reportExtType");
  const orderId = queryParams.get("orderId");
  const [translatedQuestion, setTranslatedQuestion] = useState({});
  const [translatedAnswer, setTranslatedAnswer] = useState({});

  const [convertedText, setConvertedText] = useState({
    index: -1,
    type: "",
    text: "",
  });

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;
      
      // Check if the content is scrollable
      console.log(container,"ContainerDimensions")
      if (container.scrollHeight > container.clientHeight) {
        container.scroll({
          top: container.scrollHeight,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() =>{
      // Initially hide the iframe with id="launcher-frame"
      const iframe = document.getElementById("freshworks-container");
      if (iframe) {
        iframe.style.display = "none";
      }
  },[])
  
  

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (reportUrl && reportExtType) {
      fetchLabReportAsBase64(reportUrl, reportExtType);
    }
  }, [reportUrl]);

  // useEffect(() =>{
  //     if(sampleMessage && uploadedFileName){
  //     handleArrowClick();
  //     }
  // },[sampleMessage, uploadedFileName])

  const fetchLabReportAsBase64 = async (url, type) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        let converttype =
          type.toLowerCase() === "application/pdf"
            ? "pdf"
            : type.toLowerCase() === "image/jpg"
            ? "jpg"
            : type.toLowerCase() === "image/jpeg"
            ? "jpeg"
            : type.toLowerCase() === "image/png"
            ? "png"
            : type;
        setDocumanttype(converttype);
        const base64Content = base64data.split(",")[1];
        setBase64pdf(base64Content);
        setUploadedFileName(reportUrl);
        // setSampleMessage("Analyse this Report");
        // Perform any further actions with the base64 data here
      };

      if (type === "application/pdf") {
        reader.readAsDataURL(blob); // Read PDF as Data URL
      } else if (type.startsWith("image/")) {
        reader.readAsDataURL(blob); // Read Image as Data URL
      }
    } catch (error) {
      console.error("Error fetching or converting lab report:", error);
    }
  };

  useEffect(() => {
    if (lab_report_analyser_popup == "true") {
      setopenLabReportAnalyserModal(true);
    } else {
      setopenLabReportAnalyserModal(false);
    }
  }, [lab_report_analyser_popup]);

  useEffect(() => {
    if (textAnimationFinish) {
      setServiceRecommendationObject((prevState) => ({
        ...prevState,
        [answerCount]: serviceRecommendation,
      }));
    }
  }, [textAnimationFinish]);

  useEffect(() => {
    if (qaHistory.length) {
      scrollToBottom();  // Scroll to bottom when chat history updates
    }
  }, [qaHistory]);

  useEffect(() => {
    if (qaHistory.length) {
      scrollToBottom(); // Scrolls to the bottom when chat history is updated
    }
  }, [qaHistory]);
  
  useEffect(() => {
    if (loading === false && !showHeader) {
      scrollToBottom(); // Scrolls when loading is done or after a new message is added
    }
  }, [loading]);
  
  useEffect(() => {
    setshowaddmemberpopup(true);
  }, []);

  useEffect(() => {
    genrateRandomTimestamp();
  }, []);

  const genrateRandomTimestamp = () => {
    const currentDateTime = new Date();
    const unixTimestamp = Math.floor(currentDateTime.getTime() / 1000);

    setTimestamp(unixTimestamp);
  };

  useEffect(() => {
    if (userData.code) {
      getChatHistory();
    }
  }, [userData.code]);

  const getChatHistory = () => {
    let payload = {
      // identifier: "user13",
      identifier: userData.code,
      page: 1,
      page_size: 10,
    };

    fetch(`${process.env.REACT_APP_CARE_SATHI_CHATHISTORY}qa`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${s_token}`,
      },
      body: JSON.stringify(payload),
    }).then(async (res) => {
      let result = await res.json();
      if (result.data.length) {
        // const groupedHistory = result?.data.reduce((acc, session) => {
        //   const date = new Date(
        //     session.qa_pairs[session.qa_pairs.length - 1].created_date
        //   ).toLocaleDateString();
        //   if (!acc[date]) {
        //     acc[date] = [];
        //   }
        //   acc[date].push({
        //     session_id: session.session_id,
        //     question: session.qa_pairs[session.qa_pairs.length - 1].question,
        //     qa_pairs: session.qa_pairs,
        //   });
        //   return acc;
        // }, {})
        let filterDataWithSessionId = {};
        result.data.forEach((res) => {
          const date = new Date(
            res.qa_pairs[res.qa_pairs.length - 1].created_date
          ).toLocaleDateString();
          if (/^\d+$/.test(res.session_id)) {
            if (!filterDataWithSessionId[date]) {
              filterDataWithSessionId[date] = [res];
            } else {
              filterDataWithSessionId[date].push(res);
            }
          }
        });
        setChatHistory(filterDataWithSessionId);
        // let ee = Object.keys(filterDataWithSessionId)
        // console.log(filterDataWithSessionId[ee[0]],filterDataWithSessionId, "sdvihsdouvghsdudsvsdv")
      }
    });
  };

  const typeEffect = (text, callback) => {
    let index = 0;
    const delay = 10; // Adjust the typing speed here (milliseconds per character)

    const typeCharacter = () => {
      if (index < text.length) {
        callback(text.slice(0, index + 1));
        index++;
        setTimeout(typeCharacter, delay);
      } else {
        callback(text); // Ensure the full text is set at the end
        setTextAnimationFinish(true);
      }
    };

    typeCharacter();
  };

  const getAnswer = async (reData) => {
    try {
      const src_language = detectMajorLanguage(reData.slice(0, 40)); 
  
     
      setQaHistory((prevHistory) => [
        ...prevHistory,
        {
          question: reData,
          answer: "",
          isLoading: true,
          questionLanguage: src_language,
          serviceRecommendation: null,
        },
      ]);
      
      setLoading(true);
     
  
      let url = `${process.env.REACT_APP_CARE_SATHI}all_in_one/get_answer_v2`;
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${s_token}`,
        },
        body: JSON.stringify({
          user_prompt: reData,
          classify: 1,
          session_id: timeStamp.toString(),
          identifier: userData.code,
          src_lang: src_language, 
          
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setServiceRecommendation(null);
      const data = await response.json();
      if (data.status == 200) {
        let answer = data.data.answer;
        let count = answerCount + 1;
        console.log(data.data,"vgdcbdfh")
        const detectedLanguage = detectMajorLanguage(answer); 
        const recommendation = data.data.ServiceRecommendation || null;
        
        setAnswerCount(count);
        const formattedAnswer = formatAnswer(answer);
  
       
        setQaHistory((prevHistory) =>
          prevHistory.map((item, index) =>
            index === prevHistory.length - 1
              ? {
                  ...item,
                  answer: formattedAnswer,
                  answerLanguage: detectedLanguage,
                  serviceRecommendation: recommendation,
                  isLoading: false,
                }
              : item
          )
        );
  
        setTextAnimationFinish(false);
        typeEffect(formattedAnswer, (typedText) => {
          updateAnswer(typedText);
        });
  
        setgetAnswerBot((prevAnswers) => [
          ...prevAnswers,
          { response: answer },
        ]);
  
        saveMessageToApi({
          question: reData,
          answer: formattedAnswer,
          createdBy: userData ? userData.code : "",
          chatSession: timeStamp,
          sourceChannel: "B2C",
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbarmsg({
        msg: "It seems there was an issue. Would you mind trying again?",
        type: "error",
      });
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleInputChange = (e) => {
    setSampleMessage(e.target.value);
  };

  const getQuestionFromSpeechToText = (text) => {
    setSampleMessage(text);
  };
  const [showHeader, setShowHeader] = useState(true);
  const handleArrowClick = async (e) => {
    setShowHeader(false);
    if (loading) {
      setSnackbarmsg({
        msg: "Please wait till the previous response is loading.",
        type: "warning",
      });
      setOpenSnackbar(true);
      return;
    }
    if (!sampleMessage) {
      setOpenSnackbar(true);
      setSnackbarmsg({ msg: "Please write a sampleMessage", type: "warning" });
      return;
    }
    if (base64pdf) {
      try {
        const src_language = detectMajorLanguage(sampleMessage.slice(0, 40)); // Detect language for the question
  
        // Set qaHistory with the detected question language
        setQaHistory((prevHistory) => [
          ...prevHistory,
          {
            question: `File: ${uploadedFileName} Question: ${sampleMessage}`,
            answer: "",
            isLoading: true,
            questionLanguage: src_language,
            serviceRecommendation: null, 
          },
        ]);
        setLoading(true);
  
        const requestBody = {
          user_prompt: sampleMessage,
          classify: 1,
          document: base64pdf,
          document_type: documanttype,
          identifier: userData.code,
          session_id: timeStamp.toString(),
          src_lang: src_language, // Pass the detected language as source language
        };
  
        let url = `${process.env.REACT_APP_CARE_SATHI}all_in_one/get_answer_v2`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${s_token}`,
          },
          body: JSON.stringify(requestBody),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          const data = await response.json();
          if (data.status == 200) {
            let answer = data.data.answer;
            let count = answerCount + 1;
            const detectedLanguage = detectMajorLanguage(answer); // Detect the language for the answer
            const recommendation = data.data.ServiceRecommendation || null;
            setAnswerCount(count);
            const formattedAnswer = formatAnswer(answer);
  
            // Update qaHistory with the detected language for both question and answer
            setQaHistory((prevHistory) =>
              prevHistory.map((item, index) =>
                index === prevHistory.length - 1
                  ? {
                      ...item,
                      answer: formattedAnswer,
                      answerLanguage: detectedLanguage,
                      serviceRecommendation: recommendation, 
                      isLoading: false,
                    }
                  : item
              )
            );
  
            setTextAnimationFinish(false);
            typeEffect(formattedAnswer, (typedText) => {
              updateAnswer(typedText);
            });
  
            setgetAnswerBot((prevAnswers) => [
              ...prevAnswers,
              { response: answer },
            ]);
  
            const removeEmojis = formattedAnswer.replace(
              /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{FE00}-\u{FE0F}]|[\u{1F910}-\u{1F9FF}]|[\u{1F1E6}-\u{1F1FF}]/gu,
              ""
            );
            saveMessageToApi({
              question: sampleMessage,
              answer: removeEmojis,
              createdBy: userData ? userData.code : "",
              chatSession: timeStamp,
              sourceChannel: "B2C",
            });
  
            // Ensure the chat scrolls to the bottom when a new answer is received
            scrollToBottom();
          }
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
        setUploadedFileName("");
        setFormDataState(null);
        setSampleMessage(""); // Clear the sampleMessage state after uploading the file.
        fileInputRef.current.value = ""; // Clear the file input
      }
    } else {
      setQuestion(sampleMessage);
      await getAnswer(sampleMessage); // Use the same getAnswer function for non-PDF cases
      setSampleMessage("");
      scrollToBottom(); // Ensure chat scrolls to the bottom when a new question is typed
    }
  };
  
  

  const saveMessageToApi = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ChatBotQa/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${s_token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw Error(`HTTP error! status: ${response.status}`);
      } else {
        const responseData = await response.json();
        getChatHistory();
      }
    } catch (error) {
      console.error("Failed to save sampleMessage:", error);
    }
  };
  const updateAnswer = (typedText) => {
    setQaHistory((prevHistory) =>
      prevHistory.map((item, index) =>
        index === prevHistory.length - 1 ? { ...item, answer: typedText } : item
      )
    );
  };
  const finalizeAnswer = (formattedAnswer, detectedLanguage) => {
    setQaHistory((prevHistory) =>
      prevHistory.map((item, index) =>
        index === prevHistory.length - 1
          ? {
              ...item,
              answer: formattedAnswer,
              answerLanguage: detectedLanguage,
              isLoading: false,
            }
          : item
      )
    );
  };

  // const handleFileChange = async (event) => {
  //   if (event.target.files.length > 0) {
  //     const selectedFile = event.target.files[0];

  //     if (selectedFile.type == "application/pdf") {
  //       // console.error("File is not a PDF.");
  //       // setOpenSnackbar(true);
  //       // setSnackbarmsg("Please upload pdf file.");
  //       // return;

  //     console.log(selectedFile, "selectedFile");
  //     const base64 = await convertToBase64(selectedFile);
  //     const base64Content = base64.split(",")[1];
  //     setBase64pdf(base64Content);
  //     console.log(base64Content, "base64 PDF");
  //     setUploadedFileName(selectedFile.name);
  //     const formData = new FormData();
  //     formData.append("pdf_file", selectedFile, selectedFile.name);
  //     }
  //     else{

  //     }
  //   }
  // };
  const stripHtmlTags = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;

    const plainText = tempElement.textContent || tempElement.innerText || "";
    return plainText.replace(/\*/g, "");
  };

  useEffect(() => {
    return () => {
      Object.values(audioInstances).forEach((audio) => {
        audio.pause();
        audio.currentTime = 0;
      });
    };
  }, [audioInstances]);

  const handleSpeak = async (answer, index) => {
    const plainText = stripHtmlTags(answer);

    // If this answer is already playing, pause it
    if (playingAudioId === index) {
      audioInstances[index].pause();
      audioInstances[index].currentTime = 0;
      setPlayingAudioId(null); // Reset the current playing audio
      return;
    }

    // Pause any currently playing audio
    if (playingAudioId !== null) {
      audioInstances[playingAudioId].pause();
      audioInstances[playingAudioId].currentTime = 0;
    }

    const detectedLanguage = detectMajorLanguage(plainText);
    let speaker =
      detectedLanguage === "hi"
        ? "hi_male"
        : detectedLanguage === "or"
        ? "or_male"
        : "en_male";

    const requestBody = {
      text: [plainText],
      speaker: speaker,
      speed: 1,
      pitch: 0,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_CARE_SATHI}translation/text-to-speech`,
        
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      const audioBase64 = data.audio_base64;
      const audioBlob = base64ToBlob(audioBase64, "audio/wav");

      // Create a URL for the Blob and play the audio
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);

      // Store the audio instance in state
      setAudioInstances((prev) => ({
        ...prev,
        [index]: audio,
      }));

      // Play the audio and set the currently playing answer
      audio.play();
      setPlayingAudioId(index);

      // When the audio finishes playing, reset the state
      audio.onended = () => {
        setPlayingAudioId(null);
      };
    } catch (error) {
      console.error("Error converting text to speech:", error);
    }
  };

  // Helper function to convert base64 to Blob
  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
  };

  const handleFileChange = async (event) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];

      // Function to convert file to base64
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      const fileType = selectedFile.type;
      const documentType = fileType.split("/")[1];
      const validFileTypes = ["application/pdf", "image/jpeg", "image/png"];

      if (!validFileTypes.includes(fileType)) {
        console.error("Unsupported file type.");
        // setOpenSnackbar(true);
        // setSnackbarmsg("Please upload a PDF or image file.");
        return;
      }

      console.log(documentType, "selectedFile");
      setDocumanttype(documentType);
      const base64 = await convertToBase64(selectedFile);
      const base64Content = base64.split(",")[1];
      setBase64pdf(base64Content);
      console.log(base64Content, "base64 Content");
      setUploadedFileName(selectedFile.name);
      const formData = new FormData();
      formData.append("file", selectedFile, selectedFile.name);
      setopenLabReportAnalyserModal(false);
      // setSampleMessage("Analyse this Report");
      // Perform any further actions with the base64 data here
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const formatAnswer = (response) => {
    return response
      .split("\n")
      .map((line) => {
        let result = "";
        let inBold = false;

        if (line.startsWith("* **")) {
          const parts = line.split("**");
          return `<li><strong>${parts[1]}</strong>${parts[2]}</li>`;
        }

        if (line.startsWith("    * **")) {
          line = "  • " + line.slice(2);
        }

        for (let i = 0; i < line.length; i++) {
          if (line[i] === "*" && line[i + 1] === "*") {
            if (inBold) {
              result += "</strong>";
              inBold = false;
            } else {
              result += "<strong>";
              inBold = true;
            }
            i++;
          } else {
            result += line[i];
          }
        }

        if (inBold) {
          result += "</strong>";
        }

        if (line.startsWith("• ")) {
          return `<li>${result}</li>`;
        } else {
          return `<p>${result}</p>`;
        }
      })
      .join("");
  };

  // const formatAnswer = (response) => {
  //   return response
  //     .split("\n")
  //     .map((line) => {
  //       if (line.startsWith("* **")) {
  //         const parts = line.split("**");
  //         return `<li><strong>${parts[1]}</strong>${parts[2]}</li>`;
  //       }
  //       if (line.startsWith("* ")) {
  //         return `<li>${line.slice(2)}</li>`;
  //       }
  //       if (line.startsWith("**") && line.endsWith("**")) {
  //         return `<strong>${line.slice(2, -2)}</strong>`;
  //       }
  //       if (line.startsWith("**")) {
  //         return `<p><strong>${line.slice(2)}</strong></p>`;
  //       }
  //       if (line.endsWith("**")) {
  //         return `<p>${line.slice(0, -2)}<strong></strong></p>`;
  //       }
  //       return `<p>${line}</p>`;
  //     })
  //     .join("");
  // };

  const handleChatHistoryQuestionClick = (sessionId, qaPairs) => {
    Object.values(audioInstances).forEach((audio) => {
      audio.pause();
      audio.currentTime = 0; 
    });
    setPlayingAudioId(null);
    setShowHeader(false);
    let result = qaPairs
      .map((res) => {
        return {
          question: res.question,
          answer: res.answer,
          isLoading: false,
          created_date: res.created_date,
        };
      })
      .sort((a, b) => new Date(a.created_date) - new Date(b.created_date));
  
    setTimestamp(sessionId);
    setQaHistory(result);
    setChatHistoryDrawer(false);
    
    // Ensure chat scrolls to the bottom after chat history is loaded
    scrollToBottom();
  };
  
  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom when qaHistory changes (new messages/answers)
  }, [qaHistory]);
  const handleNewChatWindow = () => {
    genrateRandomTimestamp();
    setQaHistory([]);
    setChatHistoryDrawer(false);
    Object.values(audioInstances).forEach((audio) => {
      audio.pause();
      audio.currentTime = 0; 
    });
  
    
    setPlayingAudioId(null);
  
   
    
    
    
   
  };

  const chatHistoryUI = () => {
    return (
      <>
        <div className="flex items-center justify-end sm:justify-between w-full p-4 bg-gray-800 rounded-lg  relative shadow-md">
          <h4
            className="hidden sm:block text-White text-lg font-bold text-center py-2"
            style={{ fontSize: 16, fontWeight: 700 }}
          >
            Chat History
          </h4>
          <button
            onClick={handleNewChatWindow}
            aria-label="New chat"
            className="flex items-center justify-center w-10 h-10 rounded-full bg-gray hover:bg-gray focus:outline-none focus:ring-2 focus:ring-lightBlue transition"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              viewBox="0 0 24 24"
              className="text-textGray hover:text-White transition-colors duration-200"
            >
              <path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path>
            </svg>
          </button>
        </div>

        <div className="space-y-4">
          {Object.keys(chatHistory)?.map((res, index) => (
            <div key={index} className="bg-gray-900 p-4 rounded-lg shadow">
              <h4 className="py-2 text-white font-medium text-sm text-White ">
                {res}
              </h4>
              <div className="space-y-2">
                {chatHistory[res].map((res1, index1) => (
                  <button
                    key={index1}
                    style={
                      res1.session_id === timeStamp
                        ? {
                            backgroundColor: "rgb(230, 230, 230)",
                            color: "#fff",
                          }
                        : { backgroundColor: "transparent", color: "#fff" }
                    }
                    className={`w-full text-left rounded-md px-3 py-1 transition duration-200 text-White text-sm font-normal
              ${
                res1.session_id === timeStamp
                  ? "bg-White text-[#000]"
                  : "bg-gray-800 text-White hover:bg-gray-700"
              }
            `}
                    onClick={() =>
                      handleChatHistoryQuestionClick(
                        Number(res1.session_id),
                        res1.qa_pairs
                      )
                    }
                  >
                    {res1.qa_pairs[res1.qa_pairs.length - 1].question}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  // const generateItems = (arrayReference, type, text, language) =>  [
  //   {
  //     label: 'English to Odia',
  //     key: '1',
  //     onClick: () => onClickLanguageDropdown(arrayReference, type , text, 'en', 'or'),
  //   },
  //   {
  //     label: 'Odia to English',
  //     key: '2',
  //     onClick: () => onClickLanguageDropdown(arrayReference, type, text, 'or', 'en'),
  //   },
  //   {
  //     label: 'Hindi to Odia',
  //     key: '3',
  //     onClick: () => onClickLanguageDropdown(arrayReference, type, text, 'hi', 'or'),
  //   },
  //   {
  //     label: 'Odia to Hindi',
  //     key: '4',
  //     onClick: () => onClickLanguageDropdown(arrayReference, type , text, 'or', 'hi'),
  //   },
  //   {
  //     label: 'English to Hindi',
  //     key: '5',
  //     onClick: () => onClickLanguageDropdown(arrayReference, type, text, 'en', 'hi'),
  //   },
  //   {
  //     label: 'Hindi to English',
  //     key: '6',
  //     onClick: () => onClickLanguageDropdown(arrayReference, type, text, 'hi', 'en'),
  //   },
  // ];

  const generateItems = (arrayReference, type, text, language) => {
    console.log(language,"LanguageGenerateItems")
    switch (language) {
      case "en":
        return [
          {
            label: "Hindi",
            key: "hi",
            onClick: () =>
              onClickLanguageDropdown(arrayReference, type, text, "en", "hi"),
          },
          {
            label: "Odia",
            key: "or",
            onClick: () =>
              onClickLanguageDropdown(arrayReference, type, text, "en", "or"),
          },
        ];
      case "hi":
      case "or":
        return [
          {
            label: "English",
            key: "en",
            onClick: () =>
              onClickLanguageDropdown(
                arrayReference,
                type,
                text,
                language,
                "en"
              ),
          },
        ];
      default:
        return [];
    }
  };

  console.log(serviceRecommendationObject, "svsdgusduodsdsbbsd");

  const getDropdownTrigger = () => {
    const isMobile = window.innerWidth <= 768;
    return isMobile ? ["click"] : ["hover"];
  };

  const countCharactersInRange = (text, start, end) => {
    return Array.from(text).filter(
      (char) => start <= char.charCodeAt(0) && char.charCodeAt(0) <= end
    ).length;
  };

  const detectMajorLanguage = (text) => {
    const totalChars = text.length;
    if (totalChars === 0) {
      return "Empty Text";
    }
    
    const englishCount = countCharactersInRange(text, 0x0000, 0x007f);
    const hindiCount = countCharactersInRange(text, 0x0900, 0x097f);
    const odiaCount = countCharactersInRange(text, 0x0b00, 0x0b7f);


    const englishProportion = englishCount / totalChars;
    const hindiProportion = hindiCount / totalChars;
    const odiaProportion = odiaCount / totalChars;


    const proportions = {
      en: englishProportion,
      hi: hindiProportion,
      or: odiaProportion,
    };

    return Object.keys(proportions).reduce((a, b) =>
      proportions[a] > proportions[b] ? a : b
    );
  };

  const onClickLanguageDropdown = async (
    arrayReference,
    type,
    text,
    src_language,
    tgt_language
  ) => {
    try {
    
      let cleanText = text.replace(/<\/?[^>]+(>|$)/g, "");

      let payload = {
        text: [cleanText],
        src_lang: src_language,
        tgt_lang: tgt_language,
      };

      // Send the translation request
      let response = await http.post(
        `${process.env.REACT_APP_CARE_SATHI}translation/translate`,
        payload
      );

      if (response.status === 200) {
        // Update the text with the translated response
        setConvertedText({
          index: arrayReference,
          type: type,
          text: response.data.responseList[0].outString,
        });

        // Update the specific question or answer language in qaHistory
        setQaHistory((prevHistory) => {
          const updatedHistory = [...prevHistory];

          if (type === "question") {
            // Update the language and translated question for the translated question
            updatedHistory[arrayReference] = {
              ...updatedHistory[arrayReference],
              questionLanguage: tgt_language, // Set the new target language for the question
              question: response.data.responseList[0].outString, // Update question text
            };
          } else if (type === "answer") {
            // Update the language and translated answer for the translated answer
            updatedHistory[arrayReference] = {
              ...updatedHistory[arrayReference],
              answerLanguage: tgt_language, // Set the new target language for the answer
              answer: response.data.responseList[0].outString, // Update answer text
            };
          }

          return updatedHistory; // Return the updated history
        });
      }
    } catch (error) {
      console.error("Translation Error:", error);
      messageApi.open({
        type: "error",
        content:
          error?.response?.data?.detail[0]?.msg || "Something went wrong.",
      });
    }
  };

  const sendError = (message) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const handleNavigateToLabSelection = () => {
    const query = new URLSearchParams({
      referrer: "care-sathi", // Example query parameter, you can add more as needed
    }).toString();

    history.push(`/mylabreports?${query}`);
  };

  const onLanguageClick = (language) => {
    setSelectedLanguage(language);
    setLanguageModalVisible(false);
  };

  const handleOpenPopup = () => {
    setopenLabReportAnalyserModal(true);
    return true;

    return false;
  };

  console.log(lab_report_analyser,"vgvggvdcgvs")

  return (
    <>
      {contextHolder}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={2500}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarmsg.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarmsg.msg}
        </Alert>
      </Snackbar>
      <Header />
      <div className="flex  justify-center items-center mt-20">
        {/* <div className="hidden sm:block" style={{ width: "18%" }}>
          <div
            style={{
              backgroundColor: "#004172",
              paddingLeft: "1rem",
              paddingRight: "1rem",
              height: screenHeight - 2,
            }}
            className=" overflow-y-scroll"
          >
            {chatHistoryUI()}
          </div>
        </div> */}
        <MenuOutlined
          className=" hidden sm:block pt-2 absolute top-24 left-2"
          onClick={() => setChatHistoryDrawer(true)}
        />

        <div className="flex items-center flex-col overflow-hidden relative px-2 sm:px-20 chat-container">
          <div className="flex flex-col items-center w-full justify-center z-10 ">
            {showHeader && (
              <>
                <img
                  src={Caresathi}
                  alt="Care Sathi Logo"
                  className="h-12 w-12 mt-1 sm:mt-6"
                />
                <h1 className="sm:text-3xl text-lg text-[#005D8E] text-center font-bold pt-2">
                  CareSathi
                </h1>
                <p className="text-sm sm:text-base text-[#6F6F6F] mb-2 font-semibold">
                  Care to Cure,{" "}
                  <span className="font-normal">your journey starts here</span>
                </p>
              </>
            )}
            <div className="p-2 sm:p-4 text-center">
              <p className="mb-2 sm:mb-3 ">Select language:</p>

              <div className="flex flex-wrap justify-center border border-gray rounded-full text-sm sm:text-base">
                <button
                  className={`px-3 sm:px-5 py-1 sm:py-2 ${
                    selectedLanguage === "en"
                      ? "bg-secondaryColor text-White"
                      : "bg-transparent text-black"
                  } rounded-full`}
                  onClick={() => setSelectedLanguage("en")}
                >
                  English
                </button>

                <button
                  className={`px-3 sm:px-5 py-1 sm:py-2 ${
                    selectedLanguage === "hi"
                      ? "bg-secondaryColor text-White"
                      : "bg-transparent text-black"
                  } rounded-full`}
                  onClick={() => setSelectedLanguage("hi")}
                >
                  Hindi
                </button>
                <button
                  className={`px-3 sm:px-5 py-1 sm:py-2 ${
                    selectedLanguage === "or"
                      ? "bg-secondaryColor text-White"
                      : "bg-transparent text-[#000]"
                  } rounded-full`}
                  onClick={() => setSelectedLanguage("or")}
                >
                  Odia
                </button>
              </div>
            </div>
          </div>

          <MenuOutlined
            className="block sm:hidden pt-2 absolute top-2 right-2 z-20"
            onClick={() => setChatHistoryDrawer(true)}
          />
          <div
            className="flex flex-col justify-between mt-2 overflow-y-scroll"
            style={{
              scrollbarWidth: "none",
              //  marginTop: !showHeader ? '0px' :'100px'
            }}
            ref={chatContainerRef}
          >
            {qaHistory.length ? (
              qaHistory.map((item, index) => (
                <div key={index} className="mb-8">
                  <div
                    className="p-5 float-right mb-2 w-9/12 sm:w-1/2 mr-2 sm:mr-0"
                    style={{ backgroundColor: "#f4f4f4", borderRadius: "10px" }}
                  >
                    <div className="w-full flex justify-between">
                      <div className="flex">
                        <img
                          src={Caresathi}
                          alt="Care Sathi Logo"
                          className="h-7 w-7 mt-1"
                        />
                        <p className="sm:pt-1 pl-3" style={{ fontSize: 14 }}>
                          {convertedText.index == index &&
                          convertedText.type == "question"
                            ? convertedText.text
                            : item.question}
                        </p>
                      </div>

                      <Dropdown
                        overlay={
                          <Menu
                            items={generateItems(
                              index,
                              "question",
                              item.question,
                              item.questionLanguage // Use the correct language for this specific question
                            )}
                          />
                        }
                        trigger={getDropdownTrigger()}
                      >
                        <Button>
                          <TranslateIcon />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="flex bgclr px-2 sm:px-4 py-3 w-full gap-3">
                    <div className=" ">
                      {item.isLoading ? (
                        <ChatLoading />
                      ) : (
                        <div className="font-normal flex flex-col text-lg mb-6 bg-primaryColor text-White p-4 rounded-xl">
                          <div className="flex justify-between">
                            <div
                              style={{ fontSize: "14px" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  convertedText.index == index &&
                                  convertedText.type == "answer"
                                    ? convertedText.text
                                    : item.answer,
                              }}
                            ></div>
                            {textAnimationFinish && (
                              <Dropdown
                                overlay={
                                  <Menu
                                    items={generateItems(
                                      index,
                                      "answer",
                                      item.answer,
                                      item.answerLanguage // Use the correct language for this specific answer
                                    )}
                                  />
                                }
                                trigger={getDropdownTrigger()}
                              >
                                <Button>
                                  <TranslateIcon />
                                </Button>
                              </Dropdown>
                            )}
                            {textAnimationFinish && (
                              <div className="flex flex-col">
                                <Button
                                  icon={
                                    playingAudioId === index ? (
                                      <PauseIcon />
                                    ) : (
                                      <VolumeUpIcon />
                                    )
                                  }
                                  onClick={() =>
                                    handleSpeak(item.answer, index)
                                  }
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "16px",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0 12px",
                                    gap: "8px",
                                  }}
                                >
                                  {playingAudioId === index ? "Pause" : "Play"}
                                </Button>
                              </div>
                            )}
                          </div>
                     {/* {Object.hasOwn(
                            serviceRecommendationObject,
                            index.toString()
                          ) && (
                            <CallToActionComponent
                              data={
                                serviceRecommendationObject[index.toString()]
                              }
                              snackbarmsg={(msg) => setSnackbarmsg(msg)}
                              setOpenSnackbar={setOpenSnackbar}
                              sessionId={timeStamp.toString()}
                            />
                          )}  */}


{item.serviceRecommendation && (
      <CallToActionComponent
        data={item.serviceRecommendation}
        snackbarmsg={(msg) => setSnackbarmsg(msg)}
        setOpenSnackbar={setOpenSnackbar}
        sessionId={timeStamp.toString()}
      />
    )}
                        
                        
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col justify-center sm:flex-row gap-6 p-4 ">
                {/* Card 1 */}
                <button
                  onClick={() =>
                    setSampleMessage(
                      selectedLanguage == "en"
                        ? "What are diabetes symptoms?"
                        : selectedLanguage == "hi"
                        ? " डायबिटीज के लक्षण क्या हैं?"
                        : selectedLanguage == "or"
                        ? "ଡାଇବେଟିସର ଲକ୍ଷଣଗୁଡ଼ିକ କ’ଣ?"
                        : ""
                    )
                  }
                  className="h-max ai-suggestion-card flex flex-col  p-4 bg-White rounded-lg shadow-md hover:shadow-lg transition-shadow border border-lightGray h-40"
                >
                  <p
                    className="text-center text-gray-600 break-word"
                    style={{ color: "#676767" }}
                  >
                    {selectedLanguage == "en"
                      ? "What are diabetes symptoms?"
                      : selectedLanguage == "hi"
                      ? " डायबिटीज के लक्षण क्या हैं?"
                      : selectedLanguage == "or"
                      ? "ଡାଇବେଟିସର ଲକ୍ଷଣଗୁଡ଼ିକ କ’ଣ?"
                      : ""}{" "}
                  </p>
                </button>

                {/* Card 2 */}
                <button
                  onClick={() =>
                    setSampleMessage(
                      selectedLanguage == "en"
                        ? "How to manage high BP?"
                        : selectedLanguage == "hi"
                        ? "उच्च रक्तचाप को कैसे नियंत्रित करें?"
                        : selectedLanguage == "or"
                        ? "ଉଚ୍ଚ ରକ୍ତଚାପ କେମିତି ପରିଚାଳନା କରିବେ?"
                        : ""
                    )
                  }
                  className="h-max ai-suggestion-card flex flex-col  p-4 bg-White rounded-lg shadow-md w-full sm:w-[250px] hover:shadow-lg transition-shadow border border-lightGray  "
                >
                  <p
                    className="text-center text-gray-600 break-word"
                    style={{ color: "#676767" }}
                  >
                    {selectedLanguage == "en"
                      ? "How to manage high BP?"
                      : selectedLanguage == "hi"
                      ? "उच्च रक्तचाप को कैसे नियंत्रित करें?"
                      : selectedLanguage == "or"
                      ? "ଉଚ୍ଚ ରକ୍ତଚାପ କେମିତି ପରିଚାଳନା କରିବେ?"
                      : ""}{" "}
                  </p>
                </button>

                {/* Card 3 */}
                <button
                  onClick={() =>
                    setSampleMessage(
                      selectedLanguage == "en"
                        ? "Exercises for back pain?"
                        : selectedLanguage == "hi"
                        ? "पीठ दर्द के लिए कौन से व्यायाम करें?"
                        : selectedLanguage == "or"
                        ? "ପିଠିର ବେଥା ପାଇଁ କେଉଁସବୁ ଅଭ୍ୟାସ କରିବେ?"
                        : ""
                    )
                  }
                  className="h-max ai-suggestion-card flex flex-col  p-4 bg-White rounded-lg shadow-md w-full sm:w-[250px] hover:shadow-lg transition-shadow border border-lightGray  sm:mb-0"
                >
                  <p
                    className="text-center text-gray-600 break-word"
                    style={{ color: "#676767" }}
                  >
                    {selectedLanguage == "en"
                      ? "Exercises for back pain?"
                      : selectedLanguage == "hi"
                      ? "पीठ दर्द के लिए कौन से व्यायाम करें?"
                      : selectedLanguage == "or"
                      ? "ପିଠିର ବେଥା ପାଇଁ କେଉଁସବୁ ଅଭ୍ୟାସ କରିବେ?"
                      : ""}{" "}
                  </p>
                </button>
              </div>
            )}
            {/* </div> */}
          </div>
          <div
            className=" w-full sm:w-[70%] mx-auto boxposition fixed bottom-0 px-2 sm:px-20"
            style={{
              paddingBottom: 5,
              backgroundColor: "white",
            }}
          >
            <div className="pt-2">
              {uploadedFileName && (
                <span
                  className="text-sm font-normal mb-2 ml-7 py-2 px-2 shadow rounded"
                  style={{ backgroundColor: "#ffff" }}
                >
                  <strong>Uploaded File:</strong> {uploadedFileName}
                </span>
              )}
              <div className="w-full flex justify-between relative items-center pb-3">
                <label
                  className="flex text-lg font-normal p-3 cursor-pointer absolute"
                  onClick={handleOpenPopup}
                >
                 <img src={AttachFileIcon} alt="Attach File" className="w-6" />
                  {/* <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  /> */}
                </label>

                <InputText
                  value={sampleMessage}
                  onChange={handleInputChange}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();

                      handleArrowClick(e);
                    }
                  }}
                  placeholder={
                    selectedLanguage == "en"
                      ? "Type your Medical Problems only ..."
                      : selectedLanguage == "hi"
                      ? "केवल अपनी चिकित्सा समस्याएं टाइप करें।"
                      : selectedLanguage == "or"
                      ? "କେବଳ ଆପଣଙ୍କ ଚିକିତ୍ସା ସମସ୍ୟାଗୁଡ଼ିକ ଲେଖନ କରନ୍ତୁ।"
                      : ""
                  }
                  lang={selectedLanguage}
                  style={{
                    maxHeight: "200px",
                    height: "52px",
                    width: "100%",
                    border: "none",
                    backgroundColor: "#f4f4f4",
                    outline: "none",
                  
                  }}
              
                  className={` pr-20 pl-4 sm:text-base  text-sm p-inputtext p-component p-filled sm:pl-12 ${
                    lab_report_analyser === "true" ? "pl-[35px]" : "pl-[40px]"
                  }`}
                />

                <div className="absolute right-2 sm:right-4 flex">
                  <MicRecorder
                    getQuestionFromSpeechToText={getQuestionFromSpeechToText}
                    sendError={sendError}
                    selectedLanguage={selectedLanguage}
                  />
                  <button onClick={(e) => handleArrowClick(e)}>
                    <img src={Send} alt="#" />
                  </button>
                </div>
              </div>
              {/* <p class="flex text-lg font-normal pr-3">Disclaimer</p> */}
            </div>
          </div>
        </div>
      </div>
      <Drawer
        styles={{ body: { padding: 0 } }}
        title="Chat History"
        onClose={() => setChatHistoryDrawer(false)}
        placement="left"
        open={chatHistoryDrawer}
      >
        <div
          style={{
            minWidth: "25%",
            backgroundColor: "#004172",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
          className="min-w-3/12 overflow-y-hidden h-screen"
        >
          {chatHistoryUI()}
        </div>
      </Drawer>

      <Modal
        title="Disclaimer"
        className="commonModal"
        open={false}
        onOk={() => setshowaddmemberpopup(!showaddmemberpopup)}
        onCancel={() => {
          setshowaddmemberpopup(!showaddmemberpopup);
        }}
        width="65%"
        style={{ top: 20 }}
        footer={[]}
      >
        <p className="sm:text-base text-sm">
          {" "}
          CareSathi AI is designed to provide an automatic response with a
          general overview of the user's health status based on the available
          research data. CareSathi AI is not tailored to detect individual
          symptoms and is not a substitute for professional medical diagnosis.
          Users bear sole responsibility for their reliance on the response and
          we expressly disclaim any and all liability arising from the use and
          reliance on CareSathi AI responses. For personalized advice and
          recommendations, pls visit our CureBay clinic.
        </p>
      </Modal>

      <Modal
        open={openLabReportAnalyserModal}
        onOk={() => {}}
        onCancel={() => setopenLabReportAnalyserModal(false)}
        footer={null} // Remove the default footer
        centered // Center the modal
        width={400} // Adjust width to match your design
        bodyStyle={{ padding: "30px", textAlign: "center" }} // Custom body styles
      >
        {/* Modal Content */}
        <Title level={4}>Share your report here</Title>
        <Text
          type="secondary"
          style={{ display: "block", marginBottom: "20px" }}
        >
          You can upload the report from your device or select it from the Lab
          Reports section.
        </Text>

        {/* Buttons */}
        <Button
          onClick={() => fileInputRef.current.click()}
          icon={<UploadOutlined />}
          style={{
            width: "100%",
            marginBottom: "15px",
            backgroundColor: "#004171",
            color: "#ffff",
          }}
        >
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          Upload Your Report
        </Button>
        <Button
          type="default"
          onClick={handleNavigateToLabSelection}
          icon={<SearchOutlined />}
          style={{ width: "100%", borderColor: "#004171", color: "#004171" }}
        >
          Find Your Lab Report
        </Button>
      </Modal>
    </>
  );
};

export default CareSathi;

