import React, { useState, useRef, useEffect } from "react";
import DiscountIcon from "../../../Assets/Images/Cart/discountIcon.jpg";
import http from "../../../Redux/Services/http-common";
import { useHistory } from "react-router-dom";
import { applyCouponCode, getCouponList } from "./cartApi/couponapis";
import { useDispatch, useSelector } from "react-redux";
import {
  AddtoCart,
  getCartDetails,
} from "../../../Redux/Action/cartPlaceOrderAction";
import { message, Modal, Tooltip, Input } from "antd";
import { CopyOutlined, LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Backdrop from "@mui/material/Backdrop";
import OtpInput from "react-otp-input";
import InfoDetailsCorporate from "./infoCorporateDiscount";
import "../../../index.css";
import { ToastContainer } from "react-toastify";

// ─── BILLING DETAILS COMPONENT ──────────────────────────────────────────────
// This component renders only the billing details and is memoized.
const BillingDetails = React.memo(
  ({
    medicineTotalAmount,
    labTotalAmount,
    totalDiscountAmount,
    totalAmount,
    buttonText,
    handleProceed,
  }) => {
    return (
      <div className="w-full bg-White">
        <p className="text-lg font-semibold text-primaryColor mb-2">
          Billing <span className="text-secondaryColor">Details</span>
        </p>
        {medicineTotalAmount > 0 && (
          <div className="flex justify-between mb-2">
            <p className="text-sm font-normal text-textGray">Medicine Bill</p>
            <p className="text-sm font-normal text-textGray">
              ₹ {parseFloat(medicineTotalAmount).toFixed(2)}
            </p>
          </div>
        )}
        {labTotalAmount > 0 && (
          <div className="flex justify-between">
            <p className="text-sm font-normal text-textGray">Lab Test Bill</p>
            <p className="text-sm font-normal text-textGray">
              ₹ {parseFloat(labTotalAmount).toFixed(2)}
            </p>
          </div>
        )}
        {totalDiscountAmount > 0 && (
          <div className="flex justify-between mt-2">
            <p className="text-sm font-normal text-textGray">Total Discount</p>
            <p className="text-sm font-normal text-secondaryColor">
              - ₹ {parseFloat(totalDiscountAmount).toFixed(2)}
            </p>
          </div>
        )}
        <hr className="my-3 border-t border-gray" />
        <div className="flex justify-between mt-3">
          <p className="text-primaryColor font-medium">Payable Amount</p>
          <p className="text-primaryColor font-medium">
            ₹ {parseFloat(totalAmount).toFixed(2)}
          </p>
        </div>
        <button
          className="w-full mt-4 text-sm bg-primaryColor text-White py-2.5 px-4 border border-brand-secondary rounded-md"
          onClick={handleProceed}
        >
          {buttonText}
        </button>
      </div>
    );
  }
);

function CartPrice(props) {
  const [messageApi, contextHolder] = message.useMessage();
  const { cartData, patient, readOnly = false, buttonText } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.patientData);

  // State declarations
  const [isShowCouponList, setShowCouponList] = useState(false);
  const [isCouponApply, setCouponApply] = useState(false);
  const [selectedCouponCode, setselectedCouponCode] = useState(null);
  const [medicineTotalAmount, setMedicineTotalAmount] = useState("0");
  const [medicineDiscountAmount, setMedicineTotalDiscountAmount] = useState("0");
  const [labTotalAmount, setLabTotalAmount] = useState("0");
  const [labDiscountAmount, setLabTotalDiscountAmount] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  // Loader state for coupon API calls
  const [loadingForCoupon, setLoadingForCoupon] = useState(false);
  const [couponVerify, setCouponVerify] = useState("");
  const [otp, setOtp] = useState("");
  const [isMedMemberShipAreadyApplied, setIsMedMemberShipAreadyApplied] = useState(0);
  const [isLabMemberShipAreadyApplied, setIsLabMemberShipAreadyApplied] = useState(0);
  // Removed loader for checking membership changes on quantity update.
  const [medMembershipData, setMedMembershipData] = useState({});
  const [labMembershipData, setLabMembershipData] = useState({});
  const [userFromMedicare, setUserFromMedicare] = useState(false);
  const [showOtpUI, setShowOtpUI] = useState(false);
  const [flagOtp, setFlagotp] = useState(false);
  // These loading flags now only control membership & coupon actions.
  const [medmembershipError, setMedMembershipError] = useState({ error: "", loading: false });
  const [labmembershipError, setLabMembershipError] = useState({ error: "", loading: false });
  const [isCouponAnEmail, setIsCouponAnEmail] = useState(false);

  const titles = ["Medicines", "Pathology", "Radiology"];
  const content = (
    <>
      {titles.map((item) => (
        <InfoDetailsCorporate key={item} title={item} />
      ))}
    </>
  );
  const medmembershipref = useRef(null);

  // Toggle coupon list display (if membership discount is not applied)
  const hideShow = () => {
    if (isMedMemberShipAreadyApplied > 0) return;
    setShowCouponList((prev) => !prev);
  };

  // ─── SIDE EFFECTS ─────────────────────────────────────────────────────────────
  useEffect(() => {
    checkIfCouponAppled();
    checkIfMembershipAlreadyApplied();
  }, [cartData]);

  useEffect(() => {
    // Membership details API calls (no loader for quantity change)
    http
      .get(
        `${process.env.REACT_APP_BASEURL}patientMembership/list?patientId=${patient.code}&source=B2C`
      )
      .then((res) => {
        if (res.data.data.length && res.data.data === "Medicare") {
          setUserFromMedicare(true);
        } else if (res.data.data.length) {
          http
            .get(
              `${process.env.REACT_APP_BASEURL}patientMembership/list/details?membershipCode=${res.data.data}&serviceCode=CHE_PHAR_ORDER`
            )
            .then((res) => {
              if (cartData?.patientMedicineOrder?.length > 0) {
                if (res.data.data.length) {
                  setMedMembershipData(res.data.data[0]);
                }
              } else {
                setMedMembershipData({ membershipCard: 0 });
              }
            })
            .catch((err) => {
              setMedMembershipData({ membershipCard: 0 });
            });
          http
            .get(
              `${process.env.REACT_APP_BASEURL}patientMembership/list/details?membershipCode=${res.data.data}&serviceCode=CHE_DIA_ORDER`
            )
            .then((res) => {
              if (cartData?.patientLabTestsOrder?.length > 0) {
                if (res.data.data.length) {
                  setLabMembershipData(res.data.data[0]);
                }
              } else {
                setLabMembershipData({ membershipCard: 0 });
              }
            })
            .catch((err) => {
              setLabMembershipData({ membershipCard: 0 });
            });
        }
      })
      .catch((err) => {
        setMedMembershipData({ membershipCard: 0 });
        setLabMembershipData({ membershipCard: 0 });
      });
  }, [cartData, patient]);

  const checkIfMembershipAlreadyApplied = () => {
    if (!cartData) return;
    let id = "";
    if (cartData?.patientMedicineOrder?.length) {
      id = cartData.patientMedicineOrder[0]?.membershipId;
    }
    if (cartData?.patientLabTestsOrder?.length) {
      if (id < 2 || id == null) {
        id = cartData.patientLabTestsOrder[0]?.membershipId;
      }
    }
    setIsMedMemberShipAreadyApplied(id);
  };

  const checkIfCouponAppled = () => {
    if (!cartData) return;
    let appliedCoupons = [];
    let medTotal = 0;
    let medDiscounted = 0;
    let labTotal = 0;
    let labDiscounted = 0;
    if (cartData.patientMedicineOrder?.length) {
      if (cartData.patientMedicineOrder[0].voucheryCode) {
        appliedCoupons.push(cartData.patientMedicineOrder[0].voucheryCode);
      }
      medTotal = parseFloat(cartData.patientMedicineOrder[0].amount);
      medDiscounted = parseFloat(cartData.patientMedicineOrder[0].totalAmount);
    }
    if (cartData.patientLabTestsOrder?.length) {
      if (cartData.patientLabTestsOrder[0].voucherCode) {
        appliedCoupons.push(cartData.patientLabTestsOrder[0].voucherCode);
      }
      labTotal = parseFloat(cartData.patientLabTestsOrder[0].totalAmount);
      labDiscounted = parseFloat(cartData.patientLabTestsOrder[0].paidAmount);
    }
    setMedicineTotalAmount(medTotal);
    setMedicineTotalDiscountAmount(medDiscounted);
    setLabTotalAmount(labTotal);
    setLabTotalDiscountAmount(labDiscounted);
    let totalDisc = medTotal + labTotal - (medDiscounted + labDiscounted);
    setTotalDiscountAmount(totalDisc);
    setTotalAmount(medDiscounted + labDiscounted);
    setCouponApply(appliedCoupons.length > 0);
  };

  useEffect(() => {
    if (isCouponApply && !loadingForCoupon) {
      setShowCouponList(false);
    }
  }, [isCouponApply, loadingForCoupon]);

  // Update isCouponAnEmail state based on selectedCouponCode
  useEffect(() => {
    if (selectedCouponCode) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsCouponAnEmail(emailRegex.test(selectedCouponCode));
    } else {
      setIsCouponAnEmail(false);
    }
  }, [selectedCouponCode]);

  const showError = () => (
    <div
      className={
        isCouponApply
          ? "text-danger mt-3 text-xs px-4"
          : "text-secondaryColor mt-3 text-xs px-4"
      }
    >
      {loadingForCoupon ? null : couponVerify || ""}
    </div>
  );

  const errorTimer = () => {
    setTimeout(() => {
      setMedMembershipError({ loading: false, error: "" });
      setLabMembershipError({ loading: false, error: "" });
    }, 2000);
  };

  const handleMedMembershipClick = (e) => {
    if (isCouponApply || cartData?.corporateDiscount === "Y") {
      setMedMembershipError({
        loading: false,
        error: "Please remove coupon to avail membership discount.",
      });
      errorTimer();
      return;
    }
    if (medmembershipError.loading) {
      e?.stopPropagation();
      return;
    }
    let flag = isMedMemberShipAreadyApplied > 0 ? false : true;
    setMedMembershipError({ loading: true });
    e?.stopPropagation();
    if (flag) {
      if (
        cartData?.patientMedicineOrder?.length &&
        medMembershipData?.membershipCard > 0
      ) {
        http
          .get(
            `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${medMembershipData?.membershipCard}&cartId=${cartData.cartId}&serviceType=medicine&amount=${totalAmount}`
          )
          .then((res) => {
            setMedMembershipError({ loading: false, error: "" });
            props.getCartData();
          })
          .catch((err) => {
            setMedMembershipError({
              loading: false,
              error: err.response.data.message,
            });
          });
      }
      if (
        cartData?.patientLabTestsOrder?.length &&
        labMembershipData?.membershipCard > 0
      ) {
        http
          .get(
            `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${labMembershipData.membershipCard}&cartId=${cartData.cartId}&serviceType=labTests`
          )
          .then((res) => {
            setMedMembershipError({ loading: false, error: "" });
            dispatch(getCartDetails(userData.code));
          })
          .catch((err) => {
            setMedMembershipError({
              loading: false,
              error: err.response.data.message,
            });
          });
      }
    } else {
      let data = cartData;
      if (
        cartData?.patientMedicineOrder?.length &&
        cartData.patientMedicineOrder[0].patientMedicineOrderDetailsList.length
      ) {
        data.patientMedicineOrder[0].membershipId = 0;
        data.patientMedicineOrder[0].patientMedicineOrderDetailsList.forEach(
          (res) => {
            res.discountAmount = 0;
            res.totalAmount = 0;
          }
        );
      }
      if (
        cartData?.patientLabTestsOrder?.length &&
        cartData.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.length
      ) {
        data.patientLabTestsOrder[0].membershipId = 0;
        data.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.forEach(
          (res) => {
            res.discountAmount = 0;
            res.totalAmount = 0;
          }
        );
      }
      dispatch(AddtoCart(data)).then(() => {
        dispatch(getCartDetails(userData.code))
          .then(() => {
            setMedMembershipError({ loading: false, error: "" });
          })
          .catch((err) => {
            setMedMembershipError({
              loading: false,
              error: err?.response?.data?.message,
            });
          });
      });
    }
  };

  const handleLabMembershipClick = (e) => {
    if (isCouponApply) {
      setLabMembershipError({
        loading: false,
        error: "Please remove coupon to avail membership discount.",
      });
      errorTimer();
      return;
    }
    if (labmembershipError.loading) {
      e.stopPropagation();
      return;
    }
    let flag = isLabMemberShipAreadyApplied > 0 ? false : true;
    setLabMembershipError({ loading: true });
    e.stopPropagation();
    if (flag) {
      http
        .get(
          `${process.env.REACT_APP_BASEURL}patientAmount/Calculation?membershipCode=${labMembershipData.membershipCard}&cartId=${cartData.cartId}&serviceType=labTests`
        )
        .then((res) => {
          setLabMembershipError({ loading: false, error: "" });
          dispatch(getCartDetails(userData.code));
        })
        .catch((err) => {
          setLabMembershipError({
            loading: false,
            error: err.response.data.message,
          });
        });
    } else {
      let data = cartData;
      data.patientLabTestsOrder[0].membershipId = 0;
      data.patientLabTestsOrder[0].patientLabTestsOrderDetailsList.forEach(
        (res) => {
          res.discountAmount = 0;
          res.totalAmount = 0;
        }
      );
      dispatch(AddtoCart(data)).then(() => {
        dispatch(getCartDetails(userData.code))
          .then(() => {
            setLabMembershipError({ loading: false, error: "" });
          })
          .catch((err) => {
            setLabMembershipError({
              loading: false,
              error: err?.response?.data?.message,
            });
          });
      });
    }
  };

  const handleCouponSelectClick = (item, i) => {
    setselectedCouponCode(item.voucherCode);
  };

  const [removeCouponTriggered, setRemoveCouponTriggered] = useState(false);
  const handleRemoveClick = (event, index) => {
    event.stopPropagation();
    handleCouponSelectClick({ voucherCode: "REMOVE" }, index);
    setRemoveCouponTriggered(true);
  };

  useEffect(() => {
    if (removeCouponTriggered) {
      handleApplyClick();
      setRemoveCouponTriggered(false);
    }
  }, [removeCouponTriggered]);

  const handleInputChange = (e) => {
    setselectedCouponCode(e.target.value);
  };

  const handleApplyClick = () => {
    if (selectedCouponCode === "") {
      setCouponVerify("Please enter valid Coupon code");
      return;
    } else if (selectedCouponCode === null) {
      setCouponVerify("Please select a Coupon code");
      return;
    }
    if (isMedMemberShipAreadyApplied > 0) {
      setCouponVerify("Please remove membership discount");
      return;
    }
    const validCouponCodes = ["CB_MED10", "REMOVE"];
    if (!validCouponCodes.includes(selectedCouponCode)) {
      setCouponVerify("This coupon code is not available");
      return;
    }
    let amountLocalStorage = JSON.parse(localStorage.getItem("couponCode"));
    if (amountLocalStorage === selectedCouponCode) {
      return;
    }
    let payloads = {
      customer_identifier: cartData?.patientId,
      total_transaction_cost: +totalAmount.toFixed(2),
      cart_id: cartData?.cartId,
      transaction_id: new Date().getTime(),
      voucher_code: selectedCouponCode,
      total_medicine_bill: medicineTotalAmount,
      customer_type: "Registered",
      total_labtest_bill: labTotalAmount,
    };
  
    // Set the loader flag for coupon actions
    setLoadingForCoupon(true);
    setIsModalOpen(false);
  
    // Call the coupon API
    applyCouponCode(payloads)
      .then((res) => {
        // Optional: simulate a delay to make the loader visible for testing purposes
        setTimeout(() => {
          if (res.data.errors == null) {
            if (selectedCouponCode === "REMOVE") {
              setselectedCouponCode("");
            }
            localStorage.setItem("couponCode", JSON.stringify(selectedCouponCode));
            props.getCartData();
          } else {
            setTotalDiscountAmount(0);
            setCouponVerify(res.data.errors[0].message);
            props.getCartData();
          }
          setLoadingForCoupon(false);
        }, 500); // 500ms delay
      })
      .catch((err) => {
        // Ensure the loader is hidden in case of an error
        setLoadingForCoupon(false);
        setCouponVerify("An error occurred while applying coupon");
      });
  };


  useEffect(() => {
    localStorage.setItem(
      "totalAmount",
      JSON.stringify(parseFloat(totalAmount).toFixed(2))
    );
  }, [totalAmount]);

  useEffect(() => {
    if (
      parseFloat(medicineTotalAmount).toFixed(2) -
        parseFloat(medicineDiscountAmount).toFixed(2) >
      0
    ) {
      let amount = parseFloat(medicineDiscountAmount).toFixed(2);
      localStorage.setItem("medicineTotalAmount", JSON.stringify(amount));
    } else {
      let amount = parseFloat(medicineTotalAmount).toFixed(2);
      localStorage.setItem("medicineTotalAmount", JSON.stringify(amount));
    }
  }, [medicineDiscountAmount, medicineTotalAmount]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setCouponVerify("");
    setOtp("");
    setShowOtpUI(false);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setCouponVerify("");
    setOtp("");
    setShowOtpUI(false);
    setIsModalOpen(false);
  };
  const [text, setText] = useState("CB_MED10");
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      message.success("Copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy!");
    }
  };

  const handleCorporateDiscountOtpVerification = (text) => {
    if (isMedMemberShipAreadyApplied > 0 || isLabMemberShipAreadyApplied > 0) {
      setCouponVerify("Please remove membership discount");
      setShowOtpUI(false);
      return;
    }
    let payload = {
      email: selectedCouponCode,
      otp: text,
    };
    if (text.length === 6) {
      http
        .post(
          `${process.env.REACT_APP_BASEURL}PatientOTP/verifyCorporatePatientOTP`,
          payload
        )
        .then((res) => {
          if (res) {
            let payload = {
              patientId: userData?.code,
              cartId: cartData?.cartId,
              emailId: selectedCouponCode,
              serviceType: "medicine_lab",
              patientLabTestsOrder: cartData?.patientLabTestsOrder,
              patientMedicineOrder: cartData?.patientMedicineOrder,
              corporateEmployee: true,
            };
            http
              .post(
                `${process.env.REACT_APP_BASEURL}PatientCart/getCorporateDiscount`,
                payload
              )
              .then((res) => {
                if (res) {
                  dispatch(getCartDetails(userData.code));
                  setselectedCouponCode(null);
                  setShowOtpUI(false);
                  setFlagotp(true);
                  setIsModalOpen(false);
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        })
        .catch((err) => {
          if (err?.response?.data?.details?.length) {
            message.error(err?.response?.data?.details[0]);
          }
        });
    }
  };

  const onChange = (text) => {
    setOtp(text);
    if (text.length) {
      handleCorporateDiscountOtpVerification(text);
    }
  };

  const handleCorporateOtp = async () => {
    try {
      let result = await http.post(
        `${process.env.REACT_APP_BASEURL}PatientOTP/patientEmail/`,
        { email: selectedCouponCode }
      );
      if (result?.data === 1) {
        setShowOtpUI(true);
        message.info("OTP sent successfully");
      } else {
        setShowOtpUI(false);
        message.warning(
          "We’re sorry! It seems this corporate email is not eligible for a discount. Please try our other coupon codes to enjoy extra savings."
        );
      }
    } catch (error) {}
  };

  const handleRemoveCorporateDiscount = async () => {
    setOtp("");
    setShowOtpUI(false);
    let payload = {
      patientId: userData?.code,
      cartId: cartData?.cartId,
      emailId: selectedCouponCode,
      serviceType: "medicine_lab",
      patientLabTestsOrder: cartData?.patientLabTestsOrder,
      patientMedicineOrder: cartData?.patientMedicineOrder,
      corporateEmployee: false,
    };
    http
      .post(
        `${process.env.REACT_APP_BASEURL}PatientCart/getCorporateDiscount`,
        payload
      )
      .then((res) => {
        setFlagotp(false);
        dispatch(getCartDetails(userData.code));
      });
  };

  // Determine if we should show a loader (for membership discount or coupon actions)
  const isLoaderVisible =
    loadingForCoupon || medmembershipError.loading || labmembershipError.loading;

  return (
    <>
      <ToastContainer />
      {contextHolder}
      {/* Loader appears only for membership discount or coupon API calls */}
      {isLoaderVisible && (
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open
          onClick={() => {}}
        >
          <LoadingOutlined style={{ fontSize: 40 }} spin />
        </Backdrop>
      )}
      <div className="w-full">
        <div className="w-full rounded-lg">
          {!readOnly && (
            <div>
              <div className="flex gap-4 w-full">
                {cartData?.corporateDiscount === "Y" ? (
                  <div
                    className="px-2 py-3 w-full flex flex-col justify-start items-start sm:flex-row sm:items-center sm:justify-between"
                    style={{
                      background:
                        "radial-gradient(64.4% 108.13% at 50% 50%, #007ADB 0%, #004175 100%)",
                      borderRadius: 8,
                    }}
                  >
                    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 w-full">
                      <div className="flex items-center">
                        <p className="text-xs font-medium text-white">
                          <span className="text-secondaryColor">
                            Congratulations{" "}
                          </span>
                          🎉{" "}
                          <span className="text-White">
                            !! You get a corporate discount on your order.
                          </span>
                        </p>
                        <Tooltip title={content} placement="top">
                          <div
                            className="flex items-center justify-center rounded-full bg-White ml-2"
                            style={{ width: "20px", height: "20px" }}
                          >
                            <InfoCircleOutlined
                              className="cursor-pointer"
                              style={{ color: "green", fontSize: "25px" }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <button
                        onClick={handleRemoveCorporateDiscount}
                        className="text-xs font-medium text-White border rounded border-white p-1 px-2 mt-2 sm:mt-0 sm:ml-auto"
                      >
                        REMOVE
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="w-full">
                    <button
                      className="px-3 py-3 border border-gray rounded cursor-pointer bg-lightBlue w-full text-sm"
                      onClick={hideShow}
                      style={
                        isCouponApply
                          ? {
                              backgroundColor: "#66B889",
                              color: "#ffff",
                              borderColor: "#ffff",
                            }
                          : { backgroundColor: "#f3f8ff", color: "#1F2937" }
                      }
                    >
                      <div className="flex w-full justify-between items-center">
                        <div className="flex">
                          <img
                            src={DiscountIcon}
                            style={{ height: "20px" }}
                            className="mr-4"
                            alt="Discount Icon"
                          />
                          <p
                            className={
                              isCouponApply
                                ? "text-White"
                                : "font-medium text-sm text-primaryColor"
                            }
                          >
                            Avail Coupons
                          </p>
                        </div>
                        {!isCouponApply ? (
                          <button
                            onClick={showModal}
                            className="border border-primaryColor text-primaryColor text-xs font-semibold px-3 py-1.5 rounded"
                          >
                            APPLY
                          </button>
                        ) : (
                          <button
                            onClick={(e) => handleRemoveClick(e, 0)}
                            className="text-White border border-White text-xs font-semibold px-3 py-1.5 rounded"
                          >
                            REMOVE
                          </button>
                        )}
                      </div>
                    </button>
                  </div>
                )}
              </div>
              {(medMembershipData?.membershipCard > 0 ||
                isMedMemberShipAreadyApplied > 0 ||
                labMembershipData?.membershipCard > 0) && (
                <button
                  onClick={handleMedMembershipClick}
                  style={
                    isMedMemberShipAreadyApplied > 0
                      ? {
                          backgroundColor: "#66B889",
                          color: "#ffff",
                          borderColor: "#ffff",
                        }
                      : { backgroundColor: "#f3f8ff", color: "#1F2937" }
                  }
                  className="bg-lightBlue border border-gray rounded w-full mt-4 px-3 py-3 flex flex-col justify-between relative"
                >
                  <div className="flex justify-between w-full items-center">
                    <div className="flex items-center">
                      <img
                        src={DiscountIcon}
                        style={{ height: "20px" }}
                        className="mr-4"
                        alt="Discount Icon"
                      />
                      <div
                        className={`font-medium text-sm ${
                          isMedMemberShipAreadyApplied > 0
                            ? "text-White"
                            : "text-primaryColor"
                        }`}
                      >
                        Avail Membership Discount
                      </div>
                    </div>
                    <div
                      className={`border ${
                        isMedMemberShipAreadyApplied > 0
                          ? "text-White border-White"
                          : "text-primaryColor border-primaryColor"
                      } text-xs font-semibold px-3 py-1.5 rounded`}
                    >
                      {isMedMemberShipAreadyApplied > 0 ? "REMOVE" : "APPLY"}
                    </div>
                  </div>
                  <div className="text-xs w-full font-medium text-center mt-1 text-red-600">
                    {medmembershipError.error}
                  </div>
                </button>
              )}
              <hr className="border-t border-gray my-5" />
            </div>
          )}
          <BillingDetails
            medicineTotalAmount={medicineTotalAmount}
            labTotalAmount={labTotalAmount}
            totalDiscountAmount={totalDiscountAmount}
            totalAmount={totalAmount}
            buttonText={buttonText}
            handleProceed={() => props.handleProceed("")}
          />
        </div>
      </div>
      <Modal
        title="Select Coupon"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Tooltip target=".coupon-tooltip" />
        <div className="w-full overflow-y-scroll overflow-x-hidden bg-white rounded-bl-lg rounded-br-lg">
          <div className="bg-white pb-1 sticky z-20 top-0">
            <div className="relative w-full px-4 mt-4">
              <input
                placeholder="Select Coupon Code or Enter Corporate Email Address"
                name="code"
                value={selectedCouponCode || ""}
                onChange={handleInputChange}
                className="bg-white font-medium text-gray-500 h-12 pl-4 pr-[6rem] py-2 w-full border border-gray-200 rounded-lg text-sm coupon-tooltip"
                data-pr-tooltip="Enter your corporate email or select a coupon code to avail the discount."
                data-pr-position="top"
                tooltipOptions={{ autoHide: true }}
              />
              <button
                className="absolute top-1/2 right-2 transform -translate-y-1/2 text-secondaryColor px-3 py-1 text-sm font-medium rounded"
                onClick={
                  isCouponAnEmail ? handleCorporateOtp : handleApplyClick
                }
              >
                {isCouponAnEmail ? "SEND OTP" : "Apply"}
              </button>
            </div>
            {showError()}
          </div>
          <div className="flex items-center justify-center mb-2">
            {showOtpUI && (
              <OtpInput
                value={otp}
                onChange={onChange}
                numInputs={6}
                inputType="tel"
                renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input {...props} className="otp-input" />
                )}
              />
            )}
          </div>
          {!flagOtp && (
            <div className="flex flex-col gap-4 max-h-96 mx-4 z-10">
              <div
                className="rounded-xl p-3 cursor-pointer relative w-full"
                style={{
                  border: "1px solid rgba(0, 93, 141, 0.15)",
                  background:
                    selectedCouponCode === "CB_MED10"
                      ? "rgba(25, 135, 84, 0.23)"
                      : "white",
                }}
                onClick={() =>
                  handleCouponSelectClick({ voucherCode: "CB_MED10" }, 0)
                }
              >
                <div className="bg-green justify-center h-min-20 rounded-lg">
                  <div className="flex flex-row border-b-2 border-dashed border-white w-full">
                    <h3 className="px-4 pb-2 pt-1 w-full">
                      <b className="border border-1 border-[#313131] border-spacing-1 p-1">
                        CB_MED10
                        <CopyOutlined
                          onClick={handleCopy}
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            color: "#313131",
                            marginLeft: "12px",
                          }}
                        />
                      </b>
                    </h3>
                  </div>
                  <p className="mx-4 p-1 text-grey font-normal text-sm">
                    Flat 10% discount on all the medicines
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-2 items-center gap-5 mt-5">
            {/* Additional modal buttons can go here */}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CartPrice;
